import React, {useEffect, useState} from 'react';
import AboutUs from "../HomePage/Components/AboutUs";
import {debounce} from "lodash";
import WantWorkerHeader from "./Components/WantWorkerHeader";
import StepToApp from "./Components/StepToApp";


const WorkHomePage = () => {
    const [loading, setLoading] = useState(true);

    let handleLoading = debounce(() => {
        setLoading(false);

    }, 500);

    useEffect(() => {
        window.scrollToElement('body');
        handleLoading();
    },[]);

    return (
        <div style={{opacity: loading? 0: 1, transition: '500ms'}}>
            <WantWorkerHeader/>

            <div style={{padding:'50px 10px 100px'}}>
                <AboutUs/>
            </div>
            <div style={{padding:'0px 10px 100px'}}>
                <StepToApp/>
            </div>
        </div>
    );
};

WorkHomePage.propTypes = {};

export default WorkHomePage;
