import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import moment from "moment";
import Loading from "../../../components/Generic/Loading";
import {Images} from "../../../helpers/Images";
import RateStars from "../../../components/Generic/RateStars";
import {Col} from "react-bootstrap";
import {FiMapPin, FiStar} from 'react-icons/fi'
import {FaCalendarAlt, FaRegMoneyBillAlt} from 'react-icons/fa'
import {globalMaskReal} from "../../../hooks/Helpers";
import {BsTools} from "react-icons/bs";
import useApi from "../../../hooks/useApi";
import {ReviewsComponent} from "../../../components/Generic/ReviewsComponent";
import {useParams} from "react-router-dom";


const ServiceProviderMain = (props) => {

    const [gallery, setGallery] = useState()
    const params = useParams()

    const api = useApi()

    const getPhotos = () => {
        api.get(`worker-gallery/list?page=1&limit=50&user_id=${props.params.id}&occupation_id=${props.params.occupation}`,
          (res) => {
            setGallery(res)
          },(err) => {
              console.log(err)
        })
    }

    useEffect(() => {
        getPhotos()
    }, []);


    return (
        <div className='d-flex flex-column gap-5 p-3 w-100 bg-white rounded-3'>

            <div className='d-flex flex-column gap-2'>
                <div className='fs-5 text-dark fw-bold'>
                    Sobre Mim
                </div>

                <div className='d-flex flex-column gap-2' style={{fontSize:18}}>
                    <span>
                        {props.bio ? props.bio : 'Nada informado'}
                    </span>

                    {props.actualOccupation?.extra_fields?.registration &&
                        <span>
                            Registro Profissional - <strong> {props.actualOccupation?.extra_fields?.registration} </strong>
                        </span>
                    }
                </div>
            </div>


            {props.address?.length > 0 &&
                <div className='d-flex flex-column gap-2'>
                    <div className='fs-5 text-dark fw-bold'>
                        Localização
                    </div>

                    <div style={{fontSize: 18}}>
                        {props.address.map((item,index) =>
                            <div className='d-flex align-items-center gap-2' key={index}>
                                <span><FiMapPin className='text-primary'/></span>
                                <span>{item.street_name}, {item.district} - {item.city} / {item.state}</span>
                            </div>
                        )}
                    </div>
                </div>
            }

            {props?.activities && (props?.activities[0]?.activities?.length > 0) &&
                <>
                    <div className='d-flex flex-column gap-2'>
                        <div className='fs-5 text-dark fw-bold'>
                            Serviços Oferecidos
                        </div>
                        {props?.activities?.map((item, index) => (
                            <div key={index} className='service-offered d-flex flex-column gap-2'>
                                {item?.activities?.map((active) => (
                                    <>
                                        <div className='da-flex gap-2'>
                                            <BsTools className='text-success' size={20}/>  {active?.title}
                                        </div>
                                        <div className='da-flex gap-2'>
                                            <FaRegMoneyBillAlt className='text-success' size={20}/>
                                            {(active?.max_price === active?.min_price) ?
                                                <div>
                                                    <span className='fw-bold'>R$</span> <span style={{fontSize:18}}>{globalMaskReal(active?.max_price)}</span>
                                                </div> :
                                                (active?.max_price === 0 || active?.min_price === 0) ? <> Valor a combinar </> : <>R$ {globalMaskReal(active?.min_price)} a R$ {globalMaskReal(active?.max_price)}</>
                                            }
                                        </div>
                                        <div>
                                            <span className='text-muted'>{active?.description}</span>
                                        </div>
                                    </>

                                ))}
                            </div>
                        ))}
                    </div>
                </>
            }

            <div className={'container'}>
                <div className={'mb-5'}>
                    <ReviewsComponent dataWorker={{user_id: params?.id, occupation_id: params?.occupation}}
                                      activeKey={props.activeMode}
                                      maxHeigth={500}
                                      eventKey={'MOBILE'}
                    />
                </div>
            </div>

            {gallery?.entries > 0 &&
                <div className='gallery-carousel gap-2'>
                    <div className='fs-5 text-dark fw-bold'>
                        Posts
                    </div>
                    <Col xs={12} md={10} lg={8} xl={6} className='d-flex flex-column gap-3'>
                        {gallery?.galleries?.map((item, index) => (
                            <>
                                {(item?.media?.length > 0) &&
                                    <div className='post' key={index}>
                                        <div className='da-flex gap-2 ps-3 pt-3 mb-3 position-relative'>
                                            <div>
                                                <img className='rounded-circle' style={{width: 48, height: 48, objectFit: 'cover'}} alt='profile image' src={props?.avatar}/>
                                            </div>
                                            <div>
                                                <div className='fs-7' style={{fontWeight:600}}>
                                                    {props.name}
                                                </div>
                                                <div className='fs-8'>
                                                    {props.occupationName}
                                                </div>
                                            </div>
                                            <div className='position-absolute text-muted fs-8 ' style={{right:10, top:10}}>
                                                {moment.utc(`${item?.created_at}`).fromNow()}
                                            </div>
                                        </div>
                                        <div>
                                            <img className={`${item?.description ? '' : 'bra-3'}`} src={item.media[0]?.media_url} alt="post"/>
                                        </div>
                                        {item?.description &&
                                            <div className='fs-7 px-2 py-3'>
                                                <span style={{fontWeight:600}}>{props.name} </span>
                                                <span style={{fontWeight:400}}>{item?.description}</span>
                                            </div>
                                        }
                                    </div>
                                }
                            </>
                        ))}

                    </Col>
                </div>
            }
        </div>
    );
};

ServiceProviderMain.propTypes = {
    bio: PropTypes.any,
    reviewsLoading: PropTypes.any,
    occupationName: PropTypes.any,
    nextPage: PropTypes.any,
    loadMore: PropTypes.any
};

export default ServiceProviderMain;
