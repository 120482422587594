import React from 'react'
import {Button, Modal, ModalBody} from "react-bootstrap";

const ModalViewNotification = ({viewNotification,close}) => {

    return (
        <Modal show={viewNotification} animation onHide={close} >
            <Modal.Header className='border-0 p-0 pt-3 pe-3' closeButton/>

            <ModalBody>
                <div className='d-flex flex-column gap-3'>
                    <h6 className='text-center fw-bolder text'>
                        {viewNotification.title}
                    </h6>
                    {viewNotification.image &&
                        <div>
                            <img src={viewNotification.image} className='w-100' alt="image"/>
                        </div>
                    }

                    <div className='text-justify'>
                        {viewNotification.message}
                    </div>

                    <Button onClick={() => window.open(viewNotification.link)}>
                        Confira
                    </Button>
                </div>
            </ModalBody>
        </Modal>

    )
}

export default ModalViewNotification