export const customStyles = {
    noSearchable: {
        control: (provided) => {
            const height = "58px"

            return {...provided, height}
        },

        placeholder: (provided) => {
            const position = "absolute"
            const bottom = "-6px"

            return {...provided, position, bottom}
        },

        valueContainer: (provided) => ({
            ...provided,
            marginTop:10
        }),

        singleValue: (provided) => {
            const position = "absolute"
            const bottom = "-5px"
            const fontSize = "1rem"

            return {...provided, position, bottom, fontSize}
        },

    },

    error: {
        control: (provided) => ({
            ...provided,
            height:58,
            border: '1px solid #dc3545'
        }),

        placeholder: (provided) => ({
            ...provided,
             position: "absolute",
             bottom : "-6px",
        }),

        valueContainer: (provided) => ({
            ...provided,
            marginTop:10
        }),

        singleValue: (provided) => ({
            ...provided,
            position : "absolute",
            bottom : "-6px",
            fontSize : "1rem",

        }),
    }
}
