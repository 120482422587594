import React, {useEffect} from 'react';
import Loading from "../components/Generic/Loading";
import {useHistory} from "react-router-dom";

const PanelPage = () => {

    const history = useHistory();

    useEffect(() => {
        history.push('/painel/cliente')
    },[]);

    return (<> <Loading/> </>)
};
export default PanelPage;
