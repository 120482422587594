import React, {useEffect, useRef, useState} from "react";
import NumberFormat from "react-number-format";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessageComponent } from "../../../../components/Generic/ErrorMessageComponent";
import {Button} from "react-bootstrap";
import Select from "react-select";
import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast";
import {Env} from "../../../../helpers/Env";
import {api} from "../../../../services/api";
import {optType} from "../../../../helpers/AuxiliaryVariables";

export const WorkerRegisterAddressComponent = ({user, activeStep, onSaveData, isEdit, hideHeader}) => {
    const [loading, setLoading] = useState(false);
    const query = useQueryClient();
    const callCepRef = useRef(false)
    const registerSchema = yup.object().shape({
        cep: yup.string().required('Informe seu CEP').min(8, 'Insira um CEP válido'),
        address_type: yup.string().required('Informe o número do endereço'),
        street_number: yup.number().required('Informe o número do endereço').integer('Insira um número inteiro').typeError('Informe o número do endereço').min(1, 'O número deve ser diferente de zero'),
        name: yup.string().required('Informe o nome do endereço'),
        city: yup.string().required('Informe a cidade do endereço'),
        street_name: yup.string().required('Informe o nome da rua do endereço'),
        district: yup.string().required('Informe nome do bairro do endereço'),
        is_public: yup.boolean().default(false),
    });

    const {
        register,
        getValues,
        handleSubmit,
        control,
        reset,
        setValue,
        watch,
        clearErrors,
        formState: {errors,}
    } = useForm({resolver: yupResolver(registerSchema)});

    const onError = (e) => {
    }
    const checkCEP = async (cep) => {
        setLoading(true)
        if (errors) {
            clearErrors()
        }
        try {
            let data = await api.get(`search/postal-code?cep=${cep}`)

            let updateAddress = {
                ...getValues(),
                street_name: data?.street_name,
                district: data?.district,
                city: data?.city,
                country: data?.country,
                state: data?.state,


            }

            console.log(updateAddress)
            reset(updateAddress)
        } catch (e) {
        } finally {
            setLoading(false)
        }

    }

    const onSubmit = async (data) => {
        setLoading(true)
        let text = ''
        if (activeStep === 'CHANGE_ADDRESS') {
            text = 'Alterando...'
        } else if (activeStep === 'INITIAL_REGISTER') {
            text = 'Registrando...'
        } else {
            text = 'Enviando...'
        }
        toast.loading(text, {id:1})

        try {
            if (activeStep === 'CHANGE_ADDRESS' || activeStep === 'INITIAL_REGISTER') {
                data.is_primary = true
            }

            if (activeStep === 'INITIAL_REGISTER' && user.addresses.length === 0) {

                await api.post(`register/worker/${user.user_id}/address`, data)
            } else if (!isEdit) {

                await api.put(`user/${user.user_id}/address`, data)
            } else if (activeStep === 'CHANGE_ADDRESS') {
                delete data.latitude;
                delete data.longitude;


                await api.post(`user/${user.user_id}/address/${user.addresses[0].address_id}`, data)
            }
            await query.refetchQueries({queryKey: ['profile']});
            toast.success("Salvo com sucesso!", {id:1})
            onSaveData()

        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (isEdit && user.addresses.length > 0) {
            reset(user.addresses[0])
        }
    }, []);


    return (
        <div>
            {!hideHeader &&
                <div className={'text-center mb-5'}>
                    <h3>{!isEdit ? 'Agora você irá realizar o cadastro de endereço' : 'Edite seu endereço'}</h3>
                    {!isEdit &&
                        <p>Insira o endereço de sua residência ou o endereço do local em que presta os serviços. <br/> Seu endereço é utilizado para calcular sua distância na busca dos contratantes</p>
                    }
                </div>
            }
            <form onSubmit={handleSubmit(onSubmit, onError)} id={`address_${activeStep.toLowerCase()}`} className={'row'}>
                <div className="col-12 col-md-6">
                    <div>
                        <label className="form-label" htmlFor={`name_${activeStep.toLowerCase()}`}>
                            Nome do endereço
                        </label>

                        <input type="text"
                               id={`name_${activeStep.toLowerCase()}`}
                               className={`form-control  ${errors?.name?.message ? ' is-invalid' : ''}`}
                               placeholder={'Ex: Minha casa, Oficina, Escritório etc'}
                               {...register('name')}
                        />
                        <div className='mt-2'>
                            <ErrorMessageComponent message={errors?.name?.message}/>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div>
                        <label className="form-label required" htmlFor={`address_type_${activeStep.toLowerCase()}`}>
                            Tipo de endereço
                        </label>

                        <Controller name={'address_type'}
                                    control={control}
                                    render={({field: {onChange, name, value, ref,}, }) => (
                                        <Select placeholder={'Ex - Casa, comercial...'}
                                                ref={ref}
                                                inputId={`address_type_${activeStep.toLowerCase()}`}
                                                classNamePrefix={'select'}
                                                isSearchable={false}
                                                styles={{control: (base, state) => ({...base,
                                                        minHeight: `calc(1.0em + 1rem + 7px)`,
                                                        boxShadow: errors?.address_type?.message ? (state.isFocused ? '0px 0px 3px red' : 'none') : ''
                                                })}}
                                                classNames={{
                                                    control: (state) =>
                                                        errors?.address_type?.message ? 'border-danger' : '',
                                                }}
                                                value={optType?.find(c => c.value === value) || null}
                                                options={optType}
                                                onChange={(val) => onChange(val?.value)}
                                                isClearable
                                        />
                                    )}
                        />
                        <div className='mt-2'>
                            <ErrorMessageComponent message={errors?.address_type?.message}/>
                        </div>
                    </div>
                </div>
                <div className='col-12  py-2'>
                    <label htmlFor={`cep_${activeStep.toLowerCase()}`}>CEP</label>

                    <div className={'position-relative'}>
                        {loading && <div className="spinner-border spinner-border-sm text-dark" style={{position:"absolute", right:16, top:16}} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}

                        <Controller name='cep'
                                    control={control}
                                    render={({field: {onChange, value,ref}}) => (
                                        <NumberFormat
                                            className={`form-control  ${errors?.cep?.message ? ' is-invalid' : ''}`}
                                            id={`cep_${activeStep.toLowerCase()}`}
                                            name={'cep'}
                                            value={value}
                                            disabled={isEdit}
                                            onValueChange={async (e) => {
                                                onChange(e.value)
                                                if (e.value.length === 8) {
                                                    if (callCepRef.current === false && user.addresses.length === 0) {
                                                        callCepRef.current = true
                                                    }

                                                    if (callCepRef.current || !isEdit) await checkCEP(e.value)

                                                    if (callCepRef.current === false) {
                                                        callCepRef.current = true
                                                    }
                                                }
                                            }}
                                            placeholder={`XX XXX-XXX`}
                                            getInputRef={() => ref()}
                                            format={'##.###-###'}
                                            mask="_"
                                        />
                                    )}
                        />
                    </div>

                    {errors?.cep?.message ?
                        <div className='mt-2'>
                            <ErrorMessageComponent name={'cep'} errors={errors}/>
                        </div> :
                        <div className={'w-100 pt-1 text-end'}>
                            <span className={'text-primary'}>Informe o seu CEP para completar os dados</span>
                        </div>
                    }
                </div>
                <div className={'col-12'}>
                    <div className={`row ${isEdit ? 'd-flex' : (watch().cep?.length === 8 ? 'd-flex' : 'd-none') }`}>
                        <div className={`col-12 col-md-8 py-2`}>
                            <div className='col'>
                                <label htmlFor={`street_name_${activeStep.toLowerCase()}`}>Rua</label>
                                <input className={`form-control  ${errors?.street_name?.message ? ' is-invalid' : ''}`}
                                       type="text"
                                       disabled={watch().cep?.length < 8 || loading}
                                       id={`street_name_${activeStep.toLowerCase()}`}
                                       name={'Rua'}
                                       placeholder={'Rua'}
                                       {...register('street_name')}
                                />

                                <div className='mt-2'>
                                    <ErrorMessageComponent name={'street_name'} errors={errors}/>
                                </div>

                            </div>
                        </div>

                        <div className={`col-12 col-md-4 py-2`}>
                            <label htmlFor={`street_number_${activeStep.toLowerCase()}`}>Número</label>
                            <input
                                className={`form-control  ${errors?.street_number?.message ? ' is-invalid' : ''}`}
                                type="number"
                                min={0}
                                disabled={watch().cep?.length < 8 || loading}
                                id={`street_number_${activeStep.toLowerCase()}`}
                                name={'Número do local'}
                                placeholder={'Número'}
                                {...register('street_number')}
                            />
                            {errors?.street_number?.message &&
                                <div className='mt-2'>
                                    <ErrorMessageComponent name={'street_number'} errors={errors}/>
                                </div>
                            }
                        </div>

                        <div className={`col-12 col-md-12 py-2`}>
                            <label htmlFor={`district_${activeStep.toLowerCase()}`}>Bairro</label>
                            <input className={`form-control  ${errors?.district?.message ? ' is-invalid' : ''}`}
                                   type="text"
                                   disabled={watch().cep?.length < 8 || loading}
                                   id={`district_${activeStep.toLowerCase()}`}
                                   name={'Bairro'}
                                   placeholder={'Bairro'}
                                   {...register('district')}
                            />
                            {errors?.district?.message &&
                                <div className='mt-2'>
                                    <ErrorMessageComponent name={'district'} errors={errors}/>
                                </div>
                            }
                        </div>

                        <div className={`col-12 col-md-6 py-2`}>
                            <div>
                                <label htmlFor={`city_${activeStep.toLowerCase()}`}>Cidade</label>
                                <input className={`form-control  ${errors?.city?.message ? ' is-invalid' : ''}`}
                                       type="text"
                                       disabled={watch().cep?.length < 8 || loading}
                                       id={`city_${activeStep.toLowerCase()}`}
                                       name={'Cidade'}
                                       placeholder={'Cidade'}
                                       {...register('city')}
                                />
                                {errors?.city?.message &&
                                    <div className='mt-2'>
                                        <ErrorMessageComponent name={'city'} errors={errors}/>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className={`col-12 col-md-6 py-2`}>
                            <label htmlFor={`state_${activeStep.toLowerCase()}`}>Estado</label>
                            <input className={`form-control  ${errors?.state?.message ? ' is-invalid' : ''}`}
                                   type="text"
                                   id={`state_${activeStep.toLowerCase()}`}
                                   disabled={watch().cep?.length < 8 || loading}
                                   name={'Estado'}
                                   placeholder={'Sigla do Estado'}
                                   {...register('state')}
                            />

                            <div className='mt-2'>
                                <ErrorMessageComponent name={'state'} errors={errors}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`col-12 mb-7 py-2`}>
                    <label htmlFor={`complement_${activeStep.toLowerCase()}`}>Complemento</label>
                    <input
                        className={`form-control  ${errors?.complement?.message ? ' is-invalid' : ''}`}
                        type="text"
                        id={`complement_${activeStep.toLowerCase()}`}
                        name={'Complemento'}
                        placeholder={'Complemento'}
                        {...register('complement')}
                    />
                    {errors?.complement?.message &&
                        <div className='mt-2'>
                            <ErrorMessageComponent name={'complement'} errors={errors}/>
                        </div>
                    }
                </div>

                {(!isEdit || activeStep === 'CHANGE_ADDRESS') &&
                    <div className="col">
                        <div className="form-check mt-2">
                            <input className="form-check-input cursor-pointer"
                                   type="checkbox"
                                   checked={watch().is_public}
                                   onChange={(e) => setValue('is_public', e.target.checked)}
                                   id={`is_public_${activeStep.toLowerCase()}`}
                            />
                            <label className="form-check-label cursor-pointer" htmlFor={`is_public_${activeStep.toLowerCase()}`}>
                                Eu presto serviços neste endereço <span className={'text-success'}>(Isso tornará este endereço público)</span>
                            </label>
                        </div>
                    </div>
                }


                <div className={'d-flex justify-content-end mt-3'}>
                    <div className={'col-12 col-lg-4'}>
                        <Button variant={'primary'} disabled={loading} className={'w-100'} type={'submit'}>
                            {activeStep === 'INITIAL_REGISTER' ? (Env.configs?.occupation_approved_required ? 'Continuar' : 'Finalizar') : 'Salvar'}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}