import React from "react";

export const CalendarRound = ({list, selected, selectedList, isEdit}) => {
    const handleSelect = (item) => {
        let aux = selectedList;
        if(!aux.includes(item)) {
            aux.push(item);
            selected([...aux]);
        } else if(aux.length > 0) {
            aux.splice(aux.indexOf(item), 1);
            selected([...aux]);
        }
    };

    return (
        <div className={'calendar-round gap-2 justify-content-md-center pb-md-0'}>
            {list.map((item, index) => (
                <button className={`btn-reset p-2 ${selectedList.includes(item.value) ? 'selected' : ''}`} onClick={() => handleSelect(item.value)} key={index}>
                    {item.label}
                </button>
            ))}
        </div>
    )
}