import React, {useEffect, useRef, useState} from 'react';
import ServiceProviderMain from "./Components/ServiceProviderMain";
import ServiceProviderExtras from "./Components/ServiceProviderExtras";
import useApi from "../../hooks/useApi";
import {Images} from "../../helpers/Images";
import {Env} from "../../helpers/Env";
import {Col, Container} from "react-bootstrap";
import Loading from "../../components/Generic/Loading";
import {toast} from "react-hot-toast";
import ServiceProviderProfile from "./Components/ServiceProviderProfile";
import CardOfVisits from "../CardOfVists/CardOfVisits";
import {useInView} from "react-intersection-observer";
import {api as axios} from "../../services/api"


const ServicePage = (props) => {
    const user = useRef();
    const reviewsPaginate = useRef({page: 1});
    const [reviews,setReviews] = useState([]);
    const [reviewLoading, setReviewLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const api = useApi({customReturn: true, loadingControl: false ,debounceTimer: 0});
    const occupation = useRef({});
    const [phoneNumber, setPhoneNumber] = useState(undefined);
    const [requestContactLoading, setRequestContactLoading] = useState(false);
    const [activeMode, setActiveMode] = useState('MOBILE');

    const getData = async (id, cb) => {
        setLoading(true);
        api.get('user/'+id+'/full', async (res) => {
            user.current = res;
            for(let i = 0; i < res.occupations.length; i++) {
                if (res.occupations[i].occupation_id.toString() === props.match.params.occupation) {
                    occupation.current = res.occupations[i];
                }

            }
            if(res.profile.requested_contact_id) {
                getUserContact(res.profile.requested_contact_id);
            }
            if (window.location.pathname.indexOf("cartao-de-visitas") !== -1) {
                let res = await axios.get('search/occupations')
                res.forEach((item) => {
                    (item.occupations.find(e => {
                        if (e.id === occupation.current.occupation_id) {
                            if(e?.image.length > 25) {
                                return occupation.current.banner_image = e?.image

                            } else {
                                return occupation.current.banner_image = e?.icon

                            }
                        }
                    }))
                })
            }

            setLoading(false);
        },(e) => {
            setLoading(false);
            toast.error('Ops, não foi possível recuperar os dados do profissional, tente novamente mais tarde.')
        });
    };

    const getUserContact = (id) => {
        setRequestContactLoading(true);
        api.get(`worker-contact/${id}`, (res) => {
            setPhoneNumber(res.worker.cellphone_number);
            setRequestContactLoading(false);
        }, (e) => {
            setRequestContactLoading(false);
        })
    }

    const requestContact = async () => {
        if (user) {
            window.gtag&& window.gtag('event', 'workerContactTouch');
            setRequestContactLoading(true);
            let sendObj = {
                application: Env.application_alias,
                occupation_id: props.match.params.occupation,
                worker_id: props.match.params.id
            };
            api.post(`worker-contact/request`, sendObj, (res) => {

                user.current.profile.requested_contact_id = res?.worker_contact_id;
                getUserContact(res?.worker_contact_id);
            }, (e) => {
                setRequestContactLoading(false);
            })
        }
    }

    useEffect(() => {
        if(props.match.params.id) {
            getData(props.match.params.id);
        }
        window.scrollToElement('body');
        if (window.location.pathname.indexOf("cartao-de-visitas") !== -1) {
            document.querySelector("header").style.display = 'none'
            document.querySelector("footer").style.display = 'none'
        }

    },[]);


    const observer = useInView({
        threshold: 0,
    });

    useEffect(() => {
        if (!loading) {
            if (observer.inView) {
                setActiveMode('MOBILE')
            } else {
                setActiveMode('DESKTOP')
            }
        }

    }, [observer.inView]);

    return (
        <div className=' h-100 py-md-5' style={{minHeight: '500px'}}> { loading ?
            <Loading/>
            :
            <div>
                <div className='d-block d-md-none' ref={observer.ref}>
                    <CardOfVisits avatar={user.current?.profile?.profile_picture?.full || Images.user_place_holder}
                                  name={user.current?.profile?.first_name + ' ' + user.current?.profile?.last_name}
                                  banner={occupation.current?.banner_image}
                                  occupation={occupation.current}
                                  phoneNumber={phoneNumber}
                                  address={user.current?.addresses}
                                  workSchedule={user.current?.occupations}
                                  activities={user.current?.occupations}
                                  bio={user.current?.profile?.bio}
                                  instagramLink={user.current?.profile?.instagram_link}
                                  facebookLink={user.current?.profile?.facebook_link}
                                  requestContact={(item) => requestContact(item)}
                                  activeMode={activeMode}
                    />
                </div>

                <div className={"container-xl"}>
                    <div className={`d-none d-md-flex flex-wrap flex-column-reverse flex-md-row row g-4 content-spacing`}>
                        <Col xs={12} md={7} lg={8} className=' bg-white shadow rounded-4'>
                            <div className='d-none d-md-block service-provider-main'>
                                <ServiceProviderProfile occupation={occupation.current}
                                                        avatar={user.current?.profile?.profile_picture?.full || Images.user_place_holder}
                                                        name={user.current?.profile?.first_name + ' ' + user.current?.profile?.last_name}
                                                        instagramLink={user.current?.profile?.instagram_link}
                                                        facebookLink={user.current?.profile?.facebook_link}
                                />
                            </div>


                            <div className='d-none d-md-block'>
                                <ServiceProviderMain params={props.match.params}
                                                     activities={user.current?.occupations}
                                                     actualOccupation={occupation.current}
                                                     lastSeen={user.current?.profile?.last_seen}
                                                     occupationName={occupation.current?.name}
                                                     avatar={user.current?.profile?.profile_picture?.full || Images.user_place_holder}
                                                     name={user.current?.profile?.first_name + ' ' + user.current?.profile?.last_name}
                                                     address={user.current?.addresses}
                                                     bio={user.current?.profile?.bio}
                                                     activeMode={activeMode}
                                />
                            </div>
                        </Col>

                        <Col xs={12} md={5} lg={4} className='service-provider-extras '>
                            <div className='d-none d-md-flex flex-column gap-3'>
                                <ServiceProviderExtras requestContactLoading={requestContactLoading}
                                                       phoneNumber={phoneNumber}
                                                       requestContact={(item) => requestContact(item)}
                                                       occupations={user.current?.occupations}
                                                       actualOccupation={occupation.current}
                                />
                            </div>
                        </Col>

                    </div>
                </div>
            </div>
        }
        </div>
    );
};

ServicePage.propTypes = {};

export default ServicePage;
