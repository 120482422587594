import React, {useEffect} from 'react'
import ComponentsFaq from "../../components/components-faq";


const FaqPage = () => {

    useEffect(() => {
        window.scrollToElement('body');
    }, []);

    return (
        <div style={{margin: "50px 0"}}>
            <div className="container-fluid container-xl">
                <ComponentsFaq/>
            </div>
        </div>
    )
}

export default FaqPage