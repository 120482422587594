
import {Images} from "./Images";

export const Env = {
    debug: process.env.NODE_ENV === 'production' ? false : true,
    ambience: process.env.NODE_ENV  === 'production' ? 'prod' : 'dev',
    api_url: process.env.NODE_ENV  === 'production'? 'https://prod-api.crafty.work' : 'https://dev-api.crafty.work',
    api_club_url: process.env.NODE_ENV === 'production' ? 'https://api-club.crafty.work' : 'https://apidev-club.crafty.work',
    api_bluetrix : 'https://api-white-label.bluetrix.com.br',
    header: {
        'X-Client-Device': 'web',
        Accept: 'application/json',
        'X-Version-Name': '2.0.0',
        'Content-Type': 'application/json',
        // 'X-App-Package': 'com.crafty.contrata-mais.web'
    },
    header_club: {
        Accept: 'application/json',
        'Content-Type': "application/json",
        "X-Requested-With": "XMLHttpRequest"
    },
    configs:{},
    services_available: ['crafty'], // 'crafty', 'sine', 'club'
    phone_validation: false,
    version: '1.0.1',
    application_title: 'Geração Serviço',
    application_title_uppercase: 'GERAÇÃO SERVIÇO',
    application_name: 'br.com.bluetrix.geracaoservico.web',
    application_alias: 'geracaoservico',
    application_logo: Images.logo_header ,
    state_restriction_uf: 'RO',
    state_restriction_name: 'Rondônia' ,
    state_full: null,
    support_number: '556999519534',
    support_email: 'contato@geracaoservico.ro.gov.br',
    appointment_id: '11',

    worker_play: 'https://play.google.com/store/apps/details?id=br.com.bluetrix.geracaoservico',
    worker_apple: 'https://apps.apple.com/us/app/gera%C3%A7%C3%A3o-servi%C3%A7o-p-prestador/id6444009789',
    client_play: 'https://play.google.com/store/apps/details?id=br.com.bluetrix.geracaoservico.hirer',
    client_apple: 'https://apps.apple.com/us/app/gera%C3%A7%C3%A3o-servi%C3%A7o/id6444009460',
    number: null,

    biz_apple_store: 'https://apps.apple.com/br/app/crafty-biz-para-lojistas/id1444677721',
    biz_play_store: 'https://play.google.com/store/apps/details?id=br.com.crafty.biz',
    instagram: 'geracaoservico',
    facebook: 'geracaoservico.oficial',
};
