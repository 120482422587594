import React from 'react'
import {Images} from "../../helpers/Images";
import {Col} from "react-bootstrap";

const LogoUF = ({vertical}) => {

    return (
        <div className='d-flex justify-content-center'>
            {vertical ?
                <Col xs={7} md={4} lg={3} xl={2}>
                    <img src={Images.logo_gov.logo_uf_v} className='w-100' alt="logo rondônia"/>
                </Col>
                :
                <Col xs={10} md={6} lg={5} xl={4} xxl={3}>
                    <img src={Images.logo_gov.logo_uf_h} className='w-100' alt="logo rondônia"/>
                </Col>
            }
        </div>
    )
}

export default LogoUF