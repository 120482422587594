import React from 'react'
import pdfLight from "../../../../img/svg/pdf-light.svg";
import {Button, Modal} from "react-bootstrap";
import {PiArrowsClockwiseFill} from "react-icons/pi";
import {BsFillSendFill} from "react-icons/bs";

const ConfirmImageModal = ({show, onClose, preview, changeImage, documentTitle, disableButton, confirmImage, isPdf}) => {

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton={true}>
                <h5 className={'mb-0'}>Esta imagem será enviada para revisão</h5>
            </Modal.Header>
            <Modal.Body>
                <div>

                    <ul>
                        {isPdf ?
                            <>
                                <li>
                                    Confirme se o arquivo selecionado está correto
                                </li>
                            </>
                            :
                            <>
                                <li>Verifique se a imagem não está borrada</li>
                                <li>Verifique se o texto do documento está legível na imagem</li>
                            </>
                        }
                    </ul>


                    <div style={{margin: '0 -1rem'}} className={'text-bg-primary bg-opacity-10 text-primary py-2'}>
                        <p className={'mb-0 fw-bold text-center'}>{documentTitle}</p>
                    </div>
                    {(isPdf) ?
                        <>
                            <img style={{maxHeight: 80, width: '100%'}}
                                 src={pdfLight}
                                 alt="pdf"
                                 className={'mt-2'}
                            />
                        </>
                        :
                        <img style={{maxHeight: 400, width: '100%', objectFit: 'contain'}}
                             src={preview?.image}
                             alt={preview?.note}
                        />
                    }

                    <div className={'text-center mt-2'}>
                                <span className={'limit-rows clamp-2'}>
                                    {preview?.note}
                                </span>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={changeImage}
                        style={{flex: '0 1 25%'}}
                        disabled={disableButton}
                        variant={'outline-dark'}
                        className={'d-flex fl' +
                            'ex-center gap-2'}>
                    Trocar
                    <PiArrowsClockwiseFill/>
                </Button>
                <Button onClick={confirmImage}
                        style={{flex: '0 1 50%'}}
                        className={'d-flex flex-center gap-2'}
                        disabled={disableButton}
                >
                    {disableButton ?
                        <>
                            <span className="spinner-border spinner-border-sm text-ibrami-primary" role="status"></span>
                            Aguarde...
                        </>
                        :
                        <>
                            Enviar
                            <BsFillSendFill/>

                        </>
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmImageModal
