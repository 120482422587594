import React from 'react';

import PropTypes from 'prop-types';
import NotFound from "../../../components/Generic/NotFound";
import {Images} from "../../../helpers/Images";
import {Col} from "react-bootstrap";
import {BsFillStarFill} from "react-icons/bs";
import {CgPin} from "react-icons/cg";
import InfiniteScroll from "../../../components/Generic/InfiniteScroll";

const CardListComponent = ({servicesList, loading, occupation_id, fetchMore}) => {

  return(
      <>
        {(servicesList?.length > 0 || loading) &&
            <div className='d-flex flex-wrap mt-4'>
                {servicesList?.map((item, index) => {
                    let name = item.name.split(' ');

                    return (
                        <Col xs={12} md={6} lg={4} className='p-2' onClick={() => window.open(`/prestador-de-servico/${item.id}/${occupation_id}`, "_blank")}>
                            <div className='hire-service-card bg-white shadow-sm rounded-3'>
                                <div className='d-flex gap-2 p-2'>
                                    <div>
                                        <img className='rounded-circle' style={{objectFit: 'cover'}} height={80} width={80} alt='avatar' src={item.profile_picture.thumbnail || Images.user_place_holder}/>
                                    </div>
                                    <div className='flex-grow-1'>
                                        <div>
                                            <p className='fs-5 fw-bolder mb-0'>
                                                {name.length === 1 ? name[0] : `${name[0]} ${name[name.length -1]}`}
                                            </p>
                                        </div>

                                        <div className='d-flex align-items-center gap-2 fs-6' style={{color: item.ratings_avg === 0 ? '#005c9f' : '#ffc53d'}}>
                                            {item.price_avg !== 0 &&
                                                <span className='fw-bold text-success'>
                                                    {item.price_avg <= 2 ? '$' :(item.price_avg <= 3)? '$$' :(item.price_avg <= 4)? '$$$': (item.price_avg <= 5)&& '$$$$'}
                                                </span>
                                            }

                                            {item.price_avg !== 0 &&
                                                <span>
                                                    /
                                                </span>
                                            }

                                            <span className='d-flex justify-content-center'>
                                                <BsFillStarFill/>
                                            </span>

                                            <span className='d-flex justify-content-center'>
                                                {item.ratings_avg === 0 ?
                                                    'Novo' :
                                                    <>
                                                        {item.ratings_avg.toFixed(1)}
                                                        <>({item.ratings_count})</>
                                                    </>
                                                }
                                            </span>
                                        </div>

                                        <div className='d-flex align-items-center text-muted'>
                                            <CgPin/> {window.getDistance(item.distance)}
                                        </div>
                                    </div>
                                </div>

                                {item.bio &&
                                    <div>
                                        <div className='border-top my-2'/>
                                        <div className='px-3'>
                                            <div className='limit-rows clamp-4 text-justify'>
                                                {item.bio}
                                            </div>
                                        </div>
                                    </div>
                                }

                                {index === servicesList?.length - 9  &&
                                    <>
                                        <InfiniteScroll fetchMore={()=> fetchMore()}/>
                                    </>
                                }
                            </div>
                        </Col>
                    )
                })}
            </div>
        }
      </>
  )
}

CardListComponent.propTypes = {
  loading: PropTypes.bool,
  servicesList: PropTypes.any,
  occupation_id: PropTypes.any,
};

export default CardListComponent
