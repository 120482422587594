import React from 'react'
import {Col} from "react-bootstrap";

const DividerCustom = () => {

    return (
        <div className='de-flex'>
            <Col xs={3} md={2} lg={1} className='divider-line'>
                <div className='line'/>
                <div className='polygon'/>
            </Col>
        </div>
    )
}

export default DividerCustom