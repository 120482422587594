import React from 'react';

import {Env} from "../helpers/Env";
import {Images} from "../helpers/Images";
import {toast} from "react-hot-toast";
import {CgClose} from "react-icons/cg";


export const showNotification = () => {
    toast(
        <div>
            <div className='text-end'>
                <CgClose onClick={() => toast.dismiss()} style={{cursor:"pointer"}} size={20}/>
            </div>
            <div style={{marginBottom: 16, textAlign: 'center'}}>
                <img src={Images.logo} style={{height: 50}} alt='logo'/>
            </div>
            <div className='text-center'>
                Obrigado por tentar utilizar nossos serviços, nesta plataforma só é pertinente ao estado de {Env.state_restriction_name}
            </div>
        </div>, {
            position:"top-right",
            duration: 8000,
            style: {
                maxWidth:'400px'
            },
        }
    )
};


export const checkStateRestriction = (result) => {
    if (Env.state_restriction_uf !== 'none') {
        if (result) {
            for(let i = 0; i < result?.address_components?.length; i++) {
                for(let j = 0; j < result.address_components[i].types.length; j++) {
                    if(result.address_components[i].types[j] === 'administrative_area_level_1') {
                        if(result.address_components[i].short_name === Env.state_restriction_uf) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            }
            return false;
        }
    } else {
        return !!result;
    }
};

export const globalMaskReal = (a) => {

    if(a) {
        let aux = a;
        if(typeof a === 'string') {
            aux = a.replace(',','.');
        }
        if(isNaN(aux)) {
            aux = aux.replace(/\./g, '');
        }
        aux = Number(aux).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
        return aux.toString();
    } else {
        return '---';
    }
}


export const checkDocsPending = (data) => {
    if (data) {
        if(data?.docs?.status === 'approved') {
            return false
        }
        return ((data?.docs.selfie_picture !== 'approved') || (data?.docs.photo_id_front !== 'approved') || (data?.docs.proof_of_residence !== 'approved') || (data?.docs.photo_id_back !== 'approved'))
    }

}

export const checkPendingData = (data) => {
    if (data) {
        if (data.profile.bio === null) {
            return true
        } else if (data?.occupations?.length === 0 || data?.addresses?.length === 0) {
            return true
        } else if (Env.configs?.occupation_approved_required && (checkDocsPending(data))) {
            return true
        } else {
            return false
        }
    }
}