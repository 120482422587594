import React from "react";

const LabelWithLibs = ({label, labelIcon, className}) => {

    return (
        <>
            <label className={`d-flex align-items-center gap-1 ${className ? className : ''}`}>
                {labelIcon && labelIcon} {label}
            </label>
        </>
    )
}

export default LabelWithLibs