import React, {useEffect, useState} from 'react'
import {Button, Modal, Spinner} from "react-bootstrap";
import Select from "react-select";
import {Env} from "../../../helpers/Env";
import {isEmpty} from "lodash";
import {Images} from "../../../helpers/Images";
import useGeolocation from "../../../hooks/Geolocation";
import {useHistory} from "react-router-dom";

const StepSearchProviderModal = ({openModal, closeModal, options, handleSearchModal}) => {
    const urlParams = new URLSearchParams(window.location.search)
    const {setInputGoogle, handleChangeAddress, loadingAddress, formattedAddress} = useGeolocation()
    const history = useHistory();

    const [firstMessage, setFirstMessage] = useState(true)
    const [showLocation, setShowLocation] = useState(false)
    const [activeButton, setActiveButton] = useState(true)

    const handleChange = (label,e) => {
        const urlParams = new URLSearchParams(window.location.search)

        if (label === `service`) {

            if (e === null) {
                setActiveButton(true)
                setShowLocation(false)
                urlParams.delete(`occupation_id`)
            } else {
                setShowLocation(true)
                urlParams.append(`occupation_id`, `${e.value}`)
            }
        } else if (label === `name`) {
            if (urlParams.get(`worker`) !== e) {
                urlParams.delete(`worker`)
                e.length > 2 && urlParams.set(`worker`, e)
            }

        } else {
            handleChangeAddress(e)
            isEmpty(e) && setActiveButton(true)
        }
        history.push(`/quero-contratar?${urlParams}`)
    }

    useEffect(() => {
        if(openModal) {
            setInputGoogle(`geocodeModal`)
        }
    },[openModal]);

    useEffect(() => {
        if (urlParams.has(`latitude`)) {
            if (showLocation) {
                setActiveButton(false)
            }
        }
    },[window.location.search]);

    return (
        <Modal show={openModal} backdrop='static' centered size={"lg"}>
            <Modal.Header className={`${!firstMessage ? 'd-block' : 'd-none'} text-bg-primary`}>
                <h3 className='mb-0'>Encontre o prestador de serviço ideal!</h3>
            </Modal.Header>
            <Modal.Body style={{minHeight:300}}>
                <div className={`${firstMessage ? 'd-block' : 'd-none'}`}>
                    <div className='d-flex justify-content-center'>
                        <img height={300} src={Images.connected} alt="connected"/>
                    </div>
                    <h5 className='text-center'>
                        Parece que é a primeira vez que você está buscando um serviço por esse dispositivo, vamos te auxiliar para encontrar o prestador ideal!
                    </h5>
                    <div>
                        <Button variant="success" className='w-100 mt-2' onClick={() => setFirstMessage(!firstMessage)}>Vamos lá!</Button>
                    </div>
                </div>

                <div className={`${!firstMessage ? 'd-block' : 'd-none'}`}>
                    <h6>
                       Primeiro, que tipo de serviço você precisa?<span className='text-danger'> *</span>
                    </h6>
                    <div>
                        <Select placeholder='Digite o que procura'
                                noOptionsMessage={() => 'Serviço não encontrado'}
                                onChange={(e) => handleChange(`service`, e)}
                                isClearable
                                isSearchable
                                options={options}
                        />
                    </div>
                </div>

                <div className={`mt-3 ${!firstMessage ? 'd-block' : 'd-none'}`}>
                    <h6>
                        Agora, para onde você precisa do {Env.application_title}? <span className='text-danger'>*</span>
                    </h6>
                    <div>
                        <input type="text"
                               id='geocodeModal'
                               value={formattedAddress}
                               onChange={(e) => handleChange(`geolocation`, e.target.value)}
                               className="form-control fs-7"
                               placeholder="Digite o endereço"
                        />
                    </div>
                </div>

                <div className={`mt-3 ${!firstMessage ? 'd-block' : 'd-none'}`}>
                    <h6>
                        Tem algum nome em mente? (opcional)
                    </h6>
                    <div>
                        <input type="text" onChange={(e) => handleChange(`name`, e.target.value)} className="form-control" placeholder="Digite o nome"/>
                    </div>
                </div>

                <div className={`${!firstMessage ? 'd-block' : 'd-none'}`}>
                    <Button variant={"primary"} disabled={activeButton}  onClick={() => {closeModal() ; handleSearchModal() }} className='mt-3 w-100 d-flex justify-content-center align-items-center  gap-2' >
                        {loadingAddress && <Spinner animation='border' size='sm'/>} Buscar
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default StepSearchProviderModal