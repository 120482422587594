import Route from "react-router-dom/es/Route";
import React from "react";
import Redirect from "react-router-dom/es/Redirect";
import useAuth from "../hooks/Auth";
import {useQueryClient} from "@tanstack/react-query";

export const PrivateRoute = ({component: Component, ...obj}) => {
    const queryClient = useQueryClient()
    const {data, status} = queryClient.getQueryState(['profile']);


    if (status === "loading") return <></>

    return (
        <Route {...obj}
           render={(props) => {

               if (data) {
                   return <Component {...props} />
               } else {
                   return <Redirect to='/notFound'/>
               }

           }}/>
    )
};