import React, {useEffect, useState} from 'react'
import {Env} from "../../../helpers/Env";
import Select from "react-select";
import {Button, Col, Spinner} from "react-bootstrap";
import StepSearchProviderModal from "../Modal/StepSearchProviderModal";
import {useHistory} from "react-router-dom";
import useGeolocation from "../../../hooks/Geolocation";
import {FaSearch} from "react-icons/fa";

const SearchWorkerComponent = ({services, lastWorkerSearch, handleSearch, filtersQuery}) => {
    const {formattedAddress, setInputGoogle, handleChangeAddress, loadingAddress} = useGeolocation()

    const [modalStep, setModalStep] = useState(false)
    const history = useHistory();

    const [data, setData] = useState({})

    const checkPredefinedValues = () => {

        if (filtersQuery.occupation_id && filtersQuery.latitude) {
            let values = JSON.parse(JSON.stringify(filtersQuery));

            lastWorkerSearch.setObject(filtersQuery)

            values.service = {
                label:  services[services?.map(e => e.value).indexOf(Number(values.occupation_id))]?.label,
                value: Number(values.occupation_id)
            }

            setData(values)
            handleChangeAddress(values.loc)
            handleSearch()

        } else if (lastWorkerSearch.getObject()) {
            let aux = JSON.parse(JSON.stringify(lastWorkerSearch.getObject()));
            aux.service = {
                label:  services[services?.map(e => e.value).indexOf(Number(aux.occupation_id))]?.label,
                value: Number(aux.occupation_id)
            };
            setData(aux);
            handleChangeAddress(aux.loc);

            filtersQuery = lastWorkerSearch.getObject();
            const urlParams = new URLSearchParams(filtersQuery);
            history.push(`/quero-contratar?${urlParams}`);
            handleSearch()
        } else {
            setModalStep(true);
        }
    }

    const handleChange = (label, e) => {
        const urlParams = new URLSearchParams(window.location.search)
        if (label === 'service') {
            if (e === null) {
                urlParams.delete(`occupation_id`)
                setData({...data, service: e})
            } else {
                urlParams.set(`occupation_id`, `${e.value}`)
                setData({...data, service: e})
            }

        } else if (label === 'name') {
            if (urlParams.get(`worker`) !== e) {
                urlParams.delete(`worker`)
                e.length > 2 && urlParams.set(`worker`, e)
            }
            setData({...data, worker:e})
        }
        history.push(`/quero-contratar?${urlParams}`)
    }

    const handleSearchModal = () => {
        let qs = JSON.parse(JSON.stringify(filtersQuery));
        qs.service = {
            value: Number (qs.occupation_id),
            label: services[services?.map(e => e.value).indexOf(Number (qs.occupation_id))]?.label
        }
        setData(qs)
        handleChangeAddress(qs.loc)
        handleSearch()
    }

    useEffect(() => {
        if (services) {
            checkPredefinedValues()
            setInputGoogle('geocodeWorker')
        }
    }, [services])

    return (
        <>
            <>
                <StepSearchProviderModal openModal={modalStep}
                                         closeModal={() => setModalStep(!modalStep)}
                                         handleSearchModal={() => handleSearchModal()}
                                         options={services}
                />

                <div className='d-flex justify-content-center mt-2 '>
                    <Col xs={12} md={10} lg={8} xl={7} xxl={6} className='shadow-lg bg-gray-100 bg-md-transparent rounded-3 p-3' >
                        <div className='d-flex flex-wrap'>
                            <Col xs={12} md={6} className='dropdown-custom'>
                                <div className='p-2'>
                                    <label className="form-label text-dark text-md-white">Qual serviço está buscando?</label>
                                    <Select placeholder='Digite o que procura'
                                            value={data?.service}
                                            noOptionsMessage={() => 'Serviço não encontrado'}
                                            onChange={(e) => handleChange(`service`, e)}
                                            isClearable
                                            isSearchable
                                            options={services}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className='p-2'>
                                    <label htmlFor="name" className="form-label text-dark text-md-white">Tem um nome em mente?</label>
                                    <input type="text"
                                           value={data?.worker}
                                           onChange={(e) => handleChange(`name`,e.target.value)}
                                           className="form-control"
                                           id="name"
                                           placeholder="Nome"
                                    />
                                </div>
                            </Col>
                        </div>

                        <div>
                            <Col xs={12} >
                                <div className='p-2'>
                                    <label htmlFor="geocode" className="form-label text-dark text-md-white">Para onde você precisa do {Env.application_title}?</label>
                                    <input type="text" className="form-control"
                                           value={formattedAddress}
                                           onChange={(e) => handleChangeAddress(e.target.value)}
                                           id="geocodeWorker"
                                           placeholder="Digite o endereço"
                                    />
                                </div>
                            </Col>
                        </div>

                        <div className='d-flex justify-content-center'>
                            <Col xs={12} md={4} className='p-2 d-flex justify-content-center'>
                                <Button className='w-100' variant={"success"} disabled={loadingAddress} onClick={() => {
                                    window.gtag('event', 'search_worker');
                                    handleSearch()
                                }}>
                                    <span className='de-flex gap-2'>{loadingAddress && <Spinner animation='border' size='sm'/>}{!loadingAddress && <FaSearch/>}Buscar</span>
                                </Button>
                            </Col>
                        </div>
                    </Col>
                </div>
            </>
        </>
    )
}

export default SearchWorkerComponent
