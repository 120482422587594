import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import {Env} from "../../../helpers/Env";
import {Images} from "../../../helpers/Images";
import {BsSearch} from "react-icons/bs";

const StepToApp = () => {

    return (
        <>
            <Container>
                <div>
                    <h2 className='text-primary text-center mb-4'>
                        Amplie seu atendimento <br/>através do {Env.application_title}<br/> GRATUITAMENTE!
                    </h2>
                </div>
                <Row className='gx-4 gy-4 gy-md-5 gy-lg-0  justify-content-center'>
                    <Col md={6} lg={4} >
                        <div className='bg-white rounded-3 shadow p-4 h-100 d-flex flex-column justify-content-between'>
                            <div>
                                <div className='da-flex gap-2'>
                                    <h4 style={{width:40, height:40}} className='rounded-circle text-center text-white border p-2 de-flex bg-primary'>
                                        1
                                    </h4>
                                    <h5 className='text-secondary'>
                                        Faça seu cadastro
                                    </h5>
                                </div>

                                <div>
                                    <h6 className='fw-light text-justify'>
                                        Dê o primeiro passo para alcançar mais clientes!
                                    </h6>
                                </div>
                            </div>

                            <div className='text-center'>
                                <img src={Images.scenes.scene_1} className='w-75' alt="scene"/>
                            </div>
                        </div>
                    </Col>

                    <Col md={6} lg={4}>
                        <div className='bg-white rounded-3 shadow p-4 h-100 d-flex flex-column justify-content-between'>
                            <div>
                                <div>
                                    <h5 className='da-flex gap-2 text-secondary'>
                                        <div style={{width:40, height:40}} className='rounded-circle text-white border p-2 de-flex bg-primary'>
                                            2
                                        </div>
                                        <div>
                                            Valide seus documentos
                                        </div>
                                    </h5>
                                </div>
                                <div>
                                    <h6 className='fw-light text-justify'>
                                        Para maior segurança, para você e seus clientes, precisamos validar seus dados!
                                    </h6>
                                </div>

                            </div>

                            <div className='text-center'>
                                <img src={Images.scenes.scene_double} className='w-75' alt="scene"/>
                            </div>
                        </div>

                    </Col>

                    <Col md={6} lg={4}>
                        <div className='bg-white rounded-3 shadow p-4 h-100 d-flex flex-column justify-content-between'>
                            <div>
                                <div>
                                    <h5 className='da-flex gap-2 text-secondary'>
                                        <div style={{width:40, height:40}} className='rounded-circle text-white border p-2 de-flex bg-primary'>
                                            3
                                        </div>
                                        Aguarde os clientes
                                    </h5>
                                </div>

                                <div>
                                    <h6 className='fw-light'>
                                        Agora é só aguardar os clientes entrarem em contato diretamente com você!
                                    </h6>
                                </div>
                            </div>
                            <div>
                                <div className='text-center'>
                                    <img src={Images.scenes.scene_2} className='w-75' alt="scene"/>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default StepToApp