import React, {useEffect, useState} from 'react'
import doc1 from "../../../../img/docSteps/doc1.png";
import doc4 from "../../../../img/docSteps/doc4.png";
import doc3 from "../../../../img/docSteps/doc3.png";
import doc5 from "../../../../img/docSteps/doc5.png";
import shield from "../../../../img/docSteps/shield.png";
import {DocsAttachmentsComponent} from "../DocsAttachmentsComponent";
import {useQueryClient} from "@tanstack/react-query";
import {Env} from "../../../../helpers/Env";

export const SendDocumentsComponent = (props) => {
    const query = useQueryClient();
    const [awaitingApprove, setAwaitingApprove] = useState(null);

    const steps = [
        {
            image: doc4,
            title:'Foto em tempo real',
            subTitle: 'Toque aqui para tirar uma foto sua em tempo real para verificar seu perfil',
            onlyImage:true,
            actionSheet:true,
            status: props?.user?.docs?.selfie_picture,
            api_path: 'selfie-picture',
            // sendOnlyMobile: true,
            selfie: true
        },
        {
            image: doc3,
            title:'Documento com foto',
            subTitle: <>Toque aqui para enviar um documento oficial com foto (Ex. RG, CNH), que contenha o número de <strong>CPF</strong></>,
            actionSheet:true,
            onlyImage:true,
            status: props?.user?.docs?.photo_id_front,
            api_path: 'photo-id-front',
        },
        {
            image: doc5,
            title:'Verso de documento com foto',
            subTitle: 'Toque aqui e envie o verso do documento oficial com foto',
            actionSheet:true,
            onlyImage:true,
            status: props?.user?.docs?.photo_id_back,
            api_path: 'photo-id-back',
        },
        {
            image: doc1,
            title:'Comprovante de Residência',
            subTitle: 'Toque e envie um comprovante de residência',
            maxAttachments:1,
            multipleFiles:false,
            actionSheet:true,
            status: props?.user?.docs?.proof_of_residence,
            api_path: 'proof-of-residence',
        },
    ]

    const checkApprovalQueue = (index, docs) => {
        if (index < docs?.length ) {
            if (docs[index].status === "approval_queue" || docs[index].status === "approved") {
                return checkApprovalQueue(index + 1, steps)
            } else {
                return index
            }
        } else {
            return index
        }
    }

    useEffect(() => {
        let aux = checkApprovalQueue(0, steps)
        if (aux === steps.length) {
            setAwaitingApprove(true)
        }
    }, [props.user?.docs]);



    return (

        <div id={`docs`}>
            {awaitingApprove ?
                <div
                    className={'text-center border border-success rounded-2 border-2 text-success text-bg-success bg-opacity-10 p-2 mb-3'}>
                    <i className="ki-outline ki-shield-tick " style={{fontSize:"4.5rem"}}></i>
                    <h3>Documentos enviados com sucesso!</h3>
                    <p className={'mb-0'}>
                        Após a validação dos seus dados, você terá acesso a todas as funcionalidades
                        do {Env.configs?.app_name}!
                    </p>
                </div>
                :
                <div className={'text-center'}>
                    <h3>
                    Toque nos card e envie as imagens solicitadas para aprovar seu cadastro!
                    </h3>
                    <p>
                        Para oferecer mais segurança e confiabilidade em nosso aplicativo precisamos confirmar algumas informações antes de divulgar seu trabalho.
                    </p>
                </div>
            }


            <div className={'row g-4 g-md-2'}>
                {steps.map((item, index) => (
                    <div className={'col-12'} key={index}>
                        <DocsAttachmentsComponent dropCardImage={item.image}
                                                  maxAttachments={1}
                                                  title={item.title}
                                                  subTitle={item.subTitle}
                                                  actionSheet={item.actionSheet}
                                                  status={item.status}
                                                  selfie={item.selfie}
                                                  onlyImage={item.onlyImage}
                                                  apiPath={item.api_path}
                                                  sendOnlyMobile={item.sendOnlyMobile}
                                                  refetchProfile={() => query.refetchQueries(['profile'])}
                                                  data={query.getQueryData(['profile'])}
                        />

                    </div>
                ))}
            </div>
            <div className={'mt-3'}>
                <div className={'text-bg-success bg-opacity-10 card-body rounded-2 border border-success'}>
                    <div className={'d-flex gap-3'}>
                        <img src={shield} style={{maxWidth:50, objectFit:'contain'}} alt="escudo"/>
                        <span className={'text-gray-700'}>
                               Fique tranquilo(a), somente uma equipe técnica autorizada terá acesso aos dados para realizar a aprovação do seu cadastro.
                           </span>
                    </div>
                </div>
            </div>
        </div>

    );
}
