import React from 'react'
import { FaLinkedinIn, FaPaperclip, FaShare, FaTwitter, FaWhatsapp} from "react-icons/fa";
import {MdShare} from "react-icons/md";
import {FaFacebookF} from "react-icons/fa";
import {Dropdown, Nav} from "react-bootstrap";
import {toast} from "react-hot-toast";
import {Env} from "../../helpers/Env";

const ShareTo = (props) => {

    const handleSocialMedia = (media) => {
        let link = encodeURIComponent(`${window.location.origin}${window.location.pathname}${props?.shareSearch ? window.location.search : ''}`)
        let platform = ''

        let w = ((window.innerWidth * 50) / 100)
        let h = ((window.innerHeight * 50) / 100)
        let top = (window.screen.height / 2) - (h/2)
        let left = (window.screen.width / 2) - (w/2)

        if (media === 'copyToClipboard') {
            if (window.screen.width < 600) {
                const elem = document.createElement('textarea');
                elem.value = decodeURIComponent(link);
                document.body.appendChild(elem);
                elem.select();
                document.execCommand('copy');
                document.body.removeChild(elem);
            } else {
                navigator.clipboard.writeText(decodeURIComponent(link));
            }
            return toast.success('Copiado para a área de transferência!',{id:1})
        }

        if (media === 'facebook') {
            platform = `https://www.facebook.com/sharer/sharer.php?u=${link}`
        } else if (media === 'linkedin') {
            platform = `https://www.linkedin.com/shareArticle?mini=true&url=${link}`
        } else if (media === 'twitter') {
            platform = `https://twitter.com/intent/tweet?url=${link}`
        } else if (media === 'whatsapp') {
            platform = `https://${window.screen.width < 762 ? 'api' : 'web'}.whatsapp.com/send?text=${link}`
            return window.open(platform, `blank`)
        }

        window.open(platform,'blank', `toolbar=no, location=no,status=no, menubar=no,scrollbars=yes,resizable=no,top=${top},left=${left}, width=${w},height=${h}`)
    }


    return (

        <Dropdown className={'share-to'} style={props.style}>
            <Dropdown.Toggle as={'button'} className={'btn-reset w-100 share-toggle d-flex flex-center gap-2 p-2 border border-transparent border shadow shadow-md-none border-primary-hover rounded-2'}>
                <MdShare size={20} style={{margin:0}}/>
                <span>{props?.title ?? "Compartilhar perfil"}</span>
                <i className="ki-duotone ki-down"></i>
            </Dropdown.Toggle>


            <Dropdown.Menu className='my-1'>
                <Dropdown.Item className='share-to-item' onClick={() => handleSocialMedia('whatsapp')}>
                    <FaWhatsapp/>
                    <span>WhatsApp</span>
                </Dropdown.Item>
                <Dropdown.Item className='share-to-item' onClick={() => handleSocialMedia('facebook')}>
                    <FaFacebookF/>
                    <span>Facebook</span>
                </Dropdown.Item>
                <Dropdown.Item className='share-to-item' onClick={() => handleSocialMedia('linkedin')}>
                    <FaLinkedinIn/>
                    <span>Linkedin</span>
                </Dropdown.Item>
                <Dropdown.Item className='share-to-item' onClick={() => handleSocialMedia('twitter')}>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg>
                    <span>Twitter</span>
                </Dropdown.Item>
                <Dropdown.Item className='share-to-item' onClick={() => handleSocialMedia('copyToClipboard')}>
                    <FaPaperclip/>
                    <span>Copiar link</span>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>

    )
}

export default ShareTo
