import React, {useEffect, useState} from 'react'
import {Col, Button, Spinner} from "react-bootstrap";
import {Images} from "../../../helpers/Images";
import Select from "react-select";
import {Env} from "../../../helpers/Env";
import useGeolocation from "../../../hooks/Geolocation";
import useApi from "../../../hooks/useApi";
import {toast} from "react-hot-toast";
import {useHistory} from "react-router-dom";

const SearchWorkerHeader = () => {


    const [options, setOptions] = useState()
    const [selectOption, setSelectOption] = useState()

    const {handleChangeAddress, setInputGoogle, completedAddress, formattedAddress, loadingAddress} = useGeolocation(true);
    const api = useApi();
    const history = useHistory();
    const background = {
        backgroundImage: `url(${Images.home_page_svg.background_home})`,
        backgroundPosition:" center",
        backgroundSize:"cover",
        marginTop: -125
    }

    const getServices = () => {
        let aux = []
        api.get('search/occupations', (res) => {

            res.forEach((item) => {
                item.occupations.map((subItem) => {
                    return (
                        aux.push({
                            value: subItem.id,
                            label: subItem.name,
                        })
                    )
                })
            });
            setOptions(aux);
        }, (e) => {
            console.log(e)
        });
    };

    const handleSearch = () => {
        let aux = {
            occupation_id: selectOption?.value,
            latitude: completedAddress?.lat,
            longitude: completedAddress?.long,
            loc: completedAddress?.loc,
        }

        if (!aux.occupation_id) {
            toast.error(`Necessário informar o tipo de serviço!`,{ id: 'clipboard' })
            return
        } else if (!aux.latitude) {
            toast.error(`Necessário informar o endereço!`,{ id: 'clipboard' })
            return
        }

        const urlParams = new URLSearchParams(aux);
        history.push(`/quero-contratar?${urlParams}`);
    }

    useEffect(() => {
        getServices()
        setInputGoogle('geocodeHome')

    }, [])
    return (
        <div style={background}>
            <div className='container d-flex flex-wrap justify-content-evenly align-items-center gap-2'  style={{padding:'180px 10px'}}>

                <Col xs={12} md={6} xl={5} className='p-3 bg-gray-100 bg-md-transparent rounded-3 shadow-lg'>
                    <div>
                        <h3 className='text-primary text-md-white'>
                            Encontre profissionais de confiança perto de você!
                        </h3>
                    </div>

                    <div className='d-flex flex-wrap gap-3'>
                        <Col xs={12} md={12} className='dropdown-custom'>
                            <div>
                                <label className="form-label text-dark text-md-white">Qual serviço você está buscando?</label>
                                <Select placeholder='Ex: Diarista, Encanador, Eletricista'
                                        noOptionsMessage={() => 'Serviço não encontrado'}
                                        onChange={(e) => setSelectOption(e)}
                                        isClearable
                                        isSearchable
                                        options={options}
                                />
                            </div>
                        </Col>

                        <Col xs={12} md={12}>
                            <div>
                                <label htmlFor="geocode" className="form-label text-dark text-md-white">Para onde você precisa do serviço de um profissional?</label>
                                <input type="text" className="form-control"
                                       id="geocodeHome"
                                       value={formattedAddress}
                                       onChange={(e) => handleChangeAddress(e.target.value)}
                                       placeholder="Digite o endereço"
                                />
                            </div>
                        </Col>

                        <Col xs={12} md={12}>
                            <div>
                                <Button variant={"success"} disabled={loadingAddress} className='w-100' onClick={() => {
                                    handleSearch();
                                    window.gtag('event', 'search_worker_home')
                                }}>
                                    {loadingAddress && <Spinner animation='border' size='sm'/>} Encontrar profissional
                                </Button>
                            </div>
                        </Col>
                    </div>
                </Col>
                <Col xs={4} className='d-none d-md-block'>
                    <img style={{width: '100%'}} src={Images.home_page_svg.search_home} alt='client'/>
                </Col>

            </div>
        </div>
    )
}

export default SearchWorkerHeader