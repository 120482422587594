import React, {useState} from "react";

import {Button, Col, Modal, Spinner} from "react-bootstrap";
import {toast} from "react-hot-toast";
import {useForm} from "react-hook-form";
import NumberFormat from "react-number-format";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import {Env} from "../../../helpers/Env";
import useApi from "../../../hooks/useApi";

import {BsAt} from "react-icons/bs";
import {FaIdCard} from "react-icons/fa";

import LabelWithLibs from "../../../components/Generic/LabelWithLibs";
import InputHF from "../../../components/Generic/InputHF";




const ForgetPasswordModal = ({openModal, closeModal,changeStepModal }) => {
    const [loading, setLoading] = useState(false)


    const forgetSchema = yup.object().shape({
        cpf: yup.string().required('É necessário informar seu CPF').test('cpf', 'Insira um CPF válido', (value) => {
            return value && value.length === 11
        }),
        email: yup.string().email('Insira um e-mail válido').required('O email é obrigatório'),
    })

    const api = useApi();
    const {register, handleSubmit, setValue, formState: { errors },} = useForm({
        resolver: yupResolver(forgetSchema),
    });

    const onSubmit = (data) => {
        setLoading(true);
        let sendApi = {
            ...data,
            application_name: Env.application_name,
        };

        api.post(`access/forgot-password-web`, sendApi, () => {
            toast.success('As instruções de recuperação de senha foram encaminhadas para seu email!');
            changeStepModal('login');
            setLoading(false);
        }, (e) => {
            toast.error(e.message || 'Não foi possível recuperar sua senha, tente novamente');
            setLoading(false);
        });
    }

    const onError = () => {return 0}

    return (
        <Modal show={openModal} onHide={closeModal} size={"lg"} scrollable>

            <Modal.Body className='p-0'>
                <Modal.Header className='border-0 p-3 position-absolute' style={{right:0}} closeButton/>
                <div className='d-flex flex-column flex-lg-row'>
                    <Col md={12} lg={4} className='bg-primary'>
                        <div className='de-flex justify-content-between flex-column fs-3 p-3 h-100 text-white'>
                            <div className='fw-bold'>
                                Recuperação de conta
                            </div>
                            <div className='fs-7'>
                                Você receberá um email com as informações necessárias
                            </div>
                        </div>
                    </Col>
                    <Col md={12} lg={8} className='p-3 d-flex flex-column gap-2 mt-lg-4'>
                        <form onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className='p-2 fs-4'>
                                Insira seu <span className='fw-bold'>email</span> e <span className='fw-bold'>CPF</span>
                            </div>
                            <Col xs={12} className='p-2'>
                                <InputHF label='Email'
                                         iconLabel={<BsAt size={16}/>}
                                         key='email'
                                         register={register('email')}
                                         error={errors?.email?.message}
                                />
                            </Col>

                            <Col xs={12} className='p-2'>
                                <div className={`form-floating`}>
                                    <NumberFormat className={`${errors?.cpf?.message ? 'form-control is-invalid' : 'form-control'}`}
                                                  {...register('cpf')}
                                                  onValueChange={(e) => setValue('cpf', e.value) }
                                                  placeholder='CPF'
                                                  format={'###.###.###-##'}
                                                  mask="_"
                                    />
                                    <LabelWithLibs label='CPF' labelIcon={<FaIdCard size={16}/>} className={`${errors?.cpf?.message ? 'invalid-feedback' : ''}`}/>
                                    {errors?.cpf?.message &&
                                        <div className={`${errors?.cpf?.message ? 'invalid-feedback' : ''}`}>
                                            {errors?.cpf?.message}
                                        </div>
                                    }
                                </div>
                            </Col>
                            <div className='d-flex flex-wrap-reverse gap-2 justify-content-between p-2'>
                                <Col xs={12} md={4}>
                                    <Button type='button' variant='outline-dark' className='w-100' onClick={()=>changeStepModal('login')}>
                                        Voltar
                                    </Button>
                                </Col>

                                <Col xs={12} md={5}>
                                    <Button type='submit' className='w-100' disabled={loading}>
                                        <span className='de-flex gap-2'>{loading ? <><Spinner animation='border' size='sm'/>Enviando... </> : <>Recuperar</>}</span>
                                    </Button>
                                </Col>
                            </div>
                        </form>
                    </Col>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default ForgetPasswordModal
