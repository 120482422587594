import React, {useEffect, useRef} from 'react'

const InfiniteScroll = ({fetchMore}) => {

  const containerRef = useRef()

  const observer = () => {
    const options = {
      root:null,
      rootMargin: '0px',
      threshold: 1.0
    }

    const scrollObserver = new IntersectionObserver(([entry]) =>{
      if (entry.isIntersecting) {
        scrollObserver.disconnect()
        fetchMore()
      }
    }, options);

     scrollObserver.observe(containerRef.current)
  }

  useEffect(()=>{
    observer()
  },[])

  return <div ref={containerRef}/>
}

export default InfiniteScroll