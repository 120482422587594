import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import {Toaster} from "react-hot-toast";
import {Images} from "../helpers/Images";

const DefaultLayout = props => {
    const background = {
        backgroundImage: `url(${Images.background_site})`,
        backgroundPosition:"center center",
        backgroundSize:"cover"
    }

    return (
        <div className='d-flex flex-column h-100 '>
            <Header/>
            <main style={background} className='flex-1'>
                <div className='h-100'>
                    {props.children}
                </div>
            </main>
            <Footer/>
            <Toaster/>
        </div>


    );
};

DefaultLayout.propTypes = {};

export default DefaultLayout;
