import React, {useEffect, useState} from 'react';
import useApi from "../../hooks/useApi";
import Loading from "../../components/Generic/Loading";
import moment from "moment";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Button, Col, Container} from "react-bootstrap";
import {FaArrowLeft, FaCalendarDay, FaCheck, FaIdCard, FaLock, FaPhoneAlt, FaUserTimes} from "react-icons/fa";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {BsAt, BsPeopleFill, BsPersonFill, BsPersonBoundingBox} from "react-icons/bs";
import {AiFillPhone, AiOutlineReload} from "react-icons/ai";
import { BiNetworkChart} from "react-icons/bi";
import NumberFormat from "react-number-format";
import Select from "react-select";

import LabelWithLibs from "../../components/Generic/LabelWithLibs";
import InputHF from "../../components/Generic/InputHF";
import {customStyles} from "../../components/Generic/ReactSelect/CustomStyles";
import {OptionsSelect} from "../../helpers/OptionsSelect";
import ChangePasswordModal from "./Modal/ChangePasswordModal";
import {toast, Toaster} from "react-hot-toast";
import DisableAccountModal from "./Modal/DisableAccountModal";
import VerificationCodeComponent from "../LogIntoSystem/Component/VerificationCodeComponent";
import useAuth from "../../hooks/Auth";


const ProfilePage = () => {
    const api = useApi();
    const history = useHistory();
    const {store} = useAuth();

    const [loading,setLoading] = useState(true);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showDisableAccountModal, setShowDisableAccountModal] = useState(false);
    const [modalVerificationCode, setModalVerificationCode] = useState(false);
    const [step, setStep] = useState('number');

    const profile = useSelector(state => state).authReducer;

    const schema = yup.object({
        first_name: yup.string().required('O nome é obrigatório'),
        last_name: yup.string().required('O sobrenome é obrigatório'),
        nickname: yup.string().notRequired().nullable(),
        gender: yup.string().required('Selecione seu gênero'),
        birthday: yup.string().required('A data de nascimento é obrigatória'),
        email: yup.string().email('Insira um e-mail válido').required('O email é obrigatório')

    }).required();

    const {register, handleSubmit, setValue, getValues, formState: { errors },} = useForm({
        resolver: yupResolver(schema),
    });

    const getData = () => {
        setLoading(true);
        api.get(`user/${profile.user_id}/full`,(res) => {
            setValue('first_name', res.profile?.first_name)
            setValue('last_name', res.profile?.last_name)
            setValue('nickname', res.profile?.nickname)
            setValue('gender', res.profile?.gender)
            setValue('birthday', res.profile?.birthday)
            setValue('email', res.profile?.email)
            setLoading(false);
        }, (e) => {
            toast.error('Ops, não foi possível recuperar seu perfil, tente novamente');
        });
    }

    const onSubmit = (data) => {
        api.post(`user/${profile.user_id}/profile`, data, (res) => {
            toast.success('Seus dados foram alterados com sucesso!');
        }, (e) => {
            toast.error(e.message || 'Ops!, não foi possível salvar seus dados')
        });
    }

    const onError = () => {return 0}

    const resumeValidationCode = () => {
        setModalVerificationCode(false);
        setStep('number');
        store(profile, true)
    }

    useEffect(() => {
        getData()
    },[]);

    return (
        <Container className='py-5'>
            <Toaster/>

            <div>
               <div className='d-flex p-2 '>
                  <span onClick={() => history.push('/painel')} className='d-flex align-items-center gap-2 back-icon'>
                      <FaArrowLeft size={18}/> Voltar
                  </span>
               </div>
            </div>

            {loading?
                <Loading/>
                :
                <>
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <Col xs={12} className='p-3 shadow bg-white rounded-2'>
                            <div className='d-flex align-items-center gap-2 mb-3'>
                                <BsPersonFill size={20}/>
                                <span className='fs-6 '> Dados Pessoais </span>
                            </div>

                            <div className='d-flex flex-wrap'>
                                <Col xs={12} md={6} className='p-2'>
                                    <InputHF label='Nome'
                                             iconLabel={<BsPersonFill size={20}/>}
                                             key='first_name'
                                             register={register('first_name')}
                                             error={errors.first_name?.message}
                                    />
                                </Col>

                                <Col xs={12} md={6} className='p-2'>
                                    <InputHF label='Sobrenome'
                                             iconLabel={<BsPeopleFill size={20}/>}
                                             key='last_name'
                                             register={register('last_name')}
                                             error={errors.last_name?.message}
                                    />
                                </Col>

                                <Col xs={12} md={6} className='p-2'>
                                    <InputHF label='Apelido'
                                             iconLabel={<BiNetworkChart size={20}/>}
                                             key='nickname'
                                             register={register('nickname')}
                                             error={errors.nickname?.message}
                                    />
                                </Col>

                                <Col xs={12} md={6} className='p-2'>
                                    <div className='form-floating'>
                                        <NumberFormat value={profile.cpf}
                                                      className='form-control'
                                                      placeholder='CPF'
                                                      style={{color:'#b8b8b8'}}
                                                      disabled
                                                      format={'###.###.###-##'}
                                                      mask="_"
                                        />
                                        <LabelWithLibs label='CPF' labelIcon={<FaIdCard size={20}/>}/>
                                    </div>
                                </Col>

                                <Col xs={12} md={6} className='p-2 position-relative'>
                                    <Select placeholder='Gênero'
                                            options={OptionsSelect.genders}
                                            defaultValue={{
                                                value: getValues().gender,
                                                label: OptionsSelect.genders[OptionsSelect.genders.map(e => e.value).indexOf(getValues().gender)].label}}
                                            onChange={(e) => {setValue('gender', e.value)}}
                                            isSearchable={false}
                                            styles={errors.gender?.message ? customStyles.error : customStyles.noSearchable }
                                    />
                                    <LabelWithLibs className={`label-with-select ${errors.gender?.message ? 'error-label-select ' : ''}` }
                                                   label='Gênero'
                                                   labelIcon={<BsPersonBoundingBox size={20}/>}
                                    />
                                    <div className={`${errors.gender?.message ? 'invalid-feedback' : ''}`}>
                                        {errors.gender?.message}
                                    </div>
                                </Col>

                                <Col xs={12} md={6} className='p-2'>
                                    <div className='form-floating'>
                                        <NumberFormat className={`${errors.birthday ? 'form-control is-invalid' : 'form-control'}`}
                                                      value={moment(getValues().birthday, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                                      onValueChange={(e) => {
                                                          if (e.formattedValue === moment(e.formattedValue, 'DD/MM/YYYY').format('DD/MM/YYYY')) {
                                                              setValue('birthday', moment(e.formattedValue, 'DD/MM/YYYY').format('YYYY-MM-DD'))
                                                          }
                                                      }}
                                                      placeholder='Data de nascimento'
                                                      format={'##/##/####'}
                                                      mask="_"
                                        />
                                        <LabelWithLibs className={`${errors.birthday?.message ? 'invalid-feedback' : ''}`} label='Data de nascimento' labelIcon={<FaCalendarDay size={20}/>}/>
                                        <div className={`${errors.birthday?.message ? 'invalid-feedback' : ''}`}>
                                            {errors.birthday?.message}
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </Col>

                        <Col xs={12} className='p-3 shadow bg-white rounded-2 mt-4'>
                            <div className='d-flex align-items-center gap-2 my-3'>
                                <FaPhoneAlt size={20}/>
                                <span className='fs-6 '> Dados de Contato </span>
                            </div>
                            <div className='d-flex flex-wrap'>
                                <Col xs={12} md={6} className='p-2'>
                                    <InputHF label='Email'
                                             iconLabel={<BsAt size={20}/>}
                                             key='email'
                                             register={register('email')}
                                             error={errors.email?.message}
                                    />
                                </Col>

                                <Col xs={12} md={6} className='p-2'>
                                    <div className='form-floating'>
                                        <input value={profile.phone}
                                                      className='form-control'
                                                      placeholder='Telefone'
                                                      style={{color:'#b8b8b8'}}
                                                      disabled
                                        />
                                        <LabelWithLibs label='Telefone' labelIcon={<AiFillPhone size={20}/>}/>
                                    </div>
                                </Col>
                            </div>
                            <div className='d-flex justify-content-end p-2'>
                                <Button variant={'link'} onClick={() => {setModalVerificationCode(true)}} className='d-flex align-items-center gap-2 p-0 text-decoration-none'>
                                    <AiOutlineReload size={18}/> <span>Trocar número de telefone</span>
                                </Button>
                            </div>
                        </Col>

                        <div className='d-flex flex-wrap justify-content-between p-2'>
                            <Col xs={12} md={6} xl={4} className='d-flex'>
                                <Col xs={6} className='p-1'>
                                    <button type='button' className='btn btn-sm btn-outline-danger w-100' onClick={() => setShowDisableAccountModal(true)} >
                                        <span className='d-flex align-items-center justify-content-center gap-2'>
                                          <FaUserTimes size={18}/> Desabilitar Perfil
                                        </span>
                                    </button>
                                </Col>

                                <Col xs={6} className='p-1'>
                                    <button className='btn btn-sm btn-primary text-white w-100' type='button' onClick={() => setShowChangePasswordModal(true)}>
                                        <span className='d-flex align-items-center justify-content-center gap-2'>
                                          <FaLock size={16}/> Trocar senha
                                        </span>
                                    </button>
                                </Col>
                            </Col>

                            <Col xs={12} md={4} lg={3} xl={2} className='p-1'>
                                <button  className='btn btn-sm btn-success text-white w-100' type='submit'>
                                    <span className='d-flex align-items-center justify-content-center gap-2'>
                                       <FaCheck/> Salvar alterações
                                    </span>
                                </button>
                            </Col>
                        </div>
                    </form>
                </>
            }
            {modalVerificationCode &&
                <VerificationCodeComponent   showModal={modalVerificationCode}
                                            closeModal={() => setModalVerificationCode(false)}
                                            resumeValidationCode={resumeValidationCode}
                                            step={step}
                                            setStep={(e) => setStep(e)}
                />
            }

            <ChangePasswordModal openModal={showChangePasswordModal}
                                 closeModal={() => setShowChangePasswordModal(!showChangePasswordModal)}
                                 onError={() => onError()}
            />
            <DisableAccountModal openModal={showDisableAccountModal}
                                 closeModal={() => setShowDisableAccountModal(!showDisableAccountModal)}
                                 onError={() => onError()}
                                 profile={profile}
            />
        </Container>
    );
};

export default ProfilePage
