
import React, {useContext} from "react"
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import {AccordionContext} from "react-bootstrap";
import { FaChevronDown, FaChevronUp} from "react-icons/fa";
import {Env} from "../helpers/Env";

function CustomToggle({ children, eventKey }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, (props) =>
            console.log('totally custom!', activeEventKey),
    );

    const isActive =  activeEventKey === eventKey

    return (
        <button
            type={"button"}
            className={"btn  pe-2 pe-4 py-1 rounded-4 da-flex gap-2"}
            style={{ minHeight: 80, background: "#f0f0f0", textAlign: "start", display: "flex" }}
            onClick={decoratedOnClick}
        >
            {isActive ? <FaChevronUp size={16}/> : <FaChevronDown size={16}/>}
            {children}
        </button>
    );
}

export default function ComponentsFaq(props) {
    const SYSTEM_NAME = Env.application_title;
    const SUPPORT_NUMBER = Env.support_number;

    const faq = [
        {
            title: `O que é o ${SYSTEM_NAME}?`,
            description: `O ${SYSTEM_NAME} é uma plataforma digital desenvolvida pelo Governo de Rondônia para facilitar o acesso a oportunidades de prestação de serviços para trabalhadores autônomos e prestadores de serviços informais.`
        },
        {
            title: `Quem pode se cadastrar no ${SYSTEM_NAME}?`,
            description: `Qualquer pessoa que ofereça serviços autônomos ou informais, como pedreiros, eletricistas, pintores, cabeleireiros, e outros profissionais, pode se cadastrar na plataforma.`
        },
        {
            title: `O cadastro é gratuito?`,
            description: `Sim, o cadastro na plataforma ${SYSTEM_NAME} é totalmente gratuito tanto para prestadores de serviços quanto para contratantes.`
        },
        {
            title: `Como funciona o processo de contratação de um serviço?`,
            description: `Após o cadastro, os contratantes podem pesquisar prestadores de serviços com base na localização e no tipo de serviço, entrando em contato diretamente com o profissional para contratar.`
        },
        {
            title: `Posso me cadastrar como contratante e prestador de serviço ao mesmo tempo?`,
            description: `Sim, você pode se cadastrar tanto como prestador de serviços quanto como contratante, utilizando a mesma conta para ambas as funções.`
        },
        {
            title: `A plataforma oferece algum tipo de garantia sobre os serviços prestados?`,
            description: `Não, o ${SYSTEM_NAME} atua apenas como um intermediário, facilitando o contato entre prestadores e contratantes. A negociação e a execução do serviço são de responsabilidade direta entre as partes.`
        },
        {
            title: `Posso avaliar o prestador de serviços após a contratação?`,
            description: `Sim, a plataforma permite que contratantes avaliem os serviços prestados, ajudando outros usuários a tomarem decisões baseadas em feedbacks.`
        },
        {
            title: `Como posso alterar meus dados cadastrais?`,
            description: `Você pode alterar suas informações de cadastro a qualquer momento acessando o seu perfil na plataforma e atualizando os dados necessários.`
        },
        {
            title: `Porque meu perfil não está liberado?`,
            description: `Possivelmente foram identificadas divergências nos dados fornecidos ou alguma imagem do documento solicitado não esteja legível. Acesse a aba de notificações e verifique se existe alguma mensagem nossa solicitando o envio de documentação pendente ou recusada.`
        },
        {
            title: `Existe suporte técnico caso eu tenha problemas com a plataforma?`,
            description: `Sim, a plataforma oferece suporte técnico. Você pode entrar em contato através dos canais de atendimento disponíveis no site ou pelo whatsapp número ${window.maskViewPhone(SUPPORT_NUMBER)}`
        },
        {
            title: `Posso excluir minha conta do ${SYSTEM_NAME}?`,
            description: `Sim, você pode solicitar a exclusão da sua conta a qualquer momento, diretamente pela plataforma ou entrando em contato com o suporte.`
        },
        {
            title: `Quais tipos de serviços podem ser cadastrados na plataforma?`,
            description: `A plataforma aceita uma ampla gama de serviços, incluindo, mas não se limitando a, serviços de construção, reformas, beleza, eventos, assistência técnica, entre outros.`
        },
        {
            title: `Como os contratantes encontram os prestadores de serviço?`,
            description: `Os contratantes podem buscar prestadores de serviços através de filtros como localização, categoria de serviço e avaliações de outros usuários.`
        },
        {
            title: `A plataforma oferece algum tipo de treinamento ou curso para prestadores de serviços?`,
            description: `Sim, o ${SYSTEM_NAME} pode oferecer cursos de qualificação profissional para ajudar os prestadores a melhorarem suas habilidades e aumentarem suas chances de contratação.`
        },
        {
            title: `A plataforma é segura?`,
            description: `Sim, o ${SYSTEM_NAME} adota medidas de segurança para proteger os dados pessoais dos usuários, mas recomenda-se que as negociações sejam feitas com cautela e boa comunicação entre as partes.`
        }
    ];



    return (
        <div>
            <h2 className={"my-10 text-center mb-4"}>
                Perguntas Frequentes
            </h2>

            <ul className="row g-2  p-0" style={{listStyle: "none"}}>
                {faq.map((item, key) => (
                    <li className={"col-12 col-md-6"} key={key}>
                        <Accordion >
                            <Card className={"border-0 shadow-none "} style={{background:"transparent"}}>
                                <CustomToggle eventKey="0">
                                    <b style={{color: "#333333", flexGrow:1}} className={"text-justify"}>{item.title}</b>
                                </CustomToggle>

                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <div>
                                            {item.description &&
                                                <p className={"text-justify mb-0 fs-6 lh-lg"}>
                                                    {item.description}
                                                </p>
                                            }
                                            {item.element && item.element}
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </li>
                ))}
            </ul>

        </div>
    )
}