import React from 'react'
import {Col} from "react-bootstrap";
import RateStars from "../../../components/Generic/RateStars";
import {Images} from "../../../helpers/Images";
import {FaFacebookF, FaInstagram} from "react-icons/fa";

const ServiceProviderProfile = ({name, avatar, facebookLink, instagramLink, occupation}) => {
    return (
        <div >
            <div className='d-flex gap-3 p-3'>
                <div>
                    <img className='rounded-circle' style={{width: 128, height: 128, objectFit: 'cover'}} alt='profile image' src={avatar}/>
                </div>
                <Col>
                    <div>
                        <span className='text-dark fs-3' > {name} </span>
                    </div>
                    <div>
                        <span className='text-muted fs-5' > {occupation.name}  </span>
                    </div>
                    <div className='da-flex gap-2'>
                        {occupation.price_avg !== 0 &&<span><strong className='text-success'> {occupation.price_avg <= 2? '$' :(occupation.price_avg <= 3)? '$$' :(occupation.price_avg <= 4)? '$$$': (occupation.price_avg <= 5)&& '$$$$'} </strong>  <strong className='mx-2'>/</strong>  </span> }
                        <Col xs={7} md={6} lg={4} xl={3} className='d-flex gap-2'>
                            <RateStars size={16} disabled defaultValue={occupation.ratings_avg}/>
                            <div>
                                ({occupation.ratings_count})
                            </div>
                        </Col>
                    </div>
                    {(instagramLink || facebookLink) &&
                        <div className={'social-media-icon  mt-3 '}>
                            {instagramLink &&
                                <a className={'instagram'}
                                   href={`https://www.instagram.com/${instagramLink}`} target={"_blank"}>
                                    <FaInstagram className={"fs-4"}/>
                                    @{instagramLink.includes("?") ?
                                    instagramLink.split("?").shift()
                                    :
                                    instagramLink
                                }
                                </a>
                            }

                            {facebookLink &&
                                <a className={'facebook'} href={`https://m.facebook.com/${facebookLink}`}
                                   target={"_blank"}>
                                    <FaFacebookF className={ "fs-4"}/>

                                    {facebookLink}
                                </a>
                            }
                        </div>
                    }
                </Col>
            </div>
            <div className='border-bottom border-1 pt-3'/>
        </div>

    )
}

export default ServiceProviderProfile
