
import axios from 'axios';
import {Env} from "../helpers/Env";

export let access = {
    api_token: null
};

const genericCall = async (url, method, data, params = null, config = {}) => {
    const { isClub = false, disableToast = false, isCrafty, isNext, formData = false} = config;
    let headers;
    let baseUrl;

    if (isClub) {
        headers = Env.header_club;
        baseUrl = Env.api_club_url;
    } else {
        headers = {...Env.header};
        baseUrl = Env.api_url;
    }

    let payload = {
        headers: headers,
        baseURL: baseUrl,
        url,
        data,
        method,
        params
    };
    if (formData) {
        payload.headers['Content-Type'] = 'multipart/form-data';
    }


    if (access.api_token && isClub)
        payload.headers.userToken = access.api_token || config.token;

    if (access.api_token && !isClub && !isNext)
        payload.headers['X-Api-Key'] = access.api_token;

    if (config.token) {
        payload.headers.userToken = config.token;
    }

    return fetchData(payload, (!disableToast ? (method === 'GET' ? false : disableToast) : disableToast), config);
};

const get = (url, params = {}, config = {}) => {
    return genericCall(url, 'GET', null, params, config);
};

const post = (url, data, config = {}) => {
    return genericCall(url, 'POST', data, null, config);
};

const del = (url, data, config = {}) => {
    return genericCall(url, 'DELETE', data, null, config);
};

const put = (url, data, config = {}) => {
    return genericCall(url, 'PUT', data, null, config);
};

const handleError = (error, onlyShowToast = false) => {
    if (typeof window !== 'undefined') {
        // toast?.update('1', { render: error || "Ops, ocorreu um erro!", type: "error", autoClose: 3000, pauseOnHover: true, closeOnClick: true, isLoading: false });
    }

    if (!onlyShowToast) {
        throw error;
    }
};

const handleSuccess = (show, message = 'Tudo certo!') => {
    if (typeof window !== 'undefined') {
        // show && toast?.update('1', { render: message, type: "success", closeOnClick: true, autoClose: 3000, isLoading: false });
    }
};

const isToastFetching = (show) => {
    if (typeof window !== 'undefined') {
        // show && toast?.loading("Carregando...", { toastId: '1' });
    }
};

const fetchData = async (params, disableToast, config) => {
    try {
        !disableToast && isToastFetching(params.method !== 'GET');

        let response = await axios.request(params);
        !disableToast && handleSuccess(params.method !== 'GET', (response.data?.message));

        return response.data;
    } catch (error) {
        if (error?.response) {
            if (error?.response?.data?.validator) {
                const validators = Object.keys(error.response.data?.validator);
                if (validators.length > 0) {
                    return handleError(error?.response?.data.validator[validators[0]][0]);
                } else {
                    return handleError(error?.response?.data?.message);
                }
            } else if (error?.response?.data?.message) {
                if (config.isCrafty) {
                    handleError(error?.response?.data?.message, true);
                    throw error.response.data;
                } else {
                    return handleError(error?.response?.data?.message);
                }
            } else {
                return handleError('Ops, ocorreu um erro!');
            }
        } else if (error?.request) {
            return handleError(error?.request);
        } else {
            return handleError(error?.message);
        }
    }
};

export const api = {
    get,
    post,
    del,
    put,
};