export const days = [
    {label: 'Dom', value: 'sunday'},
    {label: 'Seg', value: 'monday'},
    {label: 'Ter', value: 'tuesday'},
    {label: 'Qua', value: 'wednesday'},
    {label: 'Qui', value: 'thursday'},
    {label: 'Sex', value: 'friday'},
    {label: 'Sab', value: 'saturday'},
];

export const years = [
    {label: 'Menos de 1 ano', value: 0},
    {label: '1 ano', value: 1},
    {label: '2 anos', value: 2},
    {label: '3 anos', value: 3},
    {label: '4 anos', value: 4},
    {label: '5 anos', value: 5},
    {label: '6 anos', value: 6},
    {label: '7 anos', value: 7},
    {label: '8 anos', value: 8},
    {label: '9 anos', value: 9},
    {label: '10 anos', value: 10},
    {label: '11 anos', value: 11},
    {label: '12 anos', value: 12},
    {label: '13 anos', value: 13},
    {label: '14 anos', value: 14},
    {label: '15 anos', value: 15},
    {label: '16 anos', value: 16},
    {label: '17 anos', value: 17},
    {label: '18 anos', value: 18},
    {label: '19 anos', value: 19},
    {label: '20 anos', value: 20},
    {label: '21 anos', value: 21},
    {label: '22 anos', value: 22},
    {label: '23 anos', value: 23},
    {label: '24 anos', value: 24},
    {label: '25 anos', value: 25},
    {label: '26 anos', value: 26},
    {label: '27 anos', value: 27},
    {label: '28 anos', value: 28},
    {label: '29 anos', value: 29},
    {label: '30 anos', value: 30},
    {label: '31 anos', value: 31},
    {label: '32 anos', value: 32},
    {label: '33 anos', value: 33},
    {label: '34 anos', value: 34},
    {label: '35 anos', value: 35},
    {label: '36 anos', value: 36},
    {label: '37 anos', value: 37},
    {label: '38 anos', value: 38},
    {label: '39 anos', value: 39},
    {label: '40 anos', value: 40},
    {label: '41 anos', value: 41},
    {label: '42 anos', value: 42},
    {label: '43 anos', value: 43},
    {label: '44 anos', value: 44},
    {label: '45 anos', value: 45},
    {label: '46 anos', value: 46},
    {label: '47 anos', value: 47},
    {label: '48 anos', value: 48},
    {label: '49 anos', value: 49},
    {label: 'Mais de 50 anos', value: 50},
];

export const optType = [
    {label: 'Casa', value: 'house'},
    {label: 'Apartamento', value: 'apartment'},
    {label: 'Condominio', value: 'condominium'},
    {label: 'Comercial', value: 'commercial'},
    {label: 'Fazenda', value: 'farmhouse'},
];

export const daysPT = {
    monday: 'Segunda',
    tuesday: 'Terça',
    wednesday: 'Quarta',
    thursday: 'Quinta',
    friday: 'Sexta',
    saturday: 'Sábado',
    sunday: 'Domingo'
};

export const countries = [
    {label: "Brasil", value: "brasil"},
    {label: "Estados Unidos", value: "estados_unidos"},
    {label: "Canadá", value: "canada"},
    {label: "México", value: "mexico"},
    {label: "Reino Unido", value: "reino_unido"},
    {label: "França", value: "franca"},
    {label: "Alemanha", value: "alemanha"},
    {label: "Itália", value: "italia"},
    {label: "Espanha", value: "espanha"},
    {label: "Portugal", value: "portugal"},
    {label: "Argentina", value: "argentina"},
    {label: "Austrália", value: "australia"},
    {label: "Japão", value: "japao"},
    {label: "Índia", value: "india"},
    {label: "China", value: "china"},
    {label: "Rússia", value: "russia"},
    {label: "África do Sul", value: "africa_do_sul"},
    {label: "Chile", value: "chile"},
    {label: "Colômbia", value: "colombia"},
    {label: "Peru", value: "peru"}
]
