import React from "react";
import {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import ShareTo from "../../components/Generic/ShareTo";
import {useMutation} from "@tanstack/react-query";
import {BudgetTableComponent} from "./Components/BudgetTableComponent";
import {CardHeaderTable} from "./Components/CardHeaderTable";
import { api as axios } from "../../services/api"

const TableBudget = () => {
    const [total, setTotal] = useState('');
    const getDataBudget = async () => {
        let qs = window.queryStringToObject();
        try {
            let res = await axios.get(`customer-demand/budget/${qs?.slug}`);
            console.log(res)
            return res
        } catch (e) {
            throw new Error(e)
        }
    }

    const {mutate, isLoading, data} = useMutation({
        mutationFn: getDataBudget,
        onSuccess:(data) => {
            let aux = 0
            data.items.forEach((item) => {
                aux = aux + ((item.price * item.amount) - item.discount)
            })
            setTotal(aux)
        },
        // onError: (e) => console.log(e),
    })

    useEffect(() => {
        mutate()
        if (window.location.pathname.indexOf("orcamento") !== -1) {
            document.querySelector("header").style.display = 'none'
            document.querySelector("footer").style.display = 'none'
        }
    }, [])


    return (
        <div className={'pt-5'}>
            <div className={'pt-5'}>
                <div className="container">
                    <h3 className={'text-center mb-0'}>Orçamento</h3>
                </div>
                <div className={'content-spacing'}>

                    <div className={'container d-print-none'}>
                        <div className="row justify-content-center mb-4">
                            <div className="col-12 col-md-11 col-xl-6">
                                <CardHeaderTable data={data}/>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className={'col-12 col-md-11'}>
                                <BudgetTableComponent data={data} total={total}/>
                            </div>

                            <div className="col-12 col-md-11">
                                <div className={'d-flex gap-2 justify-content-end'}>

                                    <ShareTo title={"Compartilhar orçamento"} shareSearch={true}/>

                                    <Button type={"button"} onClick={() => window.print()}>
                                        Imprimir orçamento
                                    </Button>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'d-none d-print-block'}>
                        <div className={'mb-3'}>
                            <CardHeaderTable data={data} print={true}/>
                        </div>

                        <BudgetTableComponent data={data} total={total}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TableBudget;