import {maskPhone} from "../../../helpers/Functions";
import moment from "moment/moment";
import React from "react";

export const CardHeaderTable = ({data, print}) => {

    return (
        <div className="bg-light bg-light border border-gray-400 rounded p-3">
            <div className={`${print ? 'd-flex flex-wrap justify-content-between' : 'row gy-3 gy-md-0'}`}>
                <div className="col-12 text-center">
                    <h4>
                        {data?.title}
                    </h4>
                    <h6 className={'fw-normal'}>
                        {data?.description}
                    </h6>
                </div>
                <div className={`${print ? '' : 'col-12 col-md-6'}`}>
                    <div>
                        <div className=" mb-2">
                            <span className={'fw-bold fs-6'}>
                                Profissional:
                            </span>
                            <span className="d-block fs-6">
                                {data?.author}
                            </span>
                        </div>
                    </div>
                    {data?.contact_phone ?
                        <div className={'da-flex gap-1 mb-2'}>
                            <i className="ki-duotone ki-phone fs-5 text-primary">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                            <span className="fs-7">
                                {maskPhone(window.getPhoneNumbersWithoutCodeCountry(data?.contact_phone))}
                            </span>
                        </div>
                        : <></>
                    }
                    <div className={'da-flex gap-1'}>
                        <i className="ki-duotone ki-sms fs-5 text-primary">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </i>
                        <span className="fs-7">
                            {data?.contact_mail}
                        </span>
                    </div>
                </div>
                <div className={`${print ? '' : 'col-12 col-md-6'}`}>
                    <div
                        className={`d-flex ${print ? 'flex-column' : 'justify-content-md-end flex-column gap-2 flex-md-row flex-wrap text-start text-md-end'}`}>
                        <div className={''}>
                            <p className={'fw-bold mb-0 da-flex gap-1'}>
                                <i className="ki-duotone ki-calendar-tick fs-5 text-primary">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                    <span className="path4"></span>
                                    <span className="path5"></span>
                                    <span className="path6"></span>
                                </i>
                                Data do orçamento:
                            </p>

                            <div className={`${print ? 'text-center' : ''}`}>
                                <span className="font-size-15">
                                    {moment(data?.created_at).format('L')}
                                </span>
                            </div>
                        </div>
                        <div>
                            <p className={'fw-bold mb-0 da-flex gap-1'}>
                                <i className="ki-duotone ki-timer fs-5 text-primary">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                </i>
                                Data de validade:
                            </p>
                            <div className={`${print ? 'text-center' : ''}`}>
                                <span className="font-size-15">
                                    {moment(data?.expire_at).format('L')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}