import React, {useState} from 'react'
import {Button, Col, Container} from "react-bootstrap";
import {Env} from "../../../helpers/Env";
import DividerCustom from "../../../components/Generic/DividerCustom";
import {Images} from "../../../helpers/Images";
import ModalController from "../../LogIntoSystem/ModalController";

const AboutUs = () => {

    return (
        <>
            <Container>
                <div className='d-flex flex-wrap-reverse align-items-center'>
                    <Col xs={12} md={6} className='p-4'>
                        <img className='w-100' src={Images.home_page_svg.about_home} alt={'about'}/>
                    </Col>

                    <Col xs={12} md={6}>
                        <h2 className='text-primary '>
                        <span>
                            Sobre Nós
                        </span>
                        </h2>
                        <h5 style={{textAlign: "justify", lineHeight: 1.8}} className='text-muted fw-light'>
                            O {Env.application_title} é uma plataforma gratuita do estado
                            de {Env.state_restriction_name} que facilita a vida de quem precisa de serviços
                            profissionais com rapidez, agilidade e quem precisa divulgar serviços autônomos/freelance.
                            Com poucos cliques você encontra profissionais de qualidade por geolocalização mais próximos
                            de você e também pode divulgar o seu trabalho gratuitamente, otimizando a exposição dos seus
                            serviços para ser encontrado por mais clientes que precisam de você

                        </h5>
                    </Col>
                </div>
            </Container>
        </>
    )
}

export default AboutUs