import React, {useEffect, useState} from "react";
import {useInfiniteQuery} from "@tanstack/react-query";
import {useInView} from "react-intersection-observer";
import {Images} from "../../helpers/Images";
import RateStars from "../../components/Generic/RateStars";
import moment from "moment/moment";
import {Button} from "react-bootstrap";
import {api} from "../../services/api";
import empty from "../../img/svg/feedback-service.svg"


export const ReviewsComponent = ({eventKey, activeKey, dataWorker, maxHeigth}) => {
    const [filters, setFilters] = useState({
        page:1,
        limit: 20,
        profile: 'worker',
        type: 'received',
        occupation_id: dataWorker?.occupation_id
    });
    const [doReply, setDoReply] = useState(false);
    const [reply, setReply] = useState('');
    const getReviews = async (params) => {
        let res = await api.get(`user/${dataWorker.user_id}/contact-reviews`, params)
        console.log(res)
        return res
    }

    const nextPage = async () => {
        let aux = filters
        if (hasNextPage) {
            aux.page += 1
            await fetchNextPage({pageParam:aux})
            setFilters(aux)
        }
    }



    const {data, isFetching,isLoading, error,refetch, hasNextPage, isFetched, fetchNextPage,} = useInfiniteQuery({
        queryKey: ['reviews'],
        queryFn: ({pageParam = filters}) => getReviews(pageParam),
        getNextPageParam: (lastPage) => {
            return lastPage?.next_page
        },
        enabled: activeKey === eventKey
    })

    const { ref, inView } = useInView({
        threshold: 0,
        delay:1000
    });

    const doReplyMessage = async () => {
        await api.post(`/worker-contact/${doReply.worker_contact_id}/review/${doReply.contact_rating_id}/reply`, {reply: reply})
        setReply('');
        setDoReply(null);

        refetch()
    }

    useEffect(() => {
        if (inView && hasNextPage) {
            nextPage()
        }
    }, [inView]);

    return (
        <div>
            {isLoading ? <>Carregando...</> :
                data?.pages[0]?.reviews?.length === 0 ?
                    <div>
                    <h3 className={'text-center'}>Nenhuma avaliação foi recebida </h3>
                    <div className={'d-flex justify-content-center'}>
                        <img className={'m-auto'} src={empty} alt="nenhuma avaliação" style={{maxHeight:250}}/>
                    </div>
                </div>
                :
                    <div className=''>
                    <h4 className='text-dark'>
                        Avaliações
                    </h4>
                    <div style={{maxHeight:(maxHeigth || 800), overflowY:'auto'}}>
                        {data?.pages?.map((page, indexPage) =>
                            <div key={indexPage}>
                                {page?.reviews?.map((item, index) =>
                                    <div key={index} className='alternate-colors '>
                                        <div className={'py-3'}>
                                            <div className='d-flex gap-3 pt-3 w-100 pb-2 px-2 position-relative'>
                                                <div>
                                                    <img width={64}
                                                         height={64}
                                                         style={{objectFit: 'cover',}}
                                                         className='rounded-circle shadow'
                                                         src={item.user.profile_picture.full || Images.user_place_holder}
                                                         alt="profile-picture"
                                                    />
                                                </div>
                                                <div className={'w-100'}>
                                                    <h5 className='mb-1 text-dark'>
                                                        {item.user.name}
                                                    </h5>

                                                    <p className='mb-1 fs-7'>
                                                        Avaliação do serviço/produto de - <span className='fw-bolder text-secondary'>{item.occupation.name}</span>
                                                    </p>

                                                    <div className={"d-flex"}>
                                                        <RateStars size={14} disabled defaultValue={item.rating}/>
                                                    </div>
                                                    {item.review &&
                                                        <p style={{textAlign:"justify"}} className='fs-6 mt-2 mb-0'>
                                                            {item.review}
                                                        </p>
                                                    }

                                                    {item.reply && <div style={{borderTop: '1px solid gainsboro', paddingTop: 20, marginTop: 20}}>
                                                        Resposta -
                                                        <span style={{color: "#00a5d3", marginLeft: 5}}>{item.reply.content}</span>
                                                    </div>}

                                                    {/*{(!item.reply && item.review && (window.location.pathname.search('prestador-de-servico') === -1))&&*/}
                                                    {/*    <div style={{width: '100%', transition: '300ms', marginTop: 20 , display: 'flex', justifyContent: 'space-between'}}>*/}
                                                    {/*        {doReply?.contact_rating_id === item.contact_rating_id &&<div style={{width: '100%', marginRight: 20, position: 'relative', alignItems: 'center'}}>*/}

                                                    {/*            <input type="text"*/}
                                                    {/*                   className="form-control "*/}
                                                    {/*                   id="search_company"*/}
                                                    {/*                   value={reply}*/}
                                                    {/*                   style={{border: 'unset', boxShadow: 'unset', paddingRight: 75, borderRadius: "0", borderBottomStyle: 'solid', borderBottomWidth: 2, borderColor: '#00a5d3'}}*/}
                                                    {/*                   placeholder="Digite aqui sua resposta"*/}
                                                    {/*                   onChange={(e) => setReply(e.target.value)}*/}
                                                    {/*            />*/}
                                                    {/*            <span style={{position: 'absolute', right: 0, bottom: 10, color: 'gainsboro'}}> {reply?.length} (200) </span>*/}
                                                    {/*        </div>}*/}
                                                    {/*        {doReply?.contact_rating_id === item.contact_rating_id ?*/}
                                                    {/*            <Button onClick={() => doReplyMessage(item)}  className={'btn btn-secondary'} type={'primary'}> Enviar </Button>*/}
                                                    {/*            :*/}
                                                    {/*            <Button onClick={() => setDoReply(item)}  className={'btn btn-primary'} type={'primary'}> Responder</Button>*/}
                                                    {/*        }*/}
                                                    {/*    </div> */}
                                                    {/*}*/}
                                                </div>

                                                <div className='da-flex text-gray-700 position-absolute fs-8 gap-1' style={{top:6, right:6}}>
                                                    <div>{moment(item.created_at).format('DD-MM-YYYY')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        )}
                        {isFetching &&
                            <div className="da-flex gap-2 my-3 ms-2">
                                <div className="spinner-border spinner-border-sm" aria-hidden="true"></div>
                                <p className={'mb-0'} role="status">Carregando...</p>
                            </div>
                        }
                        {(isFetched && hasNextPage) && <div ref={ref}/>}
                    </div>
                </div>

            }

        </div>
    )
}
