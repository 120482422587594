import React, {useEffect, useState} from 'react';
import {Images} from "../../helpers/Images";
import {useHistory} from "react-router-dom";
import {Button, Col, Container} from "react-bootstrap";
import {useQueryClient} from "@tanstack/react-query";
import {FaApple, FaTools} from "react-icons/fa";
import {checkDocsPending} from "../../hooks/Helpers";
import {Env} from "../../helpers/Env";
import {BsCheck2Circle} from "react-icons/bs";

const ClientPanelComponent = () => {
    const history = useHistory();
    const queryClient = useQueryClient()
    const data = queryClient.getQueryData(["profile"]);
    const hasOccupation = data?.occupations?.length > 0;
    const [statusDoc, setStatusDoc] = useState({})


    function getStatusColor(status) {
        switch (status) {
            case 'approval_queue':
                return '#005c9f'; // Em análise
            case 'approved':
                return '#44a71d'; // Aprovado
            case 'pending':
                return '#f3b749'; // Pendente
            case 'rejected':
                return '#c51e13'; // Rejeitado
            default:
                return ''; // Default para Pendente
        }
    }

    function findStatus() {
        const keysToCheck = ["selfie_picture", "photo_id_back", "photo_id_front", "proof_of_residence"]


        for (let i = 0; i < keysToCheck.length; i++) {
            const key = keysToCheck[i];

            if (data.docs[key] === 'rejected') {
                return { key, status: 'rejected', message: "Realize o Reenvio de seu Documento", subMessage: "Parece que um ou mais documentos foram rejeitados, toque aqui para enviar novamente", image: Images.rejected };
            } else  if (data.docs[key] === 'pending') {
                return { key, status: 'pending', message: "Envio de Documentos Pendentes", subMessage: "Para seu perfil ficar visível em todo estado, só falta comprovar sua identidade", image: Images.pending_doc };
            }
        }
        if (keysToCheck.find(key => data.docs[key] === 'approval_queue')) {
            return { status: 'approval_queue', message: "Seu cadastro já está em análise", subMessage: "Se tudo certo, em poucos minutos seu perfil estará disponível para todo Estado", image: Images.finger_print };
        }

        return {};
    }

    useEffect(() => {
       if (data) {
           const res = findStatus()
           setStatusDoc(res)
       }
    }, [data]);
    return (
        <Container className=' py-5'>
            {checkDocsPending(data) &&
                <div className={"d-flex justify-content-end mb-4"}>
                    <button onClick={() => history.push("/cadastro-prestador")} className={"btn-light-gray rounded-pill"}>
                        <FaTools style={{marginRight:10}}/>

                        Me cadastrar como prestador
                    </button>
                </div>
            }

            <div className={"row g-2"}>
                <Col xs={12} md={hasOccupation ? 6 : 12} onClick={() => history.push('/painel/cliente/contatos')}>
                   <div className='card-panel-client d-flex border-bottom-primary-3 p-4 bg-white rounded-4'>
                       <Col xs={8}>
                           <h4 className='text-primary'>Ver Lista de Contatos</h4>
                           <span className='text-dark'>Veja aqui a lista de contatos dos prestadores de serviço que solicitou, e não se esqueça de avaliar! </span>
                       </Col>
                       <Col md={4}>
                           <img className='w-100' src={Images.panel.agenda_card} alt='view list'/>
                       </Col>
                   </div>
               </Col>

               {hasOccupation &&
                   <Col xs={12} md={hasOccupation ? 6 : 12} >

                       {!checkDocsPending(data) ?
                           <div className={"card-panel-client h-100 p-3 bg-white rounded-4"} style={{cursor: 'default'}}>
                               <div className={"h-100 d-flex flex-column justify-content-between"}>
                                   <div>
                                       <div className={"d-flex flex-center gap-2 text-success rounded-2 border border-success text-bg-success bg-opacity-10 p-2"}>
                                           <BsCheck2Circle className={"fs-3"}/>
                                           <h4 className={"text-success text-center mb-0"}>
                                               Documentos Aprovados
                                           </h4>
                                       </div>
                                       <div className={"my-3 text-center"}>
                                           <span className={"text-dark"}>
                                               Baixe o aplicativo agora e explore todas as funcionalidades disponíveis para melhorar sua experiência!
                                           </span>
                                       </div>

                                   </div>

                                   <div className='row g-2'>
                                       <Col xs={12} md={6}>
                                           <Button variant='dark' className='w-100'
                                                   onClick={() => window.redirectWorker(Env.worker_play)}>
                                               <div className='de-flex gap-2'>
                                                   <img height={20} src={Images.gplay_icon} alt='icon play store'/>
                                                   <span className='text-start'>
                                            <span className='fw-lighter fs-8'>Disponível no</span> <strong
                                                       className='ms-1 d-inline d-md-block'> Google Play </strong>
                                            </span>
                                               </div>
                                           </Button>
                                       </Col>

                                       <Col xs={12} md={6} >
                                           <Button variant={"dark"} className='w-100'
                                                   onClick={() => window.redirectWorker(Env.worker_apple)}>
                                               <div className='de-flex gap-2'>
                                                   <FaApple size={24}/>
                                                   <span className='text-start'>
                                                       <span className='fw-lighter fs-8'>Disponível na</span> <strong
                                                       className='ms-1 d-inline d-md-block'>App Store </strong>
                                                   </span>
                                               </div>
                                           </Button>
                                       </Col>
                                   </div>
                               </div>
                           </div> :
                           <div className={"card-panel-client p-4 bg-white rounded-4 h-100"} style={{borderBottom: `3px solid ${getStatusColor(statusDoc?.status)}`}} onClick={() => history.push("/cadastro-prestador")}>
                               <div className={"row"}>
                                   <Col xs={8}>
                                       <h4 style={{color: getStatusColor(statusDoc?.status), fontWeight:600}}>{statusDoc.message}</h4>
                                       <span>{statusDoc.subMessage}</span>
                                   </Col>

                                   <Col md={4}>
                                       <img className='w-100' src={statusDoc.image} alt=''/>
                                   </Col>

                               </div>
                           </div>
                       }
                   </Col>

               }
               <Col xs={12} md={6} onClick={() => {
                   history.push('/painel/cliente/perfil')
               }}>
                   <div className='card-panel-client d-flex border-bottom-secondary-3 p-4 bg-white rounded-4'>
                       <Col xs={8}>
                           <h4 className='text-secondary'>Visualize seu perfil</h4>

                           <span className='text-dark'>
                        Edite as informações do seu perfil. Nos conte um pouco mais sobre você.
                     </span>
                       </Col>
                       <Col md={4}>
                           <img className='w-100' src={Images.panel.profile_card} alt='view list'/>
                       </Col>
                   </div>
               </Col>

               <Col xs={12} md={6}  onClick={() => {
                   history.push('/painel/cliente/notificacoes')
               }}>
                   <div className='card-panel-client d-flex border-bottom-sucess-3 p-4 bg-white rounded-4'>
                       <Col xs={8}>
                           <h4 className='text-success'>Notificações</h4>

                           <span className='text-dark'>
                         Manteremos você informado de tudo que for de seu interesse aqui!
                     </span>
                       </Col>
                       <Col md={4}>
                           <img className='w-100' src={Images.panel.notification_card} alt='view list'/>
                       </Col>
                   </div>
               </Col>
           </div>
        </Container>
    )
};
export default ClientPanelComponent;
