import React from 'react'
import {Col, Row} from "react-bootstrap";
import {Images} from "../../../helpers/Images";
import {BsSearch, BsPerson, BsPhone} from "react-icons/bs";
import {Env} from "../../../helpers/Env";
import DividerCustom from "../../../components/Generic/DividerCustom";

const PlatformAdvantages = () => {

    return (
        <>
            <div>
                <h2 className='text-primary text-center'>
                    Como entrar em contato com prestadores de serviços?
                </h2>

                <DividerCustom/>
            </div>



            <Row className='align-items-center gx-0 gx-md-5'>
                <Col xs={12} md={7} lg={6}>
                    <img src={Images.scenes.scene_desktop} className='w-100' alt=""/>
                </Col>
                <Col xs={12} md={5} lg={6} className='d-flex flex-column gap-3'>
                    <div>
                        <h4 className='da-flex gap-2 text-secondary'>
                            <div className='rounded-circle border p-2 d-flex align-items-center bg-primary'>
                                <BsSearch  size={28} className='text-white'/>
                            </div>
                            Busque por profissionais próximos de você!
                        </h4>
                        <h6 className='fw-light'>
                            No {Env.application_title} você encontra diversas ocupações de profissionais autônomos!
                        </h6>
                    </div>

                    <div>
                        <h4 className='da-flex gap-2 text-secondary'>
                            <div className='rounded-circle border p-2 d-flex align-items-center bg-primary'>
                                <BsPerson  size={30} className='text-white'/>
                            </div>
                            Detalhes do profissional
                        </h4>
                        <h6 className='fw-light'>
                            Analise as informações e defina se o prestador se encaixa no que você procura!
                        </h6>
                    </div>
                    <div>
                        <h4 className='da-flex gap-2 text-secondary'>
                            <div className='rounded-circle border p-2 d-flex align-items-center bg-primary'>
                                <BsPhone  size={30} className='text-white'/>
                            </div>
                            Entre em contato
                        </h4>
                        <h6 className='fw-light'>
                            Combine diretamente com o profissional os detalhes do serviço que você precisa, por Whatsapp ou ligação!
                        </h6>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default PlatformAdvantages