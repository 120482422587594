import React, {useState} from 'react'
import PropTypes from "prop-types";
import {BsEye, BsEyeSlash} from "react-icons/bs";

const InputHF = ({key, label, error, register, disabled, type, iconLabel, isPassword}) => {
    const [show, setShow] = useState(false)

    return (
        <div className='form-floating w-100'>
            <input name={key}
                   disabled={disabled}
                   style={{backgroundImage: (isPassword && error) && 'none'}}
                   type={isPassword ? (show ? 'text' : 'password') : type ? type : 'text' }
                   className={`${error ? 'form-control is-invalid' : 'form-control'}`}
                   placeholder={label}
                   {...register}
            />
            <label htmlFor={key} className={`${error ? 'invalid-feedback' : ''} d-flex align-items-center gap-1`}>
                {iconLabel && iconLabel} {label}
            </label>

            <div className={`${error ? 'invalid-feedback' : ''}`}>
                {error}
            </div>
            {isPassword &&
                <div className='eyes-password' style={{color: error && '#DC3545'}}
                      onClick={() => setShow(!show)}>
                    {show ? <BsEyeSlash size={18}/> : <BsEye size={18}/>}
                </div>
            }
        </div>
    )
}

InputHF.propTypes = {
    key: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    register: PropTypes.any,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    iconLabel: PropTypes.element,
    isPassword: PropTypes.bool
}

export default InputHF