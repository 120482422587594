import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import {maskRealNu} from "../../../helpers/Functions";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {globalMaskReal} from "../../../hooks/Helpers";
import selectServicesImage from "../../../img/svg/LUPA.svg"
import {api} from "../../../services/api";
import {BsArrowRightShort, BsPlus, BsPlusSquareFill, BsTrashFill} from "react-icons/bs";

const ServicesOfferedComponent = ({occupation, user, activeStep, onSaveData, isEdit},ref) => {
    const [showModal, setShowModal] = useState(false);
    const query = useQueryClient()

    const schema = yup.object().shape({
        title: yup.string().notRequired(),
        description: yup.string().notRequired(),
        price: yup.string().nullable(),
        checked: yup.boolean().notRequired(),
    });

    const getData = async () => {
        try {
            let res = await api.get(`user/${user.user_id}/activities`);
            return res.find((item) => item.user_occupation_id === occupation.user_occupation_id)
        } catch (e) {
            console.log(e)
        }
    }

    const onSubmit = async (e) => {
        let objToSend = {
            user_occupation_id:occupation.user_occupation_id,
            title: e.title,
            description: e.description || "",
            min_price: e.price ? Number(e.price.replace(/\./g, "").replace(",", ".").replace('R$', "").replace(' ', '')) : 0,
            max_price: e.price ? Number(e.price.replace(/\./g, "").replace(",", ".").replace('R$', "").replace(' ', '')) : 0,
        }

        let res = await api.post(`user/${user.user_id}/activity`, objToSend);
        reset();

        return res
    }

    const removeActivity = async (variables) => {
        await api.del(`user/${user.user_id}/activity/${variables.id}`, null)

    }

    const { register, getValues, handleSubmit, watch, setValue, reset, formState: {errors} } = useForm({resolver:yupResolver(schema), });

    const {mutate} = useMutation({
        mutationKey: 'services-offered',
        mutationFn: (variables) => onSubmit(variables),
        onSuccess: async (e, variables, context) => {


            setShowModal(false);
            await getDataMutate();
        }
    })

    const {mutate: remove} = useMutation({
        mutationFn: removeActivity,
        onSuccess: async (e, variables) => {
            let aux = user

            if (isEdit) {
                aux.occupations.forEach((item, index) => item.activities?.findIndex((find, findIndex) => {
                    if (find.user_activity_id === variables.id) {
                        aux.occupations[index].activities?.splice(findIndex, 1)
                    }
                }))
                await query.setQueryData(['profile'], () => {
                    return {...aux}
                })

                data.activities?.findIndex((find, findIndex) => {
                    if (find.user_activity_id === variables.id) {
                        data.activities?.splice(findIndex, 1)
                    }
                })
            } else {
                data.activities?.findIndex((find, findIndex) => {
                    if (find.user_activity_id === variables.id) {
                        data.activities?.splice(findIndex, 1)
                    }
                })
            }

        }
    })

    const {data, mutate: getDataMutate, isLoading} = useMutation({
        mutationKey:['activities'],
        mutationFn: getData,
        onSuccess: async (activities, variables) => {
            if (isEdit) {
                await query.setQueryData(['profile'], (oldData) => {
                    let aux = oldData

                    aux.occupations.forEach((item) => {
                        if (item.occupation_id === activities.occupation_id) {
                            item.activities = activities.activities
                        }
                    })
                    return aux
                })
            }

        }
    })


    useEffect(() => {
        if (activeStep === 'EDIT_OCCUPATION') getDataMutate()
    }, [activeStep]);


    useImperativeHandle(ref, () => {
        return {mutate}
    })

    return (
       <div>
           <div>
               {!isEdit &&
                   <div>
                       <div className={'col-12 col-md-6 col-lg-5 m-auto'}>
                           <img src={selectServicesImage} className={'w-100'} alt="Serviços/produtos oferecidos"/>
                       </div>
                       <h4>
                           Serviços/produtos que oferece de <strong className={'text-primary'}>{occupation?.name}</strong>
                       </h4>
                   </div>
               }
               <div className={'mb-3'}>
                   <div className={'da-flex gap-2 mb-2'}>

                       <BsArrowRightShort  className={"text-success fw-bolder fs-4 d-flex"}/>
                       <span> Adicione atividades específicas que você presta como profissional.</span>
                   </div>
                   <div className={'da-flex gap-2 mb-2'}>
                       <BsArrowRightShort  className={"text-success fw-bolder fs-4 d-flex"}/>
                       <span> <strong>Ex.</strong> Corte de cabelo, Maquiagem para festas, Reparo de ar-condicionado, Troca de óleo etc...</span>
                   </div>
                   <div className={'da-flex gap-2 mb-2'}>
                       <BsArrowRightShort  className={"text-success fw-bolder fs-4 d-flex"}/>
                       <span> Estas informações estarão disponíveis para os contratantes!</span>
                   </div>
               </div>
               <div className={'col-12 col-md-8 col-lg-6 m-auto'}>
                   <button className={'btn btn-outline-secondary w-100 d-flex flex-center'} onClick={() => setShowModal(true)}>
                       <BsPlus className={"fs-3 d-flex"}/>
                       <span>Adicionar serviço/atividade</span>
                   </button>
               </div>

               {isLoading ?
                   <div>Atualizando...</div> :
                   (data) &&
                   <div className={'mt-4'}>
                       <div className={'row row-cols-1 row-cols-lg-2 gy-3'}>
                           {data?.activities?.map((activity, index) => (
                               <div className={'col '} key={index}>
                                   <div className={'d-flex flex-column card-service-delete justify-content-between border rounded-2 p-3 shadow-sm h-100'}>
                                       <div>
                                           <h4 className={'text-primary mb-0'}>
                                               {activity.title}
                                           </h4>
                                           <small className={'text-muted'}>
                                               {activity.description}
                                           </small>
                                       </div>
                                       <div className={'text-end text-success'}>
                                           <span className={'fw-semibold'}>
                                               {activity.max_price === '0.00' ?
                                                   <>Valor a combinar</>
                                                   :
                                                   <> R$ {globalMaskReal(activity.max_price)}</>
                                               }
                                           </span>
                                       </div>
                                       <div className={`icon-delete`} onClick={() => remove({id: activity.user_activity_id})}>
                                           <BsTrashFill />

                                       </div>
                                   </div>
                               </div>
                           ))}
                       </div>
                   </div>
               }

               {!isEdit &&
                   <div className={'d-flex justify-content-end mt-3'}>
                       <div className={'col-12 col-lg-4'}>
                           <Button variant={'secondary'} id={''} className={'w-100'} onClick={onSaveData}>
                               Continuar
                           </Button>
                       </div>
                   </div>
               }
           </div>

           <Modal show={showModal} onHide={() => setShowModal(false)} centered={window.innerWidth < 768}>
               <Modal.Header className={'bg-primary bg-opacity-10'} closeButton>
                   <Modal.Title className={'d-flex align-items-center gap-2 text-primary'}>
                       <BsPlusSquareFill /> Nova atividade
                   </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                   <form onSubmit={handleSubmit(mutate)}>
                       <div className={'mb-3'}>
                           <label htmlFor="name_occupation" className={'form-label'}>Nome</label>
                           <input type="text"
                                  id={'name_occupation'}
                                  placeholder={'Nome da atividade exercida'}
                                  className={'form-control'}
                                  {...register('title')}
                           />
                       </div>

                       <div className={''}>
                           <label htmlFor="description_occupation" className={'form-label'}>Descrição</label>
                           <textarea id={'description_occupation'}
                                     placeholder={'Descrição opcional da atividade'}
                                     style={{resize:"none"}}
                                     rows={4}
                                     maxLength={300}
                                     className='input-text-area'
                                     {...register('description')}
                           />

                           <div className={'mt-2 text-end'}>
                               <span className={'text-muted fs-7'}>
                                   ({watch()?.description?.length || 0 })300
                               </span>
                           </div>
                       </div>

                       <div className={'mb-3'}>
                           <label htmlFor="price_occupation" className={'form-label'}>
                               Valor
                           </label>

                           <div className="input-group ">
                               <span className="input-group-text gap-2" >
                                   <span>R$</span>
                               </span>
                               <input type="text"
                                      id={'price_occupation'}
                                      disabled={watch()?.checked}
                                      value={maskRealNu(watch()?.price || '')}
                                      placeholder={'0,00'}
                                      className={'form-control'}
                                      {...register('price')}
                               />
                           </div>
                       </div>


                       {/*<div className={'mb-3'}>*/}
                       {/*    <label htmlFor="price_occupation" className={'form-label'}>Valor</label>*/}
                       {/*    <input type="text"*/}
                       {/*           id={'price_occupation'}*/}
                       {/*           disabled={watch()?.checked}*/}
                       {/*           value={'R$ ' + (watch()?.price || '0,00')}*/}
                       {/*           placeholder={'R$ 0,00'}*/}
                       {/*           className={'form-control'}*/}
                       {/*           onChange={(e) => setValue('price', maskRealNu(e.target.value))}*/}
                       {/*           {...register('price')}*/}
                       {/*    />*/}
                       {/*</div>*/}

                       <div className={'mb-3'}>
                           <span className="form-check m-0 p-0 d-flex align-items-center gap-2">
                               <input className="form-check-input cursor-pointer m-0 p-0"
                                      style={{width:'1.5rem',height:'1.5rem',}}
                                      type="checkbox"
                                      id="tomatch_occupation"
                                      onClick={() => setValue('price', null)}
                                      {...register('checked')}
                               />
                               <label className="form-check-label cursor-pointer" htmlFor="tomatch_occupation">
                                   Valor a combinar
                               </label>
                           </span>
                       </div>


                       <div className={'col-12 col-md-10 m-auto'}>
                           <Button type={"submit"} variant={"secondary"} className={'w-100'}>
                               Confirmar
                           </Button>
                       </div>

                   </form>
               </Modal.Body>
           </Modal>
       </div>
    )
}


export default forwardRef(ServicesOfferedComponent)