import React, {useState} from 'react'
import {Row, Tab, Col, Nav} from "react-bootstrap";
import {StepperItemComponent} from "./Components/StepperItemComponent";
import {AboutYouComponent} from "./Components/Steps/AboutYouComponent";
import {useSelector} from "react-redux";
import {useQueryClient} from "@tanstack/react-query";
import ChooseOccupationComponent from "./Components/Steps/ChooseOccupationComponent";
import {WorkerRegisterAddressComponent} from "./Components/Steps/WorkerRegisterAddressComponent";
import ServicesOfferedComponent from "./Components/ServicesOfferedComponent";
import {useHistory} from "react-router-dom";
import {WelcomeRegisterComponent} from "./Components/Steps/WelcomeRegisterComponent";
import {SendDocumentsComponent} from "./Components/Steps/SendDocumentsComponent";
import {Env} from "../../helpers/Env";
import {checkDocsPending} from "../../hooks/Helpers";
import {FaBicycle, FaRegGrinBeam, FaRegHandshake} from "react-icons/fa";


export const ProviderRegistrationPage = () => {
    const [activeKey, setActiveKey] = useState(0);
    const disableClickStep = true

    const user =  useSelector(state => state).authReducer;
    const history = useHistory();
    const queryCache = useQueryClient();
    const {data, status} = queryCache.getQueryState(['profile']);
    const handleChangeActiveKey = (key) => {
        const elem = document.querySelector('#tab-main')
        elem.scrollIntoView({behavior:'smooth'})
        setActiveKey(key)
    };


    const checkActiveStep = () => {
        if (data?.profile?.bio === null) {
            handleChangeActiveKey(1)
        } else if (data?.occupations.length === 0) {
            handleChangeActiveKey(2)
        } else if (data?.addresses?.length === 0) {
            handleChangeActiveKey(4)
        } else if (Env.configs?.occupation_approved_required && checkDocsPending(data)) {
            handleChangeActiveKey(5)
        } else {
            history.push('/painel')
        }
    }

    return (
        <div className={'my-5'}>
            <div className="container-fluid container-xl">
                <Tab.Container defaultActiveKey={'1'}>
                    <Row className={'g-4 g-md-3'}>
                        <Col sm={4}>
                            <div className="card border-0 shadow-sm">
                                <div className="card-body">
                                    <Nav className="stepper-nav flex-column gap-3">
                                        <StepperItemComponent onClick={(e) => handleChangeActiveKey(e)}
                                                              title={'Bem-vindo (a)'}
                                                              disableClickStep
                                                              subTitle={'Primeiros passos para aumentar a visibilidade do seu negócio'}
                                                              activeStep={activeKey}
                                                              step={0}
                                                              iconStep={<FaRegGrinBeam className={"fs-4"}/>}
                                        />

                                        <StepperItemComponent onClick={(e) => handleChangeActiveKey(e)}
                                                              title={'Sobre você'}
                                                              disabled={disableClickStep}
                                                              subTitle={'Conte quais suas habilidades e informações importante sobre você'}
                                                              activeStep={activeKey}
                                                              step={1}
                                        />

                                        <StepperItemComponent onClick={(e) => handleChangeActiveKey(e)}
                                                              title={'Escolha suas ocupações'}
                                                              disabled={disableClickStep}
                                                              subTitle={'Escolha sua ocupação principal e os horários em que estará disponível para prestar serviço'}
                                                              activeStep={activeKey}
                                                              step={2}
                                        />

                                        <StepperItemComponent onClick={(e) => handleChangeActiveKey(e)}
                                                              title={'Serviços/produtos oferecidos'}
                                                              disabled={disableClickStep}
                                                              subTitle={'Descreva seus serviços/produtos em específico'}
                                                              activeStep={activeKey}
                                                              step={3}
                                        />

                                        <StepperItemComponent onClick={(e) => handleChangeActiveKey(e)}
                                                              title={'Cadastro de endereço'}
                                                              disabled={disableClickStep}
                                                              subTitle={'Local em que presta os serviços'}
                                                              activeStep={activeKey}
                                                              step={4}
                                        />
                                        {Env.configs?.occupation_approved_required &&
                                            <StepperItemComponent onClick={(e) => handleChangeActiveKey(e)}
                                                                  title={'Validação de Documentos'}
                                                                  disabled={disableClickStep}
                                                                  subTitle={'Envie seus documentos para confirmação de dados '}
                                                                  activeStep={activeKey}
                                                                  step={5}
                                            />
                                        }
                                    </Nav>
                                </div>
                            </div>
                        </Col>
                        <Col sm={8} id={'tab-main'}>
                           <div className="card border-0 shadow">
                               <div className="card-body">
                                   {status === 'loading' ?
                                       <div>Carregando...</div> :
                                       <Tab.Content>
                                           <Tab.Pane eventKey={0} active={activeKey === 0}>
                                               <WelcomeRegisterComponent resumeRegistration={data?.profile?.bio !== null}
                                                                         handleStart={() => checkActiveStep()}
                                               />

                                           </Tab.Pane>

                                           <Tab.Pane eventKey={1} active={activeKey === 1}>
                                               <AboutYouComponent bio={data?.profile?.bio}
                                                                  instagram={data?.profile?.instagram_link}
                                                                  facebook={data?.profile?.facebook_link}
                                                                  avatar={data?.profile?.profile_picture?.full}
                                                                  userId={data?.user_id}
                                                                  onClick={(e) => handleChangeActiveKey(2)}
                                               />
                                           </Tab.Pane>

                                           <Tab.Pane eventKey={2} active={activeKey === 2}>
                                               <div>
                                                   <ChooseOccupationComponent user={user}
                                                                              activeStep={activeKey}
                                                                              eventKey={2}
                                                                              onSaveData={() => handleChangeActiveKey(3)}
                                                   />
                                               </div>
                                           </Tab.Pane>

                                           <Tab.Pane eventKey={3} active={activeKey === 3}>
                                               <div>
                                                   <ServicesOfferedComponent occupation={data?.occupations[0]}
                                                                             user={user}
                                                                             activeStep={(activeKey === 3 && !!data?.occupations[0]?.user_occupation_id)}
                                                                             onSaveData={() => handleChangeActiveKey(4)}
                                                   />
                                               </div>
                                           </Tab.Pane>

                                           <Tab.Pane eventKey={4} active={activeKey === 4}>
                                               <WorkerRegisterAddressComponent user={data}
                                                                               activeStep={'INITIAL_REGISTER'}
                                                                               onSaveData={() => Env.configs?.occupation_approved_required ? handleChangeActiveKey(5) : history.push('/painel')}
                                               />
                                           </Tab.Pane>

                                           {Env.configs?.occupation_approved_required &&
                                               <Tab.Pane eventKey={5} active={activeKey === 5}>
                                                   <SendDocumentsComponent user={data}/>
                                               </Tab.Pane>
                                           }
                                       </Tab.Content>
                                   }
                               </div>
                           </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </div>
    )
}