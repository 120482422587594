import React from "react";

export const BudgetTableComponent = ({data, total}) => {
    const priceTotal = () => {
        let aux = 0

        data?.items.forEach((item) => {
            aux = aux + (item.price * item.amount)
        })

        return aux
    }

    const discountTotal = () => {
        let aux = 0

        for (let i = 0; i < data?.items?.length; i++) {
            if (data?.items[i].discount) {
                aux = aux + data?.items[i].discount;
            }
        }
        return aux;
    }

    const amountTotal = () => {
        let aux = 0

        for (let i = 0; i < data?.items?.length; i++) {

            aux = aux + data?.items[i].amount
        }
        return aux
    }

    return (
        <div className={'table-budget table-responsive'}>
            <table className={'table   table-striped table-hover table-bordered'}>
                <thead>
                    <tr>
                        <th className={'col-2'}>Nome</th>
                        <th className={'col-1'}>Quantidade</th>
                        <th className={'col-4'}>Descrição</th>
                        <th className={'col-2'}>Desconto</th>
                        <th className={'col-2'}>Preço</th>
                    </tr>
                </thead>
                <tbody>
                <>

                    {data?.items?.map((item, index) => (
                        <tr key={index}>
                            <td>{item.title}</td>
                            <td>{item.amount}</td>
                            <td>{item.description}</td>
                            <td>R$ {item.discount > 0 ? `${window.maskRealToView(Number(item?.discount))}` : '0,00'}</td>
                            <td>R$ {window.maskRealToView(item.price)}</td>
                        </tr>
                    ))}
                    <tr>
                        <th scope={"row"}>Subtotal</th>
                        <td colSpan={2} className={'fw-bold'}>{amountTotal()}</td>
                        <td colSpan={1} className={'fw-bold'}>R$ {window.maskRealToView(Number(discountTotal())) || `0,00`}</td>
                        <td colSpan={1} className={'fw-bold'}>R$ {window.maskRealToView(Number(priceTotal()))}</td>
                    </tr>
                </>
                </tbody>
                <tfoot>
                    <tr>
                        <th scope={"row"} className={' fs-5'}>Total</th>
                        <td colSpan={4} className={'text-end fw-bold'}>R$ {window.maskRealToView(total)}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}