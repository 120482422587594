import React, {useEffect, useRef, useState} from 'react'
import NumberFormat from "react-number-format";
import LabelWithLibs from "../../../components/Generic/LabelWithLibs";
import {AiFillPhone} from "react-icons/ai";
import {Button, Col, Modal} from "react-bootstrap";
import {toast} from "react-hot-toast";
import {Env} from "../../../helpers/Env";
import {BsHash} from "react-icons/bs";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import useApi from "../../../hooks/useApi";
import {maskPhone} from "../../../helpers/Functions";
import {Images} from "../../../helpers/Images";
import {useDispatch, useSelector} from "react-redux";

const VerificationCodeComponent = ({step, setStep, closeModal, showModal, resumeValidationCode}) => {
    const [resend, setResend] = useState(false);
    const intervalDecreaseRef = useRef();
    const [seconds, setSeconds] = useState(15);
    const [countHandleResend, setCountHandleResend] = useState(0);
    const api = useApi();
    const dispatch = useDispatch();
    const user =  useSelector(state => state).authReducer;
    const redirect = useRef(false);
    const userPhone = window.getPhoneNumbersWithoutCodeCountry(user.phone);

    const registerSchema = yup.object().shape({
        cellphone_number: yup.string().required('Informe seu número de telefone').min(11, 'Insira um número válido'),
        code: yup.string(),
    });

    const {register, setValue, getValues, trigger, watch, formState: { errors },} = useForm({
        defaultValues: registerSchema.cast({
            cellphone_number: window.getPhoneNumbersWithoutCodeCountry(user.phone),
            application: Env.application_alias
        }),
        resolver: yupResolver(registerSchema),
    });

    const validateNumber = () => {
        if(window.validateMobilePhone(getValues().cellphone_number) && getValues().cellphone_number.length === 11) {
            let sendApi = getValues()
            sendApi.cellphone_number = `+55${getValues().cellphone_number}`

            if ((user.phone === sendApi.cellphone_number) && user.mobile_verified) {
                toast.error(`O número ${maskPhone(user.phone)} já foi validado!`, {
                    className:'text-center'
                })
            } else {
                const toastLoad = toast.loading('Enviando SMS');

                api.post('access/mobile-verify', sendApi, (res) => {
                    setResend(true);
                    setStep(`code`);
                    toast.success(`Código enviado`, {
                        id: toastLoad
                    })
                    setValue(`token`, res.token)
                    decreaseSeconds();
                }, (e) => {
                    toast.error(e.message || 'Não foi possível validar, tente novamente', {
                        id:toastLoad
                    });
                });
            }

        } else {
            toast.error('Digite um número válido');
        }
    }

    const decreaseSeconds = () => {
        intervalDecreaseRef.current = setInterval(() => {
            setSeconds((value) => {
                if (value === 1) {
                    setResend(false);
                    setSeconds(15);
                    clearInterval(intervalDecreaseRef.current);
                    return 0;
                }
                return value - 1
            })
        }, 1000)
    }

    const validateCode = () => {
        let sendApi = getValues()
        sendApi.cellphone_number = `+55${getValues().cellphone_number}`
        const toastLoad = toast.loading(`Validando código...`)

        api.post('access/mobile-confirm', sendApi, () => {
            redirect.current = true;
            dispatch({type: 'update_user', data: {mobile_verified: true, phone: sendApi.cellphone_number}});
            toast.success(`Validação realizada com sucesso!`, {
                id: toastLoad,
                duration: 4000,
            })
        }, (e) => {
            toast.error(e.message || 'Código inválido', {
                id: toastLoad
            });
            setStep(`code`)
        });
    };

    const handleResend = async () => {
        let sendApi = getValues()
        sendApi.cellphone_number = `+55${getValues().cellphone_number}`
        setCountHandleResend(countHandleResend + 1)

        if (!resend) {
            setResend(true);
            decreaseSeconds();
            api.post('access/mobile-verify', sendApi, (res) => {
                toast.success(<span>Código reenviado para o número <div>{maskPhone(getValues().cellphone_number)}</div></span>, {
                    className:'text-center fs-7',
                    duration:4000
                });
                setValue(`token`, res.token)
            }, (e) => {
                toast.error(e.message || 'Não foi possível reenviar o código, tente novamente');
            });
        }
    };

    useEffect(() => {
        if(user.mobile_verified && redirect.current) {
            resumeValidationCode()
        }
    }, [user])

    return (
        <Modal show={showModal} onHide={closeModal} size={"lg"} scrollable>
            <Modal.Body className='p-0 w-100'>
                <Modal.Header className='border-0 p-3 position-absolute' style={{right:0}} closeButton/>
                <form  className='d-flex flex-column flex-lg-row' onSubmit={(e) => e.preventDefault()}>
                    <Col md={12} lg={4} className='bg-primary'>
                        <div className='de-flex justify-content-between flex-column fs-3 p-3 h-100 text-white'>
                            <div className='fw-bold'>
                                {step === `number` ? 'Vamos lá!' : 'Agora...'}
                            </div>
                            <div className='da-flex flex-column gap-2'>
                                <div className='de-flex numbers'>
                                    {step === `number` ? '1' : '2' }
                                </div>
                                <div className='text-center'>
                                    {step === `number` ? 'Informe o número do seu celular' : 'Informe o código enviado' }
                                </div>
                            </div>

                            <div className='fs-7'>
                                {step === `number` ? 'Para maior segurança é necessário validar o número de seu celular' : 'Enviamos um código de verificação para o número informado'}
                            </div>
                        </div>
                    </Col>

                    <Col md={12} lg={8} className='p-3 d-flex flex-column gap-2'>
                        {(step === 'number' || step === 'code') &&
                            <div className='text-center'>
                                <img style={{maxHeight: 250}} src={step === `number` ? Images.auth.number_step : Images.code_verification} alt='verification number'/>
                            </div>
                        }
                        {step === 'code' &&
                            <div className='my-2 fs-5 text-center'>
                                O código foi enviado para o número: <div className='text-primary '>{maskPhone(getValues().cellphone_number)}</div>
                            </div>
                        }

                        {step === `number` &&
                            <>
                                <div className={`form-floating`}>
                                    <NumberFormat className={`${errors?.cellphone_number?.message ? 'form-control is-invalid' : 'form-control'}`}
                                                  value={getValues()?.cellphone_number}
                                                  {...register('cellphone_number')}
                                                  placeholder='Telefone'
                                                  onValueChange={async(e) => {
                                                      setValue('cellphone_number', e.value)
                                                      e.value.length === 11 && await trigger('cellphone_number')
                                                  }}
                                                  format={'(##) #####-####'}
                                                  mask="_"
                                    />
                                    <LabelWithLibs label='Informe seu número' labelIcon={<AiFillPhone size={16}/>} className={`${errors?.cellphone_number?.message ? 'invalid-feedback' : ''}`}/>
                                    {errors?.cellphone_number?.message &&
                                        <div className={`${errors?.cellphone_number?.message ? 'invalid-feedback' : ''}`}>
                                            {errors?.cellphone_number?.message}
                                        </div>
                                    }
                                </div>

                                {userPhone !== watch()?.cellphone_number &&
                                    <div className='px-2 text-end'>
                                <span className='fw-light fst-italic text-danger' style={{fontSize: 13,}}>
                                    Seu número de telefone será alterado para o digitado acima
                                </span>
                                    </div>
                                }
                            </>
                        }

                        {step === 'code' &&
                            <div className={`form-floating mb-2`}>
                                <NumberFormat
                                    className='form-control'
                                    format={'### ###'}
                                    {...register('code')}
                                    onValueChange={(e) => {
                                        setValue(`code`, e.value)
                                        e.value.length === 6 && validateCode()
                                    }}
                                    style={{width:'100%', textAlign: 'center'}}
                                    placeholder={'Digite aqui'}
                                />
                                <LabelWithLibs label='Código' labelIcon={<BsHash size={18}/>}/>
                            </div>
                        }

                        {countHandleResend >= 1 &&
                            <div className='d-flex justify-content-end'>
                                <a target={'_blank'} rel="noreferrer" className='text-decoration-none' href={window.innerWidth < 768? 'https://wa.me/' + Env.support_number : 'https://web.whatsapp.com/send?phone=' + Env.number} >
                                    <p className='m-0 text-end fs-8' style={{color:'#3d8bfd'}}>
                                        Não recebeu o código? Entre em contato conosco!
                                    </p>
                                </a>
                            </div>
                        }

                        {resend &&
                            <p className='mb-2 text-end fs-8' style={{color:'#3d8bfd'}}>
                                Você poderá reenviar o código novamente dentro de <strong>{seconds}</strong> segundos.
                            </p>
                        }

                        {step === 'number' ?
                            <div className='d-flex justify-content-end'>
                                <Col xs={5}>
                                    <Button type='button' disabled={resend} className='w-100' onClick={async () => {
                                        const output = await trigger('cellphone_number')
                                        output && validateNumber();
                                    }}>
                                        Enviar código
                                    </Button>
                                </Col>
                            </div> :
                            <div className='d-flex justify-content-between'>
                                <Col xs={4}>
                                    <Button type='button' variant='outline-dark' className='w-100' onClick={() => setStep(`number`)}>
                                        Voltar
                                    </Button>
                                </Col>

                                <Col xs={5}>
                                    <Button type='button' className='w-100' disabled={resend} onClick={() => handleResend()}>
                                        Reenviar código
                                    </Button>
                                </Col>
                            </div>

                        }
                    </Col>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default VerificationCodeComponent
