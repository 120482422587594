import {useRef, useState} from 'react'
import {checkStateRestriction, showNotification} from "./Helpers";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import {isEmpty} from "lodash";

const useGeolocation = (isHome) => {
    const geocode = useRef()
    const history = useHistory();

    const [formattedAddress, setFormattedAddress] = useState('')
    const [completedAddress, setCompletedAddress] = useState()
    const [loadingAddress, setLoading] = useState(false)

    const handleChangeAddress = (e) => {
        if (isEmpty(e)) {
            if (isHome) {
                setCompletedAddress(null)
            } else {
                const urlParams = new URLSearchParams(window.location.search)

                urlParams.delete(`latitude`)
                urlParams.delete(`longitude`)
                urlParams.delete(`loc`)
                history.push(`/quero-contratar?${urlParams}`)
            }
        }
        setFormattedAddress(e)
    }

    const getGeolocation = () => {
        setLoading(true)
        setTimeout(async () => {
            const place = await geocode.current.getPlace();
            if (checkStateRestriction(place)) {
                if (isHome) {
                    let aux = {
                        lat: place.geometry.location.lat(),
                        long: place.geometry.location.lng(),
                        loc: place.formatted_address,
                    }
                    setCompletedAddress(aux)
                    setFormattedAddress(place?.formatted_address)
                    setLoading(false)
                } else {
                    const urlParams = new URLSearchParams(window.location.search)
                    urlParams.append(`latitude`, `${place.geometry.location.lat()}`)
                    urlParams.append(`longitude`, `${place.geometry.location.lng()}`)
                    urlParams.append(`loc`, `${place.formatted_address}`)

                    history.push(`/quero-contratar?${urlParams}`)
                    handleChangeAddress(place?.formatted_address)
                    setLoading(false)
                }
            } else {
                const urlParams = new URLSearchParams(window.location.search)
                showNotification();
                if (urlParams.has(`loc`)) {

                    urlParams.delete(`latitude`)
                    urlParams.delete(`longitude`)
                    urlParams.delete(`loc`)
                    history.push(`/quero-contratar?${urlParams}`)
                }
                setFormattedAddress('');
                setLoading(false)
            }
        },[500])
    }

    const setInputGoogle = (id) => {
        geocode.current = new window.google.maps.places.Autocomplete(document.querySelector(`#${id}`), {types: ['geocode']});
        geocode.current.addListener('place_changed', getGeolocation);
        geocode.current.setComponentRestrictions({'country': ['br']});
    }

    return {setInputGoogle, formattedAddress, handleChangeAddress, loadingAddress, completedAddress}
}
useGeolocation.propTypes = {
    id: PropTypes.string
};
export default useGeolocation