import React from "react";

const NotFound = (props) => {
    return (
        <>
            <div>
                <div className='text-center'>
                    <span style={{fontSize: 23}}> {props.title}</span>
                    {props.subTitle && <> <br/>
                    <span style={{fontSize: 18}}> {props.subTitle}</span> </>}
                </div>
                <div className='text-center'>
                    <img src={props.img} height={320} alt='not found'/>
                </div>
            </div>
        </>
    );
};

NotFound.propTypes = {};

export default NotFound;
