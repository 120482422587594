import React from 'react';
import "./helpers/Functions";
import LayoutManager from "./routes/LayoutManager";
import store from "./store/Store";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import * as moment from "moment";
import 'moment/locale/pt-br';
import './styles/style.scss'
import * as Sentry from "@sentry/react";

moment.locale('pt-br');

const App = () => {
    return (
        <Sentry.ErrorBoundary fallback={({ error, componentStack, resetError }) => (
            <React.Fragment>
                <div>Parece que você encontrou um erro, iremos corrigir o mais rápido possível!</div>
                <button
                    onClick={() => {
                        resetError();
                    }}
                >
                    Clique aqui para recarregar
                </button>
            </React.Fragment>
        )} showDialog>
            <Router>
                <Provider store={store}>
                    <LayoutManager />
                </Provider>
            </Router>
        </Sentry.ErrorBoundary>
    );
};

App.propTypes = {};

export default App;
