import React, {useState} from "react";

import {Button, Collapse, Offcanvas, Spinner} from "react-bootstrap";
import {
    FaBriefcase,
    FaClock, FaFacebookF, FaInstagram,
    FaMapMarkerAlt,
    FaPhoneAlt,
    FaRegEye,
    FaRegEyeSlash,
    FaTags
} from "react-icons/fa";
import useAuth from "../../hooks/Auth";
import ModalController from "../LogIntoSystem/ModalController";
import {globalMaskReal} from "../../hooks/Helpers";
import {useQueryClient} from "@tanstack/react-query";
import ShareTo from "../../components/Generic/ShareTo";
import {useParams} from "react-router-dom";
import {ReviewsComponent} from "../../components/Generic/ReviewsComponent";


const CardOfVisits = (props) => {

    const {isLogged} = useAuth();

    const [showServices, setShowServices] = useState(false);
    const [showTime, setShowTime] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [step, setStep] = useState(undefined);
    const [open, setOpen] = useState(false);
    const params = useParams()

    const dataProfile = useQueryClient().getQueryData(['profile']);

    const handleCloseServices = () => setShowServices(false);
    const handleShowServices = () => setShowServices(true);

    const handleCloseTime = () => setShowTime(false);
    const handleShowTime = () => setShowTime(true);

    const handleCloseAddress = () => setShowAddress(false);
    const handleShowAddress = () => setShowAddress(true);

    const daysPT = {
        monday: 'Segunda',
        tuesday: 'Terça',
        wednesday: 'Quarta',
        thursday: 'Quinta',
        friday: 'Sexta',
        saturday: 'Sábado',
        sunday: 'Domingo'
    };


    const handleHire = () => {
        if(isLogged()) {
            props.requestContact(true);
        }
        else {
            setStep('login');
        }
    };

    const handleStepModal = (value) => {
        setStep(value)
    }



    return (
        <div>
            <div>
                {props.banner ?
                    <div>
                        <img src={props.banner} style={{width:'100%', objectFit:'cover'}} alt={'banner'}/>
                    </div> :
                    <div className="banner-card-of-visits"/>
                }
                <div className={'container'}>
                    <div className="d-flex justify-content-center " style={{marginTop: -120}}>
                        <div className="photo-container">
                            <img src={props.avatar}
                                 className="rounded-circle shadow"
                                 alt=""
                            />
                        </div>
                    </div>

                    <div className="mt-4">
                        <div className="text-center ">
                            <h1 className={'px-2'}>
                                {props.name}
                            </h1>
                        </div>
                        <div className="text-center">
                            <span className='rounded bg-gray-300 text-gray-800 fw-light fs-6 px-2 py-1 text-center'>
                                {props.occupation.name}
                            </span>
                        </div>
                    </div>
                    {(props.instagramLink || props.facebookLink) &&
                        <div className={'social-media-icon justify-content-center mt-3 '}>
                            {props.instagramLink &&
                                <a className={'instagram'}
                                   href={`https://www.instagram.com/${props.instagramLink}`} target={"_blank"}>
                                    <FaInstagram className={"fs-2"}/>
                                    @{props.instagramLink.includes("?") ?
                                    props.instagramLink.split("?").shift()
                                    :
                                    props.instagramLink
                                }
                                </a>
                            }

                            {props.facebookLink &&
                                <a className={'facebook'} href={`https://m.facebook.com/${props.facebookLink}`}
                                   target={"_blank"}>
                                    <FaFacebookF className={ "fs-2"}/>

                                    {props.facebookLink}
                                </a>
                            }
                        </div>
                    }

                    <div className={'mt-3'}>
                        <div className="row gx-1 gy-3 justify-content-center">
                            <div className="col-6">
                                {props.phoneNumber ?
                                    <div className='show-number rounded-2'>
                                        <span className='d-flex align-items-center gap-2 justify-content-center'>
                                            {window.maskViewPhone(props.phoneNumber)}
                                        </span>
                                    </div>
                                    :
                                    <Button variant='secondary' className='w-100' onClick={() => handleHire()}>
                                        <span className='d-flex align-items-center gap-2 justify-content-center'>
                                            <FaPhoneAlt/> Ver contato
                                        </span>
                                    </Button>
                                }
                            </div>
                            <div className="col-6">
                                <Button variant="outline-primary"
                                        className="w-100 d-flex flex-center gap-1 w-100"
                                        onClick={() => setOpen(!open)}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={open}
                                >

                                    {open ?
                                        <FaRegEyeSlash className={"fs-5"}/>
                                        :
                                        <FaRegEye className={"fs-5"}/>

                                    }

                                    <i className={`ki-duotone fs-5 ki-eye${open ? "-slash" : ""}`}>
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        {open && <span className="path4"></span>}
                                    </i>
                                    Sobre mim
                                </Button>
                            </div>
                            <div className="col-8">
                                <ShareTo style={{width: "100%"}}/>
                            </div>
                        </div>
                        <div>
                            <Collapse in={open}>
                                <div className="container" id="example-collapse-text">
                                <span className="text-center d-flex justify-content-center mt-4">
                                    {props.bio ? props.bio : 'Nada informado'}
                                </span>
                                </div>
                            </Collapse>
                        </div>
                        <div className="d-flex d-md-none justify-content-center mt-4">
                            <div className="row justify-content-center gap-3 mb-5">
                                <div onClick={handleShowServices}
                                     className="bg-light d-flex justify-content-center align-items-center flex-column rounded-circle"
                                     style={{width: 150, height: 150}}>
                                    <div>
                                        <FaBriefcase style={{fontSize:"3rem", color:"#7284ff"}}/>

                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <span className="text-center fs-6">
                                            Produtos dos Serviços
                                        </span>
                                    </div>
                                </div>
                                <div onClick={handleShowTime}
                                     className="bg-light d-flex justify-content-center align-items-center flex-column rounded-circle"
                                     style={{width: 150, height: 150}}>

                                    <FaClock style={{fontSize:"3rem", color:"#7284ff"}}/>


                                    <div className="d-flex justify-content-center">
                                    <span className="text-center fs-6">
                                        Horário de trabalho
                                    </span>
                                    </div>
                                </div>
                                <div onClick={handleShowAddress}
                                     className="bg-light d-flex justify-content-center align-items-center flex-column rounded-circle"
                                     style={{width: 150, height: 150}}>

                                    <FaMapMarkerAlt style={{fontSize: "3.2rem", color: "#ff7272"}}/>

                                    <div className="d-flex justify-content-center">
                                    <span className="text-center fs-6">
                                        Endereço
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={'container'}>
                    <div className={'mb-5'}>
                        <ReviewsComponent dataWorker={{user_id: params?.id, occupation_id: params?.occupation}}
                                          activeKey={props.activeMode}
                                          maxHeigth={500}
                                          eventKey={'MOBILE'}
                        />
                    </div>
                </div>

                {/*<div className={'container'}>*/}
                {/*    <div className={'mb-5 mt-3'}>*/}
                {/*        <NewPostComponent eventKey={'MOBILE'}*/}
                {/*                          activeKey={props.activeMode}*/}
                {/*                          dataProfile={dataProfile}*/}
                {/*                          showOnlyPosts={true}*/}
                {/*                          dataWorker={{*/}
                {/*                              name: props.name,*/}
                {/*                              occupation: props.occupation.name,*/}
                {/*                              avatar: props.avatar*/}
                {/*                          }}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <Offcanvas show={showServices} onHide={handleCloseServices} style={{width:"90%"}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Produtos dos Serviços
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>
                        {props?.occupation?.activities.length === 0 ?
                            <>
                                Nenhum produto cadastrado
                            </>
                            :
                            <>
                                <ul  className='service-offered px-2 d-flex flex-column gap-2 last-child-border-none' style={{listStyle: "none"}}>
                                    {props?.occupation.activities?.map((active, index) => (
                                        <>
                                            <li key={index} className={'border-bottom pb-3'}>
                                                <div className='da-flex gap-2 mb-1'>
                                                    <h6 className={'mb-0'}>{active?.title}</h6>
                                                </div>
                                                <div className='da-flex gap-2'>

                                                    {(parseFloat(active?.max_price) === 0 || parseFloat(active?.min_price) === 0) ?
                                                        <span className={'px-2 bg-secondary-light text-secondary rounded-pill border border-secondary mt-1 fs-7'}>
                                                            Valor a combinar
                                                        </span>
                                                        :
                                                        <>
                                                            <FaTags className={"fs-4"} style={{ color: "#2e7d32"}}/>


                                                            <div className={'fs-6'}>
                                                                <span className='fw-bold me-1'>R$</span>
                                                                <span>
                                                                    {globalMaskReal(active?.max_price)}
                                                                </span>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div className={'mt-2 text-justify'}>
                                                    <span className='text-muted'>{active?.description}</span>
                                                </div>
                                            </li>
                                        </>
                                    ))}
                                </ul>
                            </>
                        }
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showTime} placement={"bottom"} onHide={handleCloseTime}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Horário de trabalho
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <>
                        {props?.occupation.work_schedule?.map((item, index) =>
                            <div key={index} className="da-flex justify-content-between">
                                <div className={'da-flex gap-2'}>
                                    <FaClock style={{fontSize:"1rem", color:"#7284ff"}}/>

                                    <span>
                                        {daysPT[item.dow]}
                                    </span>
                                </div>
                                <div>
                                    <span> {item.start1} às {item.end1} </span>
                                    {item.start2 && <span> {item.start2} às {item.end2}</span>}
                                </div>
                            </div>

                        )}
                    </>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showAddress} placement={"bottom"} onHide={handleCloseAddress}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Endereço
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {props.address.map((item, index) =>
                        <div key={index} className={'d-flex gap-2'}>
                            <FaMapMarkerAlt style={{fontSize: "1.5rem", color: "#ff7272"}}/>

                            <span>
                                {item.street_name + ", " + item.district + " - " + item.city + " / " + item.state}
                            </span>
                        </div>
                    )}
                </Offcanvas.Body>
            </Offcanvas>

            {step && <ModalController typeModal={step} handleStepModal={(e) => handleStepModal(e)}/>}
        </div>
    )
}
export default CardOfVisits
