import React, { useState} from 'react'
import useAuth from "../hooks/Auth";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import ModalController from "../pages/LogIntoSystem/ModalController";
import NavbarTop from "./Components/NavbarTop";
import NavbarBottom from "./Components/NavbarBottom";
import {useQueryClient} from "@tanstack/react-query";

const Header = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { logoutWithoutApi } = useAuth();
    const queryClient = useQueryClient()
    const { data } = queryClient.getQueryState(['profile']);

    const [step, setStep] = useState(undefined)

    const handleStepModal = (value) => {
        setStep(value)
    }

    const logout = () => {
        logoutWithoutApi();
        queryClient.clear();
    }

    return (
        <header>
            <NavbarTop dispatch={dispatch}
                       history={history}
                       isLogged={!!data}
                       handleStepModal={(value) => handleStepModal(value)}
                       logoutWithoutApi={logout}
            />

            <NavbarBottom history={history}
                          isLogged={!!data}

            />

            {step &&  <ModalController typeModal={step}  handleStepModal={(e) => handleStepModal(e)}/>}
        </header>
    )
}

export default Header
