import React from 'react'
import {FaAngleRight, FaFacebookF, FaInstagram, FaWhatsapp} from "react-icons/fa";
import {Link} from "react-router-dom";
import {Col, Container, Navbar} from "react-bootstrap";
import {Env} from "../helpers/Env";
import {Images} from "../helpers/Images";

const Footer = () => {

    return (
        <>
            <footer className='footer-page bg-primary'>
                <Container fluid={"lg"} className='d-flex flex-wrap'>
                    <Col xs={12} md={3} className='my-3 text-white text-center text-sm-start'  >
                        <div className='mb-3 fw-bold fs-6'>Sobre</div>

                        <div className='d-flex flex-column gap-2 fs-7'>
                            <Link className='text-white' to={"termos"}>
                                <FaAngleRight size={18}/>
                                <span className='ps-2'>Termos de uso</span>
                            </Link>

                            <Link className='text-white' to={"termos"}>
                                <FaAngleRight size={18}/>
                                <span className='ps-2'>Políticas de Privacidade</span>
                            </Link>

                            <Link className='text-white' to={"faq"}>
                                <FaAngleRight size={18}/>
                                <span className='ps-2'>Perguntas Frequentes</span>
                            </Link>
                        </div>
                    </Col>

                    <Col xs={12} md={3} className='my-3 text-white text-center text-sm-start' >
                        <div className='mb-3 fw-bold fs-6'>Serviços</div>

                        <div className='d-flex flex-column gap-2 fs-7'>
                            <Link className='text-white' to={'/quero-contratar'}>
                                <FaAngleRight size={18}/>
                                <span className='ps-2'>Preciso de um profissional</span>
                            </Link>
                        </div>
                    </Col>

                    <Col xs={12} md={3} className='my-3 text-white text-center text-sm-start'>
                        <div className='mb-3 fw-bold fs-6'>Redes sociais</div>

                        <div className='d-flex flex-column gap-2 fs-7'>
                            <a className='text-white' target={'_blank'} href={`https://www.instagram.com/${Env.instagram}`}>
                                <FaInstagram size={20}/>
                                <span className='ps-2'>Instagram</span>
                            </a>

                            <a className='text-white' target='_blank' href={`https://www.facebook.com/${Env.facebook}`}>
                                <FaFacebookF size={18}/>
                                <span className='ps-2'>Facebook</span>
                            </a>
                        </div>
                    </Col>

                    <Col xs={12} className='my-3 text-white text-center text-sm-start' md={3}>
                        <div className='mb-3 fw-bold fs-6'>Ajuda</div>

                        <div className='d-flex flex-column gap-2 fs-7'>
                            <a className={'text-white'} target={'_blank'} href={window.innerWidth < 768? 'https://wa.me/' + Env.configs?.contact_number : 'https://web.whatsapp.com/send?phone=+' + Env.configs?.contact_number}>
                                <FaWhatsapp size={18}/>
                                <span className='ps-2'>Fale conosco pelo WhatsApp</span>
                            </a>

                            <Link style={{wordBreak:"break-all"}} className='text-white'>
                                {Env.support_email}
                            </Link>
                        </div>

                    </Col>
                </Container>
            </footer>

            <Navbar bg={'transparent'} className='gov p-0'>

                <div className='sub-footer gap-2 flex-grow-1 ' >
                    <div className='d-flex flex-column'>
                        <div style={{borderTop:'1px solid #ffb900', height:3, width:'100%'}}/>

                        <div className='da-flex py-3'>
                            <div style={{left:35}} className='position-absolute px-1'>
                                <img src={Images.logo_gov.brasao_ro} height={70} alt="brasão RO"/>
                            </div>
                            <div style={{marginLeft:110}} className='py-2'>
                                <span style={{color:'#999999', fontSize:14}} className='fw-light'>Portal do Governo do</span>
                                <div style={{color:'#333333', fontSize:25}} className='fw-bold'>Estado de Rondônia</div>
                            </div>
                        </div>

                        <div style={{borderTop:'3px solid #ffb900', height:3, width:'100%'}}/>

                        <div className='d-flex justify-content-center justify-content-md-between flex-wrap py-3 px-2 gap-2 px-md-4 bg-primary text-white fs-8'>
                            <div className='text-center text-md-start'>
                                <div>
                                    <strong>Palácio Rio Madeira</strong> - Av. Farquar, 2986 - Bairro Pedrinhas
                                </div>
                                <div>
                                    CEP 76.801-470 - Porto Velho, RO
                                </div>
                            </div>

                            <div>
                                <div className='text-center text-md-start'>
                                    &copy; 2022 <strong>Governo do Estado de Rondônia</strong>
                                </div>
                                <div className='text-center text-md-end '>
                                    Todos os Direitos Reservados
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Navbar>

        </>
    )
}

export default Footer
