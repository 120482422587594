import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {FaArrowLeft} from "react-icons/fa";

export const BackButtonComponent = ({className, classNameIcon, onClick, redirectTo, style, position, message}) => {
    const settings = {
        className:`back-button btn-reset py-1 px-2 da-flex gap-1 rounded-pill  ${className || ''}`,
        style: (style || position) || {top:0, left:0}
    }

    const DynamicTag = (props) => {
        if (redirectTo) {
            return <Link to={`${redirectTo}`} {...settings}>
                {props.children}
            </Link>
        } else {
            return <button type={'button'} {...settings} onClick={onClick}>
                {props.children}
            </button>
        }
    }

    return (
        <DynamicTag>
            <FaArrowLeft />

            <span>
				{message || 'Voltar'}
			</span>
        </DynamicTag>
    )
}

BackButtonComponent.propTypes = {
    className: PropTypes.string,
    classNameIcon: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    redirectTo: PropTypes.string,
};