import React from "react";

export const ErrorMessageComponent = ({message, errors, name}) => {
    return (
        <div>
            {(errors && name) ?
                <small className={`${errors?.[name]?.message ? 'invalid-feedback d-block' : '' } mt-0`}>
                    <span>{errors?.[name]?.message}</span>
                </small> :
                <small className={`${message ? 'invalid-feedback d-block' : '' } mt-0`}>
                    <span>{message}</span>
                </small>
            }
        </div>
    )
}