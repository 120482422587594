import React from "react";
import {Nav} from "react-bootstrap";
import {FaCheck} from "react-icons/fa";
export const StepperItemComponent = ({onClick, title, activeStep, step, subTitle, disabled, iconStep}) => {

    return (
        <Nav.Item className={'stepper-item'}>
            <Nav.Link eventKey={step}
                      disabled={disabled}
                      className={`btn-reset p-2 text-start stepper-wrapper ${activeStep === step ? 'current' : ''} gap-3 `}
                      as={"button"}
                      onClick={() => onClick(step)}
            >
                <span className={`stepper-icon ${activeStep > step ? 'stepper-completed' : ''}`}>
                    {iconStep ?
                        iconStep
                        :(activeStep > step) ?
                            <FaCheck className={"fs-6"}/>
                            :
                            step
                    }
                </span>
                <div className={'stepper-label'}>
                    <h5 className={'stepper-title'}>
                        {title}
                    </h5>
                    <span>
                       {subTitle}
                    </span>
                </div>
            </Nav.Link>
        </Nav.Item>
    )
}