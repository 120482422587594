export const OptionsSelect = {
    genders: [
        { value: 'male', label: 'Masculino' },
        { value: 'female', label: 'Feminino' },
        { value: 'transgender_male', label: 'Homem transgênero' },
        { value: 'transgender_female', label: 'Mulher transgênero' },
        { value: 'transsex_male', label: 'Homem Transexual' },
        { value: 'transsex_female', label: 'Mulher Transexual' },
        { value: 'unknown', label: 'Não sei responder' },
        { value: 'not_anwswered', label: 'Prefiro não responder' },
        { value: 'other', label: 'Outros' },
    ]
}