import React, {useState} from 'react';
import PropTypes from 'prop-types';
import useAuth from "../../../hooks/Auth";
import {Button, Col, Accordion, Spinner} from "react-bootstrap";
import {FaCalendarCheck, FaCheck, FaPhoneAlt, FaRegClock} from "react-icons/fa";
import ModalController from "../../LogIntoSystem/ModalController";
import {useSelector} from "react-redux";
import VerificationCodeComponent from "../../LogIntoSystem/Component/VerificationCodeComponent";
import ShareTo from "../../../components/Generic/ShareTo";


const daysPT = {
    monday: 'Segunda',
    tuesday: 'Terça',
    wednesday: 'Quarta',
    thursday: 'Quinta',
    friday: 'Sexta',
    saturday: 'Sábado',
    sunday: 'Domingo'
};

const ServiceProviderExtras = (props) => {
    const {isLogged, store} = useAuth();
    const [step, setStep] = useState(undefined);
    const [openDiary, setOpenDiary] = useState(false);
    const [openOccupation, setOpenOccupation] = useState(false);
    const [openAvailable, setAvailable] = useState(false);
    const [modalVerificationCode, setModalVerificationCode] = useState(false);
    const user =  useSelector(state => state).authReducer;

    const handleStepModal = (value) => {
        setStep(value)
    }

    const handleHire = () => {
        if(isLogged()) {
            if (user.mobile_verified) {
                props.requestContact(true);
            } else {
                setModalVerificationCode(true)
                setStep(`number`)
            }
        }
        else {
            setStep('login');
        }
    };

    const resumeValidationCode = () => {
        setStep(undefined);
        props.requestContact(true);
        setModalVerificationCode(false);
        store(user, true)
    }

    return (
        <>
            <Col xs={12} className='justify-content-between'>
                {props.requestContactLoading ?
                    <button disabled className='btn btn-outline-success de-flex p-2 rounded-2 fs-5 w-100'>
                        <Spinner size='sm' animation='border'/> Ver contato
                    </button>
                    : props.phoneNumber ?
                        <div className='show-number p-2 rounded-2 fs-5'>
                            <span className='d-flex align-items-center gap-2 justify-content-center'><FaPhoneAlt/> {window.maskViewPhone(props.phoneNumber)}</span>
                        </div>
                        :
                        <Button variant='success' className='w-100' onClick={() => handleHire()}>
                            <span className='d-flex align-items-center gap-2 justify-content-center'><FaPhoneAlt/> Ver contato</span>
                        </Button>
                }
                <ShareTo style={{width: "100%", marginTop:10}}/>
            </Col>

            {(props.actualOccupation.extra_fields?.daily_price || props.actualOccupation.extra_fields?.half_daily_price) &&
                <>
                    <Accordion defaultActiveKey='0'>
                        <Accordion.Item eventKey='0'>

                            <Accordion.Button onClick={() => setOpenDiary(!openDiary)} className='d-flex justify-content-between shadow-none'>
                                <div className='fs-5'>Diárias</div>
                                <div className={openDiary ? "circle-plus closed ": "circle-plus opened" }>
                                    <div className="circle ">
                                        <div className="horizontal"/>
                                        <div className="vertical"/>
                                    </div>
                                </div>
                            </Accordion.Button>

                            <Accordion.Body className='border-top'>
                                {props.actualOccupation.extra_fields.daily_price &&
                                    <div className='da-flex gap-2 fs-6'>
                                        <FaCalendarCheck/>Diária -
                                        <div className='text-success fw-bolder'>R$ {props.actualOccupation.extra_fields.daily_price}</div>
                                    </div>
                                }
                                {props.actualOccupation.extra_fields.half_daily_price &&
                                    <div className='da-flex gap-2 fs-6 mt-2'>
                                        <FaCalendarCheck/>Meia diária -
                                        <div className='text-success fw-bolder'>R$ {props.actualOccupation.extra_fields.half_daily_price}</div>
                                    </div>
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
            }

            <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='0'>

                    <Accordion.Button onClick={() => setOpenOccupation(!openOccupation)} className='d-flex justify-content-between shadow-none'>
                        <div className='fs-5'>Ocupações</div>
                        <div className={openOccupation ? "circle-plus closed ": "circle-plus opened" }>
                            <div className="circle">
                                <div className="horizontal"/>
                                <div className="vertical"/>
                            </div>
                        </div>
                    </Accordion.Button>

                    <Accordion.Body className='d-flex flex-column gap-2 border-top fs-6'>
                        {props.occupations?.map((item,index) => (
                            <div key={index} className='da-flex gap-2'>
                                <FaCheck/>  {item.name}
                            </div>
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='0'>

                    <Accordion.Button onClick={() => setAvailable(!openAvailable)} className='d-flex justify-content-between shadow-none'>
                        <div className='fs-5'>Horários disponíveis</div>
                        <div className={openAvailable ? "circle-plus closed ": "circle-plus opened" }>
                            <div className="circle">
                                <div className="horizontal"/>
                                <div className="vertical"/>
                            </div>
                        </div>
                    </Accordion.Button>

                    <Accordion.Body className='d-flex flex-column gap-2 border-top fs-6'>
                        {props.actualOccupation?.work_schedule?.map((item, index) =>
                            <div key={index} className='da-flex justify-content-between fs-6'>
                                <div className='da-flex gap-2'>
                                    <FaRegClock className='text-success'/> {daysPT[item.dow]}
                                </div>

                                <div className='d-flex flex-column'>
                                    <span> {item.start1} às {item.end1} </span>
                                    {item.start2&& <span> {item.start2} às {item.end2}</span>}
                                </div>
                            </div>)
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            {modalVerificationCode &&
                <VerificationCodeComponent showModal={modalVerificationCode}
                                           closeModal={() => setModalVerificationCode(false)}
                                           resumeValidationCode={resumeValidationCode}
                                           step={step}
                                           setStep={(e) => setStep(e)}
                />
            }

            {step &&  <ModalController typeModal={step}  handleStepModal={(e) => handleStepModal(e)}/>}
        </>
    );
};

ServiceProviderExtras.propTypes = {
    actualOccupation: PropTypes.any,
    occupations: PropTypes.any,
    setShowModal: PropTypes.any,
    showModal: PropTypes.any
};

export default ServiceProviderExtras;
