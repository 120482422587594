import React, {useEffect} from 'react';
import useLocalStorage from "../hooks/Storage";
import ProfilePage from "../pages/Profile/ProfilePage";
import ClientPanelComponent from "../pages/Panel/ClientPanelComponent";
import {PrivateRoute} from "../helpers/PrivateRoute";
import {useDispatch} from "react-redux";
import {StorageVariables} from "../helpers/StoragesVariables";
import MyContactsManage from "../pages/Contacts/MyContactsManage";
import NotificationPage from "../pages/Notification/NotificationPage";

const ClientPanelPageRoutes = () => {

    const panelStorage = useLocalStorage(StorageVariables.lastPanel);
    const dispatch = useDispatch();


    useEffect(() => {
        window.scrollToElement('body');
        panelStorage.setPrimitive('client');
        dispatch({type: 'panel_profile', data: 'client'});
    },[]);

    return (
        <div className='panel-client-routes'>
            <PrivateRoute exact path={'/painel/cliente'} component={ClientPanelComponent}/>
            <PrivateRoute exact path={'/painel/cliente/perfil'} component={ProfilePage}/>
            <PrivateRoute exact path={'/painel/cliente/notificacoes'} component={NotificationPage}/>
            <PrivateRoute exact path={'/painel/cliente/contatos'} component={MyContactsManage}/>
        </div>
    )
};
export default ClientPanelPageRoutes;
