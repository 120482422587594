import React, {useEffect, useState} from "react";
import {ChangeAvatarComponent} from "../ChangeAvatarComponent";
import {toast} from "react-hot-toast";
import {Button} from "react-bootstrap";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import NumberFormat from "react-number-format";
import {api} from "../../../../services/api";
import {FaFacebookF, FaInstagram} from "react-icons/fa";

export const AboutYouComponent = ({onClick, instagram, bio, facebook, isEdit, avatar, userId}) => {
    const [data, setData] = useState({bio: bio || "", instagram_link: instagram || "", facebook_link: facebook || "", cnpj:"", have_cnpj:false, is_mei: undefined });
    const query = useQueryClient();
    const getValuesInput = () => {
        if (data.bio.length < 10) {
            return toast.error(data.bio.length  === 0 ? 'Escreva sobre você' :'Escreva um pouco mais sobre você')
        }

        if ((data?.cnpj?.length < 14) && data?.have_cnpj) {
            let elem = document.querySelector('#input_cnpj');
            elem.scrollIntoView({behavior:'smooth'});
            elem.focus();
            return toast.error('Insira um CNPJ válido')
        }

        if (!data?.have_cnpj) {
            data.cnpj = ''
        }

        if (!data?.have_cnpj && data.is_mei) {
            data.is_mei = undefined
        }

        let medias = {
            instagram_link: data?.instagram_link,
            facebook_link: data?.facebook_link
        }

        medias = filterMedias(medias)

        mutate({...data, ...medias})
    }

    const filterMedias = (medias) => {
        if (medias.instagram_link) {
            let filterUsernameInstagram = medias.instagram_link.split('/');

            filterUsernameInstagram = filterUsernameInstagram.filter((el, index) => {
                return filterUsernameInstagram[index] !== ''
            })

            data.instagram_link = filterUsernameInstagram[filterUsernameInstagram.length -1]

            if (medias.instagram_link.includes('@')) {
                medias.instagram_link = data.instagram_link.replace('@', '').trim()
            }

            if (medias.instagram_link.includes('?')) {
                medias.instagram_link = medias.instagram_link.split('?').shift();
            }

        }

        if (medias.facebook_link) {
            let filterUsernameFacebook = medias.facebook_link.split('/');

            filterUsernameFacebook = filterUsernameFacebook.filter((el, index) => {
                return filterUsernameFacebook[index] !== ''
            })

            medias.facebook_link = filterUsernameFacebook[filterUsernameFacebook.length -1]
        }
        return medias
    }

    const onChange = (label, value) => {
        setData({...data, [label]:value})
    }

    const {mutate} = useMutation({
        mutationKey: 'update-about-me',
        mutationFn: (variables) => handleUpdateData(variables),
        onSuccess: async (data, variables, context) => {
            await query.setQueryData(['profile'], (oldData) => {
                let aux = oldData
                oldData.profile = data
                return aux
            })
            onClick();
        }
    })

    const handleUpdateData = async (variables) => {
        let aux = query.getQueryData(['profile'])
        let sendToApi = {
            first_name: aux.profile.first_name,
            last_name: aux.profile.last_name,
            email: aux.profile.email,
            nickname: aux.profile.nickname,
            birthday: aux.profile.birthday,
            gender: aux.profile.gender,
            ...variables
        }
        if (!sendToApi?.have_cnpj && sendToApi?.cnpj?.length === 0) {
            delete sendToApi?.cnpj
        }
        if (sendToApi.is_mei === undefined) {
            sendToApi.is_mei = false
        }

        if (!sendToApi.have_cnpj && !sendToApi.is_mei) {
           delete sendToApi.is_mei
        }

        let socialMedia = {
            facebook_link: sendToApi.facebook_link,
            instagram_link: sendToApi.instagram_link
        }

        await api.post(`user/${aux.user_id}/profile`, sendToApi);
        await api.post(`user/social-media`, socialMedia);
        return sendToApi
    }

    useEffect(() => {
        let aux = data

        let aux2 = query.getQueryData(['profile'])
        if (aux2?.profile?.cnpj) {
            aux.cnpj = aux2?.profile?.cnpj
            aux.have_cnpj = true
            aux.is_mei = aux2?.profile?.is_mei ? aux2?.profile?.is_mei : undefined
        }

        setData(aux)
    }, []);

    return (
        <div>
            <div>
                {!isEdit &&
                    <div>
                        <h3 className={'text-center'}>Escolha uma imagem</h3>
                        <p className={'text-center'}>
                            Para começar a prestar serviços o ideal seria você ter uma foto! além de trazer maior confiança aos clientes, dá mais visibilidade ao ser perfil!
                        </p>
                        <div className={'col-8 m-auto'}>
                            <ChangeAvatarComponent isFormData={true}
                                                   avatar={avatar}
                                                   userId={userId}
                            />
                        </div>
                    </div>
                }
                <div className={'mt-3'}>
                    <h3 className={'text-center'}>Sobre você</h3>
                    <p className={'text-center'}>
                        Escreva aqui um pouco sobre você e seu trabalho, estas informações estarão disponíveis para os contratantes!
                    </p>
                    <textarea name="aboutYou"
                              className={'form-control'}
                              placeholder={'Escreva aqui...'}
                              id="about_you"
                              value={data?.bio}
                              onChange={(e) => onChange('bio', e.target.value)}
                              rows="10"
                              style={{resize:'none'}}
                    />
                    <div className={'mt-3'}>
                        <h5>Você possui CNPJ?</h5>
                        <div className="da-flex gap-2">
                            <div className="form-check">
                                <input className="form-check-input"
                                       onChange={() => onChange('have_cnpj', true)}
                                       type="radio"
                                       name="haveCnpj"
                                       id="yes"
                                       checked={data?.have_cnpj === true}
                                />
                                <label className="form-check-label" htmlFor="yes">
                                    Sim
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input"
                                       onChange={() => onChange('have_cnpj', false)}
                                       type="radio"
                                       name="haveCnpj"
                                       id="no"
                                       checked={data?.have_cnpj === false}
                                />
                                <label className="form-check-label" htmlFor="no">
                                    Não
                                </label>
                            </div>
                        </div>
                        {data?.have_cnpj &&
                            <div className={'mt-3'}>
                                <h5>Você é MEI?</h5>
                                <div className="da-flex gap-2">
                                    <div className="form-check">
                                        <input className="form-check-input"
                                               onChange={() => onChange('is_mei', true)}
                                               type="radio"
                                               name="ismei"
                                               id="yes_mei"
                                               checked={data?.is_mei === true}
                                        />
                                        <label className="form-check-label" htmlFor="yes_mei">
                                            Sim
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input"
                                               onChange={() => onChange('is_mei', undefined)}
                                               type="radio"
                                               name="ismei"
                                               id="no_mei"
                                               checked={data?.is_mei === undefined}
                                        />
                                        <label className="form-check-label" htmlFor="no_mei">
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                        {data?.have_cnpj &&
                            <div className={'mt-3 col-12 col-md-6 col-lg-4'}>
                                <label htmlFor="input_cnpj" className={'form-label'}>CNPJ</label>
                                <NumberFormat className={`form-control`}
                                              id={'input_cnpj'}
                                              value={data?.cnpj}
                                              placeholder='Digite aqui seu CNPJ'
                                              onValueChange={(e) => onChange('cnpj', e.value)}
                                              format={'##.###.###/####-##'}
                                              mask="_"
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className={'mt-3'}>
                    <h3 className={'text-center'}>Redes Sociais</h3>
                    <p className={'text-center'}>
                        Compartilhe suas Redes Sociais e ganhe mais visibilidade para seus clientes
                    </p>
                    <div>
                        <div className={'mb-3'}>

                            <label htmlFor="facebook" className={'form-label'}>Facebook</label>

                            <div className="input-group mb-3">
                                <span className="input-group-text gap-2" >
                                    <FaFacebookF />

                                    <span>
                                        facebook.com/
                                    </span>
                                </span>
                                <input type="text"
                                       className="form-control"
                                       value={data?.facebook_link}
                                       onChange={(e) => onChange('facebook_link', e.target.value)}
                                       placeholder="Nome de usuário"
                                       id={'facebook'}
                                />
                            </div>

                        </div>
                        <div>

                            <label htmlFor="instagram" className={'form-label'}>Instagram</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text gap-2" >
                                    <FaInstagram />

                                    <span>
                                        instagram.com/
                                    </span>
                                </span>
                                <input type="text"
                                       className="form-control"
                                       value={data?.instagram_link}
                                       onChange={(e) => onChange('instagram_link', e.target.value)}
                                       placeholder="Nome de usuário"
                                       id={'instagram'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'d-flex justify-content-end mt-3'}>
                    <div className={'col-12 col-lg-4'}>
                        <Button variant={'secondary'} className={'w-100'} onClick={getValuesInput}>
                            {isEdit ? 'Editar dados' : 'Próximo'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}