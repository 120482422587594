import React from "react";
import {AiOutlineShop, AiOutlineTeam} from 'react-icons/ai'
import {TiBusinessCard} from 'react-icons/ti'

const Loading = () => {
    return (
        <div className="no-freeze-spinner" style={{margin: 'auto'}}>
        <div id="no-freeze-spinner">
            <div>
                <i><AiOutlineShop className="material-icons"/></i>
                <i><TiBusinessCard className="material-icons"/></i>
                <i><AiOutlineTeam className="material-icons"/></i>
                <div></div>
            </div>
        </div>
        </div>
    );
};

Loading.propTypes = {};

export default Loading;
