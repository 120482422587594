import React, {useEffect, useState} from 'react'
import {Container} from "react-bootstrap";
import AboutUs from "./Components/AboutUs";
import DownApps from "./Components/DownApps";
import {debounce} from 'lodash'
import CountProviders from "./Components/CountProviders";
import SearchWorkerHeader from "./Components/SearchWorkerHeader";
import PlatformAdvantages from "./Components/PlatformAdvantages";
import LogoUF from "../../components/Generic/LogoUF";
import ComponentsFaq from "../../components/components-faq";

const HomePage = () => {

    const [loading, setLoading] = useState(true);

    let handleLoading = debounce(() => {
        setLoading(false);
    }, 500);

    useEffect(() => {
        window.scrollToElement('body');
       handleLoading()
    },[]);
    return (
        <>
            <div style={{opacity: loading ? 0 : 1, transition: '500ms', margin: '-20px 0'}}>
                <SearchWorkerHeader/>

                <div style={{paddingTop: '100px'}}>
                    <LogoUF/>
                </div>

                <div style={{paddingTop: '50px'}}>
                    <AboutUs/>
                </div>

                <div style={{padding: '100px 0'}}>
                    <CountProviders/>
                </div>

                <Container>
                    <PlatformAdvantages/>
                </Container>

                <div style={{padding: '100px 0 '}}>
                    <DownApps/>
                </div>

                <div style={{paddingBottom: '100px'}}>
                    <Container fluid={"lg"}>
                        <ComponentsFaq/>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default HomePage