import React from "react";
import {Button, Col, Container} from "react-bootstrap";
import DividerCustom from "../../../components/Generic/DividerCustom";
import {Env} from "../../../helpers/Env";
import {Images} from "../../../helpers/Images";
import {FaApple} from "react-icons/fa";

const DownApps = () => {

    return (
        <div className='my-3'>
            <div>
                <Col xs={12} className='d-flex justify-content-center'>
                    <h2 className='text-primary'>
                        Baixe também nossos apps
                    </h2>
                </Col>

                <DividerCustom/>

                <div className='d-flex justify-content-center text-center'>
                    <Col xs={12} md={8} lg={7}>
                        <h5 style={{lineHeight:1.8}} className='my-2 text-muted fw-light'>Se você precisa encontrar um profissional autônomo/freelancer com rapidez e agilidade, baixe o <span className='fw-bold text-primary'>{Env.application_title}</span>. Se você é um profissional autônomo/freelancer e quer otimizar a visibilidade do seu serviço, baixe o <span className='fw-bold text-success'>{Env.application_title} p/ Prestadores</span>.
                        </h5>
                    </Col>
                </div>
            </div>
            <Container fluid={'lg'}>
                <div className='d-flex flex-wrap justify-content-center' >
                    <Col xs={12} md={6} lg={5} xxl={4} className='p-2 text-center'>
                        <div className='d-flex flex-column justify-content-between bg-white shadow-lg p-2 rounded-2 h-100'>
                            <h3 className='fw-light text-dark pt-2'>
                                {Env.application_title}
                            </h3>
                            <Col xs={8} className='mx-auto'>
                                <img className='mb-2 w-100' src={Images.download_app_peoples.hire_people} alt='hire'/>
                            </Col>
                            <div className='p-2 h-100'>
                                <div className='p-0 p-lg-1 h-100'>
                                    <h4 className='text-bg-primary text-white p-2 rounded-2 shadow-sm de-flex flex-1 h-100 mb-0'>
                                        Quero encontrar um profissional agora!
                                    </h4>
                                </div>

                            </div>

                            <div className='d-flex flex-wrap justify-content-center p-2'>
                                <Col xs={12} md={6} className='p-1'>
                                    <Button variant='dark' className='w-100' onClick={() => window.redirectWorker(Env.client_play)}>
                                        <div className='de-flex gap-2'>
                                            <img height={20} src={Images.gplay_icon} alt='icon play store'/>
                                            <span className='text-start'>
                                            <span className='fw-lighter fs-8'>Disponível no</span> <strong className='ms-1 d-inline d-md-block'> Google Play </strong>
                                        </span>
                                        </div>
                                    </Button>
                                </Col>

                                <Col xs={12} md={6} className='p-1'>
                                    <Button variant={"dark"} className='w-100' onClick={() => window.redirectWorker(Env.client_apple)}>
                                        <div className='de-flex gap-2'>
                                            <FaApple size={24}/>
                                            <span className='text-start'>
                                            <span className='fw-lighter fs-8'>Disponível na</span> <strong className='ms-1 d-inline d-md-block'>App Store </strong>
                                        </span>
                                        </div>
                                    </Button>
                                </Col>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={5} xxl={4} className='p-2 text-center'>
                        <div className='d-flex flex-column justify-content-between bg-white shadow-lg rounded-2 p-2 h-100'>
                            <h3 className='pt-2 text-dark'>
                                <span className='fw-light'>{Env.application_title}</span> <span className='text-success fw-bold'>p/ Prestador</span>
                            </h3>
                            <Col xs={8} className='mx-auto'>
                                <img  className='mb-2 w-100' src={Images.download_app_peoples.work_people} alt='pro'/>
                            </Col>

                            <div className='p-2 h-100'>
                                <div className='p-0 p-lg-1 h-100'>
                                    <h4 className='text-bg-success text-white p-2 rounded-2 shadow-sm de-flex flex-1 h-100 mb-0'> Quero divulgar meu trabalho </h4>
                                </div>
                            </div>

                            <div className='d-flex flex-wrap align-items-center p-2'>
                                <Col xs={12} md={6}  className='p-1'>
                                    <Button variant='dark' className='w-100' onClick={() => window.redirectWorker(Env.worker_play)}>
                                        <div className='de-flex gap-2'>
                                            <img height={20} src={Images.gplay_icon} alt='icon play store'/>
                                            <span className='text-start'>
                                            <span className='fw-lighter fs-8'>Disponível no</span><strong className='ms-1 d-inline d-md-block'> Google Play </strong>
                                        </span>
                                        </div>
                                    </Button>
                                </Col>

                                <Col xs={12} md={6} className='p-1'>
                                    <Button variant={"dark"} className='w-100' onClick={() => window.redirectWorker(Env.worker_apple)}>
                                        <div className='de-flex gap-2'>
                                            <FaApple size={24}/>
                                            <span className='text-start'>
                                            <span className='fw-lighter fs-8'>Disponível na</span><strong className='ms-1 d-inline d-md-block'>App Store </strong>
                                        </span>
                                        </div>
                                    </Button>
                                </Col>
                            </div>
                        </div>
                    </Col>
                </div>
            </Container>
        </div>
    )
}

export default DownApps