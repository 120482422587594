import React from 'react'
import {Button, Col} from "react-bootstrap";
import {Images} from "../../../helpers/Images";
import {Env} from "../../../helpers/Env";
import {FaApple} from "react-icons/fa";

const WantWorkerHeader = () => {
    const background = {
        backgroundImage: `url(${Images.home_page_svg.background_home})`,
        backgroundPosition:" center",
        backgroundSize:"cover",
        marginTop: -125
    }
    return (
        <div style={background}>
            <div className='container d-flex align-items-center justify-content-evenly' style={{padding:'160px 10px'}}>
                <Col xs={11} md={6} xl={5}>
                    <div style={{backgroundColor: '#ffffffcc'}}
                         className='d-flex flex-column justify-content-between shadow-lg rounded-2 p-2 h-100'>
                        <h3 className='pt-2 text-dark text-center'>
                            Aumente sua carteira de clientes conquistando <span
                            className='text-success'>mais serviços</span> diretamente do seu celular!
                        </h3>
                        <Col xs={8} md={5} lg={7} className='mx-auto d-block d-md-none '>
                            <img className='mb-2 w-100' src={Images.download_app_peoples.work_people} alt='pro'/>
                        </Col>

                        <div className='p-2'>
                            <div className='p-0 p-lg-1'>
                                <h5 className='text-muted p-2 text-justify mb-0'>
                                    Baixe o App {Env.application_title} p/ prestadores e otimize a visibilidade do seu
                                    trabalho gratuitamente agora mesmo!
                                </h5>
                            </div>
                        </div>


                        <div className='de-flex flex-wrap  p-2'>
                            <Col xs={12} md={6} lg={5} className='p-1'>
                                <Button variant='dark' className='w-100'
                                        onClick={() => window.redirectWorker(Env.worker_play)}>
                                    <div className='de-flex gap-2'>
                                        <img height={20} src={Images.gplay_icon} alt='icon play store'/>
                                        <span className='text-start'>
                                            <span className='fw-lighter fs-8'>Disponível no</span><strong
                                            className='ms-1 d-inline d-md-block'> Google Play </strong>
                                        </span>
                                    </div>
                                </Button>
                            </Col>

                            <Col xs={12} md={6} lg={5} className='p-1'>
                                <Button variant={"dark"} className='w-100'
                                        onClick={() => window.redirectWorker(Env.worker_apple)}>
                                    <div className='de-flex gap-2'>
                                        <FaApple size={24}/>
                                        <span className='text-start'>
                                            <span className='fw-lighter fs-8'>Disponível na</span><strong
                                            className='ms-1 d-inline d-md-block'>App Store </strong>
                                        </span>
                                    </div>
                                </Button>
                            </Col>
                        </div>
                        <div>
                            <div className="separator">Ou</div>

                            <div className='de-flex flex-wrap my-3'>
                                <Col xs={12} md={6} lg={5}>
                                    <button style={{minHeight:60}} className='btn btn-outline-primary w-100'
                                            onClick={() => {
                                                let a = document.getElementById("register")
                                                if (a) a.click()

                                            }}>
                                        <div className='de-flex gap-2'>
                                            <span className='text-center'>
                                                Cadastrar pelo site
                                            </span>
                                        </div>
                                    </button>
                                </Col>
                            </div>
                        </div>


                    </div>
                </Col>

                <Col xs={6} className='d-none d-md-block '>
                    <img src={Images.i_want_work.phone_logo} className='w-100' alt='work'/>
                </Col>
            </div>


        </div>
    )
}

export default WantWorkerHeader