import React from 'react'
import LoginModal from "./Modal/LoginModal";
import RegisterModal from "./Modal/RegisterModal";
import ForgetPasswordModal from "./Modal/ForgetPasswordModal";


const ModalController = ({typeModal, handleStepModal}) => {

    return (
        <>
            <LoginModal openModal={typeModal === 'login'}
                        closeModal={() => handleStepModal(undefined)}
                        changeStepModal={(e) => handleStepModal(e)}
            />
            <RegisterModal openModal={typeModal === 'register'}
                           closeModal={() => handleStepModal(undefined)}
                           changeStepModal={(e) => handleStepModal(e)}
            />
            <ForgetPasswordModal openModal={typeModal === 'forget'}
                                 closeModal={() => handleStepModal(undefined)}
                                 changeStepModal={(e) => handleStepModal(e)}
            />
        </>
    )
}

export default ModalController