import {useDropzone} from "react-dropzone";
import React, {useCallback, useEffect, useState} from "react";
import {useQueryClient} from "@tanstack/react-query";
import {api} from "../../../services/api";
import {FaEdit, FaImage, FaPlus} from "react-icons/fa";

export const ChangeAvatarComponent = ({avatar, isFormData, userId}) => {
	const [files, setFiles] = useState(avatar? {image: avatar}: {});
	const [loadingFile, setLoadingFile] = useState(false);
	const query = useQueryClient()

	const onDrop = useCallback((acceptedFiles) => {
		setLoadingFile(true);

		acceptedFiles.forEach((file) => {
			const reader = new FileReader()
			let type = file.type.split('/')


			reader.onabort = () => console.log('file reading was aborted')
			reader.onerror = () => console.log('file reading has failed')
			reader.onload = async () => {
				let binaryStr = {
					base64: reader.result.split(',').pop(),
					extension: type[type.length - 1].toUpperCase(),
					image: reader.result
				};
				isFormData && await convertToFormData(file, binaryStr);
				setLoadingFile(false);
			};
			reader.readAsDataURL(file)
		})
	},[]);

	const convertToFormData = async (image, binaryStr) => {
		const formData = new FormData();
		formData.append('profile-picture', image)

		try {
			await api.post('register/upload/profile-picture', formData, { formData: true });
			await handleAfterImageLoad()
			setFiles(binaryStr);
		} catch (e) {
			console.log(e)
		}

	};
	
	const handleAfterImageLoad = async () => {
		if (userId) {
			let res = await api.get(`user/${userId}/profile`);
			await query.setQueryData(['profile'], (oldData) => {
				oldData.profile = {...oldData.profile, ...res.profile}
				return {...oldData}
			})
		}
	};

	const {getRootProps, getInputProps, fileRejections} = useDropzone({
			onDrop,
			accept: {'image/png': [],'image/jpg': [],'image/jpeg': []},
	});
	const fileRejectionItems = fileRejections.map(({ file }) => {
		let type = file.path.split('.')
		type = type[type.length - 1]

		return (
			<div className='mt-5'>
				<span className='text-danger-emphasis bg-danger-subtle px-4 py-1 rounded-1'>
					O tipo de arquivo <span className={'fw-bold text-uppercase'}>{type}</span> não é permitido
				</span>
			</div>
		)
	});

	useEffect(() => {
		if (avatar) {
			setFiles({image: avatar})
		}
	}, [avatar]);

	return (
		<>
			{Object.keys(files)?.length > 0 ?
				<div className={'d-flex flex-center py-2'}>
					<div {...getRootProps()} className={'edit-avatar'}>
						<input {...getInputProps()} />
						<div style={{position:'relative', width:110, height:110}}
							 className={`rounded-circle cursor-pointer shadow-sm ${loadingFile ? 'border border-gray-500' : ''}`}>
							{loadingFile ?
								<div className={'d-flex flex-center rounded-circle'} style={{position:'absolute', inset:0}}>
									<span className="spinner-border spinner-border-sm align-middle"></span>
								</div>
								:
								<img alt="Avatar" className={'w-100 h-100 rounded-circle'} style={{objectFit:'cover', objectPosition:'center',}} src={files?.image}/>
							}
							<button className={'d-flex position-absolute btn-reset p-2 border border-primary rounded-circle bg-white'}
								style={{bottom: 0, right: 0}}>
								<FaEdit />

							</button>
						</div>
					</div>
				</div>

				:
				<div>
					<div className={'bg-danger-light border-danger d-flex flex-wrap flex-center gap-4 py-3 position-relative rounded-2 border border-2'}>
						{loadingFile ?
							<div>
								<div className={'d-flex flex-center'}>
									<div style={{width:100, height:100}} className={'d-flex flex-center bg-white rounded-circle'}>
										<div className={'d-flex flex-center rounded-circle'}>
											<span className="spinner-border spinner-border-sm align-middle"></span>
										</div>
									</div>
								</div>
							</div> :
							<>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<div className="bg-white change-avatar cursor-pointer d-flex flex-center rounded-circle symbol hover-scale">
										<FaImage className={"text-primary"} style={{fontSize:"4rem"}}/>


										<button className={'bg-primary-subtle btn-reset d-flex p-2 rounded-circle'}
												style={{bottom: -8, right:-10}}>
											<FaPlus className={"fs-6"}/>
										</button>
									</div>
								</div>
							</>
						}
						<div className={'text-center'}>
							<h4 className='text-danger'>
								Escolha uma foto para seu perfil
							</h4>
							<p className={'fw-light'}>
								Ter uma logo é muito importante, pois aumenta o destaque e credibilidade da sua
								empresa.
							</p>
						</div>
					</div>

				</div>
			}
		</>

	)
}