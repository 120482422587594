import React from 'react';
import {Env} from "../helpers/Env";
import useLocalStorage from "./Storage";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {StorageVariables} from "../helpers/StoragesVariables";


const useAuth = () => {
    const history = useHistory();
    const userStorage = useLocalStorage(StorageVariables.userProfile);
    const panelStorage = useLocalStorage(StorageVariables.lastPanel);
    const fcmStorage = useLocalStorage(StorageVariables.fcm);
    const geoLocation = useLocalStorage(StorageVariables.geolocation);
    const hasProfile = useLocalStorage(StorageVariables.hasSineProfile);
    const enterpriseStorage = useLocalStorage(StorageVariables.enterprise);
    const enterpriseDataStorage = useLocalStorage(StorageVariables.enterpriseData);
    const dispatch = useDispatch();
    const step = useLocalStorage(StorageVariables.curriculumStep);

    // const api = useApi({loadingControl: false});
    const storeDAO = (res) => {
        let storedUser = userStorage.getObject();
        return {
            token: res?.api_key || storedUser.token,
            user_id: res?.user_id,
            email: res?.profile?.email,
            nickname: res?.profile?.nickname,
            mobile_verified: res?.profile?.mobile_verified,
            avatar: res?.profile?.profile_picture?.thumbnail,
            name: (res?.profile?.first_name + ' ' + res?.profile?.last_name),
            gender: res?.profile?.gender,
            phone: res?.profile?.cellphone_number,
            cpf: res?.profile?.cpf,
            addresses: res?.addresses || storedUser.addresses,
            birthday: res?.profile?.birthday,
        }
    }

    const login = (userObj:  {token:  string, name: string, email: string, nickname: string, avatar: string, addresses: any, user_id: any, gender: string, cpf: string, phone: string, birthday: string, last_profile: string, mobile_verified: string}) => {
        Env.header[`X-Api-Key`] = userObj.token;
        dispatch({type: 'init_login', data: userObj});
        dispatch({type: 'panel_profile', data: userObj.last_profile});
        panelStorage.setPrimitive(userObj.last_profile);
        userStorage.setObject(userObj);

        let userGeolocation = [];
        if (userObj.addresses) {
            userObj.addresses.forEach((item) => {
                userGeolocation.push({lat: item.latitude, long: item.longitude, name: (item.street_name + ', ' + item.street_number + ' - ' + item.district + ', ' + item.city + ' - ' + item.state) })
            });
        }
        geoLocation.setObject({...geoLocation.getObject(), ...{userLat: userGeolocation}})
    };

    const store = (userObj, fromStorage) => {
        userObj.token&& (Env.header[`X-Api-Key`] = userObj.token);
        userStorage.setObject(fromStorage? userObj : storeDAO(userObj));
        dispatch({type: 'update_user', data: fromStorage? userObj : storeDAO(userObj)});
        dispatch({type: 'panel_profile', data: panelStorage.getPrimitive()});
    };

    const logoutWithoutApi = () => {
        userStorage.remove();
        fcmStorage.remove();
        panelStorage.remove();
        geoLocation.remove();
        step.remove();
        hasProfile.remove();
        enterpriseStorage.remove();
        enterpriseDataStorage.remove();
        dispatch({type: 'logout'});
        delete Env.header[`X-Api-Key`];
        history.push('/');
    };

    const getUser = () => {
        return userStorage.getObject();
    }

    const changeAvatar = (image) => {
        userStorage.setObject({...userStorage.getObject(), avatar: Env.avatarLogo+image});
    }

    const isLogged = () => {
        return !!userStorage.getObject()?.email;
    }

    const storeEnterpriseOpportunities = (item) => {
        enterpriseStorage.setObject(item)
    };

    const getEnterpriseOpportunities = () => {
        return enterpriseStorage.getObject()
    }

    const storeEnterprise = (item) => {
        enterpriseDataStorage.setObject(item)
    }

    const getEnterprise = () => {
        return enterpriseDataStorage.getObject()
    }

    return {login,logoutWithoutApi,storeEnterprise,getEnterprise, getUser, isLogged, store,  changeAvatar, storeEnterpriseOpportunities, getEnterpriseOpportunities};
};

useAuth.propTypes = {};

export default useAuth;
