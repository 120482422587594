import React, {useEffect, useState} from 'react'
import {Col} from "react-bootstrap";
import DividerCustom from "../../../components/Generic/DividerCustom";
import {Env} from "../../../helpers/Env";
import {Images} from "../../../helpers/Images";
import {BsFillArchiveFill} from "react-icons/bs";
import useApi from "../../../hooks/useApi";

const CountProviders = () => {
    const [servicesCount, setServicesCount] = useState()

    const api = useApi()

    const getCounts = () => {
      api.get(`/search/top-occupations?application=${Env.application_alias}`, (res) => {
          setServicesCount(res)
      }, (e) => {
          console.log(e)
      })
    }

    useEffect(() => {
        getCounts()
    }, []);


    return (
        <div>
            <div>
                <Col xs={12} className='d-flex justify-content-center'>
                    <h2 className='text-center text-primary'>
                       Categorias de serviços
                    </h2>
                </Col>

                <DividerCustom/>

                <div className='d-flex justify-content-center text-center '>
                    <Col xs={12} md={8} lg={7}>
                        <h5 style={{lineHeight:1.8}} className='mt-2 text-muted fw-light'>
                           Encontre diversas opções e categorias de prestadores de serviços em todo o estado de {Env.state_restriction_name}!
                        </h5>
                    </Col>
                </div>
            </div>

            <div className='d-flex justify-content-center'>
                <Col xs={12} md={12} xl={10} className='d-flex flex-wrap justify-content-center align-items-center'>
                    <Col xs={12} className='d-flex flex-wrap'>
                        {servicesCount?.map((item) => (
                            <>
                                <Col xs={6} md={6} lg={3} className='mt-4 categories-service'>
                                    <div className='p-3 text-center d-flex flex-column align-items-center justify-content-between h-100'>
                                        <div className='p-3 w-100'>
                                            <span className='icon rounded-3'>
                                                <img src={item.icon} alt='Ícone'/>
                                            </span>
                                        </div>
                                        <h4>
                                            {item?.occupation_name}
                                        </h4>
                                        {/*<h6 className='text-muted'>*/}
                                        {/*    + de {item.total} Cadastrados*/}
                                        {/*</h6>*/}
                                    </div>
                                </Col>
                            </>
                        ))}
                    </Col>
                </Col>
            </div>
        </div>
    )
}

export default CountProviders