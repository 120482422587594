import React, {useEffect,useRef, useState} from 'react';
import {useSelector} from "react-redux";

import {FaArrowLeft, FaCheck, FaClipboardCheck, FaClipboardList, FaPlusSquare,} from "react-icons/fa";
import {MdClose} from "react-icons/md";
import useApi from "../../hooks/useApi";
import Loading from "../../components/Generic/Loading";
import RateOrderModal from "./Modal/RateOrderModal";
import moment from "moment";
import {Images} from "../../helpers/Images";
import {useHistory} from "react-router-dom";
import {Button, Col, Container} from "react-bootstrap";
import RateStars from "../../components/Generic/RateStars";

const MyContactsManage = () => {

    const api = useApi();
    const contacts_worker = useRef([]);
    const [loading, setLoading] = useState(true);
    const typeSelected = useRef('requested');
    const [contactWorkerReview, setContactWorkerReview] = useState(false);
    const activeWorker = useRef(null);
    const paginate1 = useRef({ page: 1 });
    const paginate2 = useRef({ page: 1 });
    const user = useSelector((state) => state).authReducer;
    const history = useHistory();

    const getData = (filter, loadMore) => {
        typeSelected.current = filter;
        setLoading(true);
        if(filter === `requested`) {
            const filter = {
                "order": "desc",
                "status": ["requested"],
                "page": paginate1.current?.page,
                "end": moment().format("YYYY-MM-DD"),
                "with_review": false,
                "profile": "customer"};

            api.post(`worker-contact/contacts`, filter, (res) => {
                if(res.next_page) {
                    paginate1.current.page = paginate1.current.page + 1;
                }
                paginate1.current.next_page = res.next_page;
                let aux;
                if (loadMore) {
                    aux = contacts_worker.current.concat(res.worker_contacts);
                } else {
                    aux = res.worker_contacts;
                }
                contacts_worker.current = aux;
                setLoading(false);
            }, () => {})
        } else {
            api.get(`user/${user.user_id}/contact-reviews?page=${paginate2.current?.page}&order=desc&profile=customer&type=submitted`, (res) => {
                if(res.next_page) {
                    paginate2.current.page = paginate2.current.page + 1;
                }
                paginate2.current.next_page = res.next_page;
                let aux;
                if (loadMore) {
                    aux = contacts_worker.current.concat(res.reviews);
                } else {
                    aux = res.reviews;
                }
                contacts_worker.current = aux;
                setLoading(false);
            }, (e) => {
                console.log(e)
            });
        }
    }

    const afterFeedback = () => {
        let aux = contacts_worker.current;
        aux.splice(activeWorker.current.index, 1)
        contacts_worker.current = aux;
        setContactWorkerReview(false);
        //refToast.current.showToast('success', 'Retorno enviado com sucesso!')
    }

    const handleNoService = (id, index) => {
        let safeAux = Object.assign([], contacts_worker.current);
        let aux = contacts_worker.current;
        aux.splice(index, 1)
        contacts_worker.current = aux;

        api.post(`worker-contact/${id}/cancel`, null, () => {

        }, (e) => {
            contacts_worker.current = safeAux;
            // refToast.current.showToast('warning', 'Ops algo deu errado!')
            console.log(e);
        })
    }

    useEffect(() => {
        getData(typeSelected.current);
        window.scrollToElement('body');

    },[]);


    return (
        <Container className='my-contacts py-5'>

            {contactWorkerReview &&
                <RateOrderModal hide={() => setContactWorkerReview(false)}
                                visible={contactWorkerReview}
                                profile={activeWorker.current}
                                handleAfterFeedback={() => afterFeedback()}/>
            }
            <Col xs={12} md={4} xl={3} className='p-2'>
                <div className='side-menu shadow rounded-2'>
                    <div className='icon-side'>
                        <FaArrowLeft className='back-icon' onClick={() => history.push('/painel')}/>
                    </div>

                    <div className='not-forget-worker p-3 mb-3' >
                        <img src={Images.menuWorker} alt='not forget worker'/>
                        <div className='text-center pt-3'>
                            <span className='fw-bold text-dark'>Não se esqueça de informar que ocorreu a prestação de serviço!</span> <br/>
                            <span>Isso ajuda muito o trabalhador!</span>
                        </div>
                    </div>

                    <div>
                        <div className='d-flex flex-wrap'>
                            <Col xs={12} md={12} onClick={() => getData('requested')} className={`options ${typeSelected.current === 'requested' ? 'opt-active' : ''} gap-2`}>
                                <FaClipboardCheck/> <span>Contatos solicitados</span>
                            </Col>

                            <Col xs={12} md={12} onClick={() => getData('fulfilled')} className={`options ${typeSelected.current !== 'requested' ? 'opt-active' : ''} gap-2`}>
                                <FaPlusSquare/> <span>Contatos que prestaram serviço</span>
                            </Col>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={12} md={8} xl={9} className='p-2'>
                <div className='main-content shadow rounded-2 p-2'>

                    <div className='d-flex p-2 border-bottom align-items-center mb-2'>
                        <span className='icon-content gap-2'>
                            {typeSelected.current === `requested` ?
                                <FaClipboardCheck className='text-secondary'/>
                                :
                                <FaClipboardList className='text-secondary'/>
                            }
                            <span>{typeSelected.current === `requested`? 'Contatos solicitados' : 'Contatos que prestaram serviço'}</span>
                        </span>
                    </div>

                    {loading?
                        <div style={{paddingTop: 50}}>
                            <Loading/>
                        </div>
                        :
                        <>
                            {typeSelected.current === `requested`?
                                <div className='d-flex flex-wrap py-3'>
                                    {contacts_worker.current.map((item, index) =>
                                        <Col xs={12} xl={6} className='p-1'>
                                            <div className='card-provider shadow rounded-2 p-2'>
                                                <div className='text-muted text-right position-absolute' style={{ fontSize: 12, top: 5, right: 6}}>
                                                    <span> {moment(item.created_at).format(`DD/MM/YYYY`)} </span>
                                                </div>

                                                <div className='d-flex align-items-center' onClick={() => history.push('/prestador-de-servico/' + item.worker.id + '/' + item.occupation.id)}>
                                                    <div style={{marginRight: 10, position: 'relative'}}>
                                                        <img alt='image profile' src={item.worker?.profile_picture? item.worker.profile_picture : Images.case_img }/>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <span className='fw-bold text-dark' >{item.worker?.name}</span>
                                                        </div>
                                                        <div>
                                                            <span className='text-muted' style={{fontSize: 12}}>{item.occupation?.name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='border-bottom mt-2 mb-2'/>

                                                <div className='d-flex align-items-center'>
                                                    <div style={{flex: 1}}>
                                                        <span className='text-muted' style={{fontSize: 13}}>O trabalhador prestou o serviço para você?</span>
                                                    </div>
                                                    <div style={{height:30}} className='d-flex gap-2'>
                                                        <button type='button' onClick={() => {activeWorker.current = item; setContactWorkerReview(true)}}
                                                                className='btn btn-success d-flex text-white box-shadow-none align-items-center rounded-5'>
                                                            <FaCheck className='me-2'/> <span>Sim</span>
                                                        </button>

                                                        <button type='button' onClick={() => handleNoService(item.id, index)}
                                                                className='btn btn-outline-danger d-flex box-shadow-none align-items-center rounded-5' >
                                                            <MdClose className='me-2'/> <span>Não</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </div>
                                :

                                <div className='d-flex flex-wrap'>
                                    {contacts_worker.current.map((item, index) =>
                                        <Col xs={12} md={12} xl={6} className='p-2'>
                                            <div key={index} className='card-provider shadow-sm rounded-2 p-2' onClick={() => window.open(`/prestador-de-servico/${item.user.id}/${item.occupation.id}`)}>
                                                <div className='text-muted text-right position-absolute' style={{fontSize: 12, top: 5, right: 6}}>
                                                    <span> {moment(item.created_at).format(`DD/MM/YYYY`)} </span>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='position-relative me-2'>
                                                        <img alt='image profile' src={item.user?.profile_picture?.full? item.user.profile_picture.full : Images.case_img }/>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <span className='text-dark fw-bold'>{item.user?.name}</span>
                                                        </div>
                                                        <div className='mb-1'>
                                                            <span className='text-muted' style={{fontSize: 12}}>{item.occupation?.name}</span>
                                                        </div>
                                                        {item.rating &&
                                                            <div className='d-flex align-items-center'>
                                                                <RateStars size={14} defaultValue={item.rating} disabled/>
                                                                <span className='text-muted ms-1 mt-1' style={{fontSize: 12}}> ({item.rating})</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                {item.review &&<div>
                                                    <div className='border-top my-2'/>
                                                    <span className='text-muted py-2' style={{fontSize: 13}}>{item.review}</span>
                                                </div>}
                                            </div>
                                        </Col>)}
                                </div> }


                            {!loading && contacts_worker.current.length === 0 &&
                                <Container  className='d-flex flex-column flex-wrap align-items-center text-center bg-white'>
                                    <img src={Images.resume_not_found} alt='not Found' style={{height: 300, width: '100%', objectFit: "contain"}}/>
                                    {typeSelected.current === `requested` ?
                                        <span className='fs-4'> Você não possui nenhum contato pendente solicitado </span>
                                        :
                                        <span className='fs-4'> Você não possui nenhum contato em que informou que ocorreu a prestação de serviço </span>
                                    }
                                    <span> Busque agora por profissionais autônomos cadastrados em todo o estado </span>
                                    <div className='py-4 '>
                                        <Button className='text-white' onClick={() => history.push('/quero-contratar')}>Buscar Prestadores</Button>
                                    </div>
                                </Container>
                            }
                        </>
                    }
                </div>
            </Col>
        </Container>
    )
};
export default MyContactsManage;

